import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ErrorMessage, Field, Form, Formik } from 'formik'

import { getMerchants, getMerchantPaymentMethods } from 'api/RESTful/merchant'
import { createPayout } from 'api/RESTful/payout'

import PayoutCardTable from './components/PayoutCardTable'

import { initialValues, payoutSchema } from '../config/PayoutSchema'

import Button from 'utils/Button'

const PayoutForm = ({ type }) => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(true)
  const [selectedMerchant, setSelectedMerchant] = useState('')
  const [selectedMerchantPaymentMethod, setSelectedMerchantPaymentMethod] = useState(null)
  const [selectedCard, setSelectedCard] = useState(null)
  const [merchantDropdownList, setMerchantDropdownList] = useState([])
  const [merchantPaymentMethodList, setMerchantPaymentMethodList] = useState([])
  const [payoutTo, setPayoutTo] = useState('')

  const [payoutData, setPayoutData] = useState(initialValues)

  const fetchMerchantDropdownList = () => {
    getMerchants(10, 0)
      .then((res) => {
        getMerchants(res.total, 0).then((res) => {
          setMerchantDropdownList(res.data)
          setIsLoading(false)
        })
      })
      .catch((err) => {
        toast.error("Cannot get merchant's list: " + err.message || 'Cannot get merchants list')
      })
  }

  const fetchMerchantPaymentMethods = (merchantId) => {
    getMerchantPaymentMethods(merchantId)
      .then((res) => {
        setMerchantPaymentMethodList(res)
        setIsLoading(false)
      })
      .catch((err) => {
        toast.error(
          "Cannot get merchant's payment methods: " + err.message ||
            "Cannot get merchant's payment methods",
        )
      })
  }

  const handleSubmit = (values, actions) => {
    const formattedValues = {
      ...values,
      amount: parseFloat(values.amount),
    }
    createPayout(formattedValues)
      .then((res) => {
        actions.setSubmitting(false)
        toast.success(res.data.message, {
          onClose: () => navigate('/dashboard/payout'),
        })
      })
      .catch((error) => {
        console.error(error)
        toast.error(error.toString())
        actions.setSubmitting(false)
      })
  }
  const handleFormChange = (e) => {
    setPayoutData({ ...payoutData, [e.target.name]: e.target.value })
  }

  const handleMerchantChange = (e) => {
    const merchantId = e.target.value
    setSelectedMerchant(merchantId)
    setPayoutData({
      ...payoutData,
      merchant_id: merchantId,
    })
  }

  const handleCardSelection = (cardId) => {
    setSelectedCard(cardId)
    setPayoutData({ ...payoutData, card_id: cardId })
  }

  const handlePayoutChange = (e) => {
    setPayoutTo(e.target.value)
    setPayoutData({ ...payoutData, payout_to: e.target.value })
  }
  const handlePaymentMethodChange = (e) => {
    const selectedMethod = merchantPaymentMethodList.find(
      (method) => method.id.toString() === e.target.value,
    )
    setSelectedMerchantPaymentMethod(selectedMethod || null)
    setPayoutData({
      ...payoutData,
      payment_method_bank_account_number: selectedMethod.account_number,
      payment_method_bank: selectedMethod.bank,
      payment_method_currency: selectedMethod.currency,
      payment_method_type: selectedMethod.type,
    })
  }

  useEffect(() => {
    fetchMerchantDropdownList()
  }, [])

  useEffect(() => {
    if (!selectedMerchant) return
    fetchMerchantPaymentMethods(selectedMerchant)
  }, [selectedMerchant])

  return (
    <div>
      <Formik
        initialValues={payoutData}
        validationSchema={payoutSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, handleChange, values, errors }) => (
          <Form autoComplete='off'>
            {/* Basic Info Form */}
            <div className='border rounded md:mb-4 mb-2 mt-5'>
              <div className='md:px-3 px-2 py-1 mb-3'>
                <h5 className='m-4 font-semibold text-2xl'>Basic Info</h5>
                <div className='md:mt-2'>
                  {/* 1 */}
                  <div className='flex flex-wrap my-1 items-center'>
                    <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='payout_to'>Payout to</label>
                    </div>
                    <div className='col_ col_md_4 col_12 flex items-center'>
                      <div className='w-full flex items-center'>
                        <select
                          name='payout_to'
                          id='payout_to'
                          className='w_100 select_2 cursor_pointer user_select_none w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                          value={payoutTo}
                          onChange={handlePayoutChange}
                        >
                          <option value='' disabled>
                            Select Payout To
                          </option>
                          <option value='CUSTOMER'>Customer</option>
                          <option value='MERCHANT'>Merchant</option>
                        </select>
                      </div>
                    </div>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name='payout_to'
                        component='p'
                        className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>
                  {/* 2 */}
                  <div className='flex flex-wrap my-1 items-center'>
                    <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='merchant_id'>Merchant</label>
                    </div>
                    <div className='col_ col_md_4 col_12 flex items-center'>
                      <select
                        name='merchant_id'
                        id='merchant_id'
                        className='w_100 select_2 cursor_pointer user_select_none w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                        onChange={handleMerchantChange}
                        value={selectedMerchant}
                      >
                        <option value='' disabled>
                          Select Merchant
                        </option>
                        {isLoading ? (
                          <option value='' disabled>
                            Loading all merchants
                          </option>
                        ) : merchantDropdownList.length < 0 ? (
                          <option value='' disabled>
                            No merchant found
                          </option>
                        ) : (
                          merchantDropdownList.map((item, ind) => (
                            <option value={item.id} key={item.id || ind}>
                              {item.name}
                            </option>
                          ))
                        )}
                      </select>
                    </div>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name='merchant_id'
                        component='p'
                        className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>
                  {/* 3 */}
                  <div className='flex flex-wrap my-1 items-center'>
                    <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='amount'>Amount</label>
                    </div>
                    <div className='col_ col_md_4 col_12 flex items-center'>
                      <div className='w-full flex items-center'>
                        <Field
                          type='number'
                          name='amount'
                          id='amount'
                          className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                          onChange={handleFormChange}
                          value={payoutData.amount}
                          min='0'
                        />
                      </div>
                    </div>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name='amount'
                        component='p'
                        className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>

                  {/* ----------------------- */}
                </div>
              </div>
            </div>

            {/* Conditional Payout To Form */}
            {payoutTo === 'MERCHANT' && (
              <div className='border rounded md:mb-4 mb-2 mt-5'>
                <div className='md:px-3 px-2 py-1 mb-3'>
                  <h5 className='m-4 font-semibold text-2xl'>Payout to</h5>
                  {/* 1 */}
                  <div className='flex flex-wrap my-1 items-center'>
                    <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='merchant_name'>Merchant name</label>
                    </div>
                    <div className='col_ col_md_4 col_12 flex items-center'>
                      <div className='w-full flex items-center'>
                        <select
                          name='merchant_name'
                          id='merchant_name'
                          className='w_100 select_2 cursor_pointer user_select_none w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                          onChange={(e) => {
                            setSelectedMerchant(e.target.value)
                          }}
                          value={selectedMerchant}
                          disabled
                        >
                          <option value='' disabled>
                            Select Merchant
                          </option>
                          {isLoading ? (
                            <option value='' disabled>
                              Loading all merchants
                            </option>
                          ) : merchantDropdownList.length < 0 ? (
                            <option value='' disabled>
                              No merchant found
                            </option>
                          ) : (
                            merchantDropdownList.map((item, ind) => (
                              <option value={item.id} key={item.id || ind}>
                                {item.name}
                              </option>
                            ))
                          )}
                        </select>
                      </div>
                    </div>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name='merchant_name'
                        component='p'
                        className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>
                  <div className='md:mt-2'>
                    {/* 2 */}
                    <div className='flex flex-wrap my-1 items-center'>
                      <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                        <label htmlFor='payment_method_name'>Payment Method Name</label>
                      </div>
                      <div className='col_ col_md_4 col_12 flex items-center'>
                        <div className='w-full flex items-center'>
                          <select
                            name='payment_method_name'
                            id='payment_method_name'
                            className='w_100 select_2 cursor_pointer user_select_none w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                            onChange={handlePaymentMethodChange}
                            value={
                              selectedMerchantPaymentMethod ? selectedMerchantPaymentMethod.id : ''
                            }
                          >
                            <option value='' disabled>
                              Select Payment Method
                            </option>
                            {isLoading ? (
                              <option value='' disabled>
                                Loading all merchants
                              </option>
                            ) : merchantPaymentMethodList.length < 0 ? (
                              <option value='' disabled>
                                No payment method found
                              </option>
                            ) : (
                              merchantPaymentMethodList.map((item, ind) => (
                                <option value={item.id} key={item.id || ind}>
                                  {item.name}
                                </option>
                              ))
                            )}
                          </select>
                        </div>
                      </div>
                      <div className='col_md_5 col_12'>
                        <ErrorMessage
                          name='merchant_name'
                          component='p'
                          className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                        />
                      </div>
                    </div>
                    {/* 3 */}
                    <div className='flex flex-wrap my-1 items-center'>
                      <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                        <label htmlFor='payment_method_bank_account_number'>
                          Payment Method Account Number
                        </label>
                      </div>
                      <div className='col_ col_md_4 col_12 flex items-center'>
                        <div className='w-full flex items-center'>
                          <Field
                            type='text'
                            name='payment_method_bank_account_number'
                            id='payment_method_bank_account_number'
                            className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                            value={
                              selectedMerchantPaymentMethod
                                ? selectedMerchantPaymentMethod.account_number
                                : ''
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col_md_5 col_12'>
                        <ErrorMessage
                          name='payment_method_bank_account_number'
                          component='p'
                          className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                        />
                      </div>
                    </div>
                    {/* 6 */}
                    <div className='flex flex-wrap my-1 items-center'>
                      <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                        <label htmlFor='payment_method_bank'>Payment Method Bank</label>
                      </div>
                      <div className='col_ col_md_4 col_12 flex items-center'>
                        <div className='w-full flex items-center'>
                          <Field
                            type='text'
                            name='payment_method_bank'
                            id='payment_method_bank'
                            className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                            value={
                              selectedMerchantPaymentMethod
                                ? selectedMerchantPaymentMethod.bank
                                : ''
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col_md_5 col_12'>
                        <ErrorMessage
                          name='payment_method_bank'
                          component='p'
                          className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                        />
                      </div>
                    </div>
                    {/* 5 */}
                    <div className='flex flex-wrap my-1 items-center'>
                      <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                        <label htmlFor='payment_method_currency'>Currency</label>
                      </div>
                      <div className='col_ col_md_4 col_12 flex items-center'>
                        <div className='w-full flex items-center'>
                          <Field
                            type='text'
                            name='payment_method_currency'
                            id='name'
                            className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                            value={
                              selectedMerchantPaymentMethod
                                ? selectedMerchantPaymentMethod.currency
                                : ''
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col_md_5 col_12'>
                        <ErrorMessage
                          name='payment_method_currency'
                          component='p'
                          className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                        />
                      </div>
                    </div>
                    {/* 6 */}
                    <div className='flex flex-wrap my-1 items-center'>
                      <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                        <label htmlFor='card_holder_name'>Payment Method Type</label>
                      </div>
                      <div className='col_ col_md_4 col_12 flex items-center'>
                        <div className='w-full flex items-center'>
                          <Field
                            type='text'
                            name='payment_method_type'
                            id='name'
                            className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                            value={
                              selectedMerchantPaymentMethod
                                ? selectedMerchantPaymentMethod.type
                                : ''
                            }
                            disabled
                          />
                        </div>
                      </div>
                      <div className='col_md_5 col_12'>
                        <ErrorMessage
                          name='payment_method_type'
                          component='p'
                          className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                        />
                      </div>
                    </div>
                    {/* ----------------------- */}
                  </div>
                </div>
              </div>
            )}
            {payoutTo === 'CUSTOMER' && (
              <div className='border rounded md:mb-4 mb-2 mt-5'>
                <div className='md:px-3 px-2 py-1 mb-3'>
                  <h5 className='m-4 font-semibold text-2xl'>Payout to</h5>
                  {/* 1 */}
                  <div className='flex flex-wrap my-1 items-center'>
                    <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='client_name'>Client name</label>
                    </div>
                    <div className='col_ col_md_4 col_12 flex items-center'>
                      <div className='w-full flex items-center'>
                        <Field
                          type='text'
                          name='client_name'
                          id='client_name'
                          className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                          onChange={handleFormChange}
                          value={payoutData.client_name}
                        />
                      </div>
                    </div>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name='client_name'
                        component='p'
                        className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>
                  <div className='md:mt-2'>
                    {/* 2 */}
                    <div className='flex flex-wrap my-1 items-center'>
                      <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                        <label htmlFor='bank_name'>Bank name</label>
                      </div>
                      <div className='col_ col_md_4 col_12 flex items-center'>
                        <div className='w-full flex items-center'>
                          <Field
                            type='text'
                            name='bank_name'
                            id='bank_name'
                            className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                            onChange={handleFormChange}
                            value={payoutData.bank_name}
                          />
                        </div>
                      </div>
                      <div className='col_md_5 col_12'>
                        <ErrorMessage
                          name='bank_name'
                          component='p'
                          className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                        />
                      </div>
                    </div>
                    {/* 3 */}
                    <div className='flex flex-wrap my-1 items-center'>
                      <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                        <label htmlFor='bank_account_number'>Bank Account Number</label>
                      </div>
                      <div className='col_ col_md_4 col_12 flex items-center'>
                        <div className='w-full flex items-center'>
                          <Field
                            type='text'
                            name='bank_account_number'
                            id='bank_account_number'
                            className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                            onChange={handleFormChange}
                            value={payoutData.bank_account_number}
                          />
                        </div>
                      </div>
                      <div className='col_md_5 col_12'>
                        <ErrorMessage
                          name='bank_account_number'
                          component='p'
                          className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                        />
                      </div>
                    </div>
                    {/* 6 */}
                    <div className='flex flex-wrap my-1 items-center'>
                      <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                        <label htmlFor='branch'>Branch</label>
                      </div>
                      <div className='col_ col_md_4 col_12 flex items-center'>
                        <div className='w-full flex items-center'>
                          <Field
                            type='text'
                            name='branch'
                            id='branch'
                            className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                            onChange={handleFormChange}
                            value={payoutData.branch}
                          />
                        </div>
                      </div>
                      <div className='col_md_5 col_12'>
                        <ErrorMessage
                          name='branch'
                          component='p'
                          className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                        />
                      </div>
                    </div>
                    {/* 5 */}
                    <div className='flex flex-wrap my-1 items-center'>
                      <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                        <label htmlFor='province'>Province</label>
                      </div>
                      <div className='col_ col_md_4 col_12 flex items-center'>
                        <div className='w-full flex items-center'>
                          <Field
                            type='text'
                            name='province'
                            id='province'
                            className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                            onChange={handleFormChange}
                            value={payoutData.province}
                          />
                        </div>
                      </div>
                      <div className='col_md_5 col_12'>
                        <ErrorMessage
                          name='province'
                          component='p'
                          className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                        />
                      </div>
                    </div>
                    {/* 6 */}
                    <div className='flex flex-wrap my-1 items-center'>
                      <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                        <label htmlFor='city'>City</label>
                      </div>
                      <div className='col_ col_md_4 col_12 flex items-center'>
                        <div className='w-full flex items-center'>
                          <Field
                            type='text'
                            name='city'
                            id='city'
                            className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                            onChange={handleFormChange}
                            value={payoutData.city}
                          />
                        </div>
                      </div>
                      <div className='col_md_5 col_12'>
                        <ErrorMessage
                          name='city'
                          component='p'
                          className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                        />
                      </div>
                    </div>
                    {/* ----------------------- */}
                  </div>
                </div>
              </div>
            )}
            {/* Payout from */}
            <PayoutCardTable onCardSelect={handleCardSelection} payoutTo={payoutTo} />
            <div className='flex items-center justify-center mr-3 gap-5 my-5'>
              <Button classes='cs_btn bg-purple-500 text-white' type='submit'>
                {isSubmitting ? 'Creating...' : 'Create'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PayoutForm
