// Custom Modal reusable
const CsModal = ({ open, onClose, children }) => {
  if (open) {
    return (
      <>
        {children}
        <div className='cs_modal_backdrop' onClick={() => (onClose ? onClose() : null)}></div>
      </>
    )
  }
  return null
}

export default CsModal
