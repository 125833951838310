import Table from '../../components/Table'
import { IndicatorIcon } from 'utils/Icons'
import { useState, useEffect } from 'react'
import Button from 'utils/Button'
import plusIcon from 'assets/images/icons/plusIcon.png'
import { generateUID } from 'utils/helpers'
//Icons
import { toast } from 'react-toastify'
import { useCancelablePromises } from 'hook/useCancelablePromises'
import Spinner from 'utils/Spinner'
import { useSortableData2 } from 'pages/Dashboard/components/useSortableData2'
import { currency } from 'utils/dropdownOptions'
import { Link } from 'react-router-dom'
import { addFeeSetting, deleteFeeSetting, getFeeSetting, updateFeeSetting } from 'api/api'
import moment from 'moment'

//
const FeeSettingsTable = ({ id, compType, propsData, updateData, tableType, title }) => {
  const { fetchData } = useCancelablePromises()
  // check form type
  const formType = () => compType === 'add'
  //
  const [loadingData, setLoadingData] = useState(true)
  const [loadingFunc, setLoadingFunc] = useState(false)
  // check for conditional data
  const conditionalObj = () => {
    if (tableType === 'PAYOUT') return []
    return [{ name: 'Details', title: 'is_active' }]
  }
  // these are the config for table header
  const feeTableColumns = [
    { name: '#' },
    { name: 'Currency', title: 'currency', sort: true },
    { name: 'Amount', title: 'amount' },
    { name: 'Percent', title: 'percent' },
    { name: 'Fixed Amt', title: 'fixed_amount' },
    { name: 'Status', title: 'is_active' },
    ...conditionalObj(),
    { name: 'Last Updated', title: 'last_updated', sort: true },
    { name: 'Action' },
  ]
  // Fee data will be like this
  const [feeData, setFeeData] = useState([])

  // table hook
  const { items: tblData, CsTableHead } = useSortableData2(
    formType() ? propsData : feeData,
    feeTableColumns,
  )

  //
  useEffect(() => {
    if (formType()) {
      setLoadingData(false)
      return
    }
    if (!id) return
    // get fee settings data
    getFeeSetting(id)
      .then((data) => {
        if (data) {
          setFeeData(data?.filter((v) => v?.transaction_type === tableType))
          setLoadingData(false)
        }
      })
      .catch((err) => {
        console.log(err)
        setLoadingData(false)
        toast.error(err.message || "Couldn't get fee settings!")
      })
  }, [id, compType])

  // this will add a new row in a table for creating a new record
  const addNewRow = () => {
    let data = formType() ? [...propsData] : [...feeData]
    data.unshift({
      newRecord: true,
      id: generateUID(),
      transaction_type: tableType,
      currency: 'CNY',
      min_amount: 0,
      max_amount: 0,
      // spread: 0,
      percent: 0,
      fixed_amount: 0,
      is_active: true,
      last_updated: 'N/A',
    })

    formType() ? updateData(data) : setFeeData(data)
  }

  // enable editing for a particular raw
  const editEnable = (rowId, status) => {
    let prev = formType() ? [...propsData] : [...feeData]
    let index = prev.findIndex((v) => v.id === rowId)
    prev[index] = { ...prev[index], editing: status }
    formType() ? updateData(prev) : setFeeData(prev)
  }

  // cancel new record add/ cancel new record
  const cancelNewRow = (elemId, type) => {
    if (elemId === null || elemId === undefined) return
    if (formType()) {
      let filtered = propsData?.filter((v) => v.id !== elemId)
      updateData(filtered)
      return
    }
    if (type && type === 'new') {
      let filtered = feeData.filter((v) => v.id !== elemId)
      setFeeData(filtered)
    } else if (type && type === 'edit') {
      editEnable(elemId, false)
    } else {
      setLoadingFunc(true)
      deleteFeeSetting(elemId)
        .then(() => {
          let filtered = feeData.filter((v) => v.id !== elemId)
          setFeeData(filtered)
          toast.success('Fee setting deleted successfully')
          setLoadingFunc(false)
        })
        .catch((e) => {
          console.log(e)
          toast.error(e.message || 'Something went wrong!')
          setLoadingFunc(false)
        })
    }
  }
  // save new record / add a new record in our data
  const saveNewRow = (elemId, newRecord) => {
    let obj = feeData.find((v) => v.id === elemId)
    if (obj.max_amount < obj.min_amount) {
      toast.error('Max Amount should be greater than min amount')
      return
    }
    if (formType()) {
      let prev = [...propsData]
      let index = prev.findIndex((v) => v.id === elemId)
      prev[index] = {
        ...prev[index],
        newRecord: false,
        editing: false,
      }
      updateData(prev)
      return
    }
    setLoadingFunc(true)
    if (newRecord === 'new') {
      // add new
      addFeeSetting({ ...obj }, id)
        .then((res) => {
          let data = res?.data || {}
          let prev = [...feeData]
          let index = prev.findIndex((v) => v.id === elemId)
          prev[index] = {
            ...prev[index],
            id: data?.id || elemId,
            is_primary: data?.is_primary,
            last_updated: data?.last_updated,
            merchant_id: data?.merchant_id,
            created_at: data?.created_at,
            newRecord: false,
            editing: false,
          }
          setFeeData(prev)
          toast.success('New setting has been saved successfully')
          setLoadingFunc(false)
        })
        .catch((err) => {
          console.log(err)
          toast.error(err.message || 'Something went wrong!')
          setLoadingFunc(false)
        })
    } else {
      // update
      delete obj.created_at
      delete obj.editing
      delete obj.id
      delete obj.last_updated
      delete obj.merchant_id
      updateFeeSetting(obj, elemId)
        .then(() => {
          let prev = [...feeData]
          let index = prev.findIndex((v) => v.id === elemId)
          prev[index] = {
            ...prev[index],
            newRecord: false,
            editing: false,
          }
          setFeeData(prev)
          toast.success('Updated successfully')
          setLoadingFunc(false)
        })
        .catch((err) => {
          console.log(err)
          toast.error(err.message || 'Something went wrong!')
          setLoadingFunc(false)
        })
    }
  }
  // Update Status
  const handleStatusUpdate = (elemId) => {
    let prev = [...feeData]
    let obj = feeData.find((v) => v.id === elemId)
    let index = prev.findIndex((v) => v.id === elemId)
    setLoadingFunc(true)
    obj = {
      ...obj,
      is_active: !obj.is_active,
    }
    updateFeeSetting(obj, elemId)
      .then((res) => {
        prev[index] = obj
        toast.success('Fee Setting Updated')
        setFeeData(prev)
        setLoadingFunc(false)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err?.message || 'Something went wrong')
        setLoadingFunc(false)
      })
  }

  // handle input on change
  const handleInput = (e, rowId) => {
    let { name, value } = e.target
    let prev = formType() ? [...propsData] : [...feeData]
    let index = prev.findIndex((v) => v.id === rowId)
    if (loadingFunc) return
    if (!checkForInputEdit(prev[index])) {
      prev[index] = { ...prev[index], [name]: parseFloat(value) }
      formType() ? updateData(prev) : setFeeData(prev)
    } else {
      return
    }
  }

  //
  const checkForInputEdit = (item) => {
    if (loadingFunc) {
      return true
    } else {
      if (item?.newRecord || item.editing) {
        return false
      } else {
        return true
      }
    }
  }

  return (
    <div className='border rounded md:my-3 my-2'>
      <div className=' px_md_3 px-2 py-2 flex items-center'>
        <div className='flex items-center'>
          <h6 className='text-2xl font-bold p-2'>{title}</h6>
        </div>
        <Button
          disabled={loadingData || loadingFunc}
          onClick={addNewRow}
          classes='ml-auto flex items-center border'
          type='button'
        >
          <span className='mr-2 flex items-center justify-center'>
            <img src={plusIcon} alt='' />
          </span>{' '}
          Add fee
        </Button>
      </div>
      <Table CsTableHead={<CsTableHead loading={loadingFunc} />}>
        {loadingData ? (
          <tr>
            <td colSpan='10' className='text-center'>
              <div className='w-full py-3 px-2'>
                <Spinner />
                <h6 className='py-2 w-full text-center'>Loading...</h6>
              </div>
            </td>
          </tr>
        ) : (
          tblData?.map((item, i) => (
            <tr key={i} className=''>
              <td>{item?.newRecord || formType() ? i + 1 : i + 1}</td>

              {/* 1 */}
              <td>
                <div className='flex items-center justify-center'>
                  <select
                    name='currency'
                    id=''
                    className='cursor-pointer  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                    value={item?.currency}
                    onChange={(e) => handleInput(e, item.id)}
                  >
                    <option>CNY</option>
                  </select>
                </div>
              </td>
              {/* 2 */}
              <td>
                <div className='flex'>
                  <div className=' flex items-center justify-center'>
                    <input
                      type='number'
                      name='min_amount'
                      value={item?.min_amount}
                      onChange={(e) => handleInput(e, item.id)}
                      placeholder='min'
                      style={{ textAlign: 'right' }}
                      className='px-0 min-w-[90px]  w-full  rounded py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                    />
                    <span className='mx_2'>
                      <svg width='11' height='1' viewBox='0 0 11 1' fill='none'>
                        <line y1='0.5' x2='10.0499' y2='0.5' stroke='black' />
                      </svg>
                    </span>
                    <input
                      type='number'
                      name='max_amount'
                      value={item?.max_amount}
                      onChange={(e) => handleInput(e, item.id)}
                      placeholder='max'
                      style={{ textAlign: 'right' }}
                      className='min-w-[90px] px-0 w-full  rounded  py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                    />
                  </div>
                </div>
              </td>
              {/* 3 */}
              <td>
                <div className='flex'>
                  <div className=' flex items-center justify-center'>
                    <input
                      type='number'
                      name='percent'
                      min='0'
                      max='100'
                      step='0.01'
                      className='border min-w-full max-w-[80px]  rounded  h-auto min-h-auto text-base outline-gray-300 border-gray-300 text-black m-1 placeholder:text-gray-500'
                      value={item?.percent}
                      onChange={(e) => handleInput(e, item.id)}
                      placeholder='4%'
                      style={{ textAlign: 'right' }}
                    />
                  </div>
                </div>
              </td>
              {/* 4 */}
              <td>
                <div className='flex'>
                  <div className='flex items-center justify-center'>
                    <input
                      type='number'
                      name='fixed_amount'
                      min='0'
                      className='border min-w-full max-w-[50px]  rounded  h-auto min-h-auto text-base outline-gray-300 border-gray-300 text-black m-1 placeholder:text-gray-500'
                      value={item?.fixed_amount}
                      onChange={(e) => handleInput(e, item.id)}
                      placeholder='0.00'
                      style={{ textAlign: 'right' }}
                    />
                  </div>
                </div>
              </td>
              {/* 5 */}
              <td>
                {item?.newRecord || item?.editEnable ? null : (
                  <div className='flex items-center justify-start'>
                    <span className='md:mx-2 mx-1'>
                      <IndicatorIcon type={item?.is_active ? 'active' : 'pending'} />
                    </span>
                    {!item?.is_active ? 'InActive' : 'Active'}
                  </div>
                )}
              </td>
              {/* 6 */}
              {tableType === 'PAYIN' && (
                <td>
                  {item?.newRecord || item?.editEnable ? null : (
                    <div className='flex'>
                      <div className=' flex items-center justify-center'>
                        <Link to={`/dashboard/merchant/details/${id}/cardspriority/${item.id}`}>
                          <p className='text-[#499CD6] underline'>Details</p>
                        </Link>
                      </div>
                    </div>
                  )}
                </td>
              )}

              {/* 7*/}
              <td>
                <div className='flex'>
                  <div className=' flex items-center justify-center '>
                    {item?.last_updated !== 'N/A' &&
                      moment(item?.last_updated).format('DD/MM/YYYY')}
                  </div>
                </div>
              </td>
              {/* 5 */}

              <td>
                {item?.newRecord || item?.editing ? (
                  <div className='flex items-center justify-start '>
                    <Button
                      type='button'
                      classes='border-none px-[3px] py-[7px] min-h-[23px] text-sm font-semibold'
                      disabled={loadingFunc}
                      onClick={() => handleStatusUpdate(item.id)}
                    >
                      {item?.is_active ? 'InActive' : 'Active'}
                    </Button>
                    |
                    <Button
                      type='button'
                      classes='border-none px-[3px] py-[7px] min-h-[23px] text-sm font-semibold '
                      disabled={loadingFunc}
                      onClick={() => saveNewRow(item.id, item?.editing ? 'edit' : 'new')}
                    >
                      Save
                    </Button>
                    |
                    <Button
                      type='button'
                      disabled={loadingFunc}
                      onClick={() => cancelNewRow(item.id, item?.editing ? 'edit' : 'new')}
                      classes='border-none px-[3px] py-[7px] min-h-[23px] text-sm font-semibold   '
                    >
                      Cancel
                    </Button>
                  </div>
                ) : (
                  <div className='flex items-center justify-start '>
                    <Button
                      type='button'
                      disabled={loadingFunc}
                      onClick={() => editEnable(item.id, true)}
                      classes='border-none px-[3px] py-[7px] min-h-[23px] text-sm font-semibold   '
                    >
                      Edit
                    </Button>
                    |
                    <Button
                      type='button'
                      disabled={loadingFunc}
                      onClick={() => cancelNewRow(item.id)}
                      classes='border-none px-[3px] py-[7px] min-h-[23px] text-sm font-semibold   '
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </td>
            </tr>
          ))
        )}
      </Table>
    </div>
  )
}

export default FeeSettingsTable
