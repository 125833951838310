import React, { useState, useEffect } from 'react'

import { toast } from 'react-toastify'
import { useParams, NavLink, useNavigate } from 'react-router-dom'
import { ErrorMessage, Field, Form, Formik } from 'formik'

import { getCardDetails, doTopUpCard } from 'api/RESTful/card'
import { getWalletDetails } from 'api/RESTful/wallet'

import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import Button from 'utils/Button'

const DebitCard = () => {
  const [debitData, setDebitData] = useState({ amount: null, remark: '', wallet_id: '' })
  const [walletDetails, setWalletDetails] = useState({
    accountBalance: 0,
    availableBalance: 0,
    frozenBalance: 0,
  })
  const { id: cardId } = useParams()
  const navigate = useNavigate()

  const handleSubmit = (values) => {
    const newBalance = walletDetails.availableBalance - parseFloat(values.amount)
    doTopUpCard(values.wallet_id, { amount: newBalance, remark: values.remark })
      .then((res) => {
        toast.success(res.data.message, {
          onClose: () => navigate(`/dashboard/all-cards/details/${cardId}`),
        })
      })
      .catch((error) => {
        toast.error(error.response.data.message)
      })
  }

  useEffect(() => {
    getCardDetails(cardId)
      .then((res) => {
        setDebitData(res)

        getWalletDetails(res.wallet_id)
          .then((walletRes) => {
            setWalletDetails({
              accountBalance: walletRes.available_balance + walletRes.frozen_balance,
              availableBalance: walletRes.available_balance,
              frozenBalance: walletRes.frozen_balance,
            })
          })
          .catch((error) => {
            toast.error(error.response.data.message)
          })
      })
      .catch((error) => {
        toast.error(error.response.data.message)
      })
  }, [cardId])

  return (
    <div>
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '/dashboard/all-cards' },
          { title: 'Card details', path: '' },
          { title: 'Debit Card', path: '' },
        ]}
      />
      <Formik initialValues={debitData} onSubmit={handleSubmit} enableReinitialize>
        {({ handleChange, values, errors }) => (
          <Form autoComplete='off'>
            <div className='border rounded md:mb-4 mb-2 mt-5'>
              <div className='md:px-3 px-2 py-1 mb-3'>
                <h5 className='m-4 font-semibold text-2xl'>Debit Card</h5>
                <div className='md:mt-2'>
                  {/* 1 */}
                  <div className='flex items-center justify-center gap-5 my-5'>
                    <div className='col_md_4 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='card_id'>Card ID</label>
                    </div>
                    <div className='col_ col_md_4 col_12 flex items-center'>
                      <Field
                        type='text'
                        name='card_id'
                        id='card_id'
                        className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                        value={cardId}
                        disabled
                      />
                    </div>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name='card_id'
                        component='p'
                        className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>
                  <div className='flex items-center justify-center gap-5 my-5'>
                    <div className='col_md_4 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='card_id'>Wallet ID</label>
                    </div>
                    <div className='col_ col_md_4 col_12 flex items-center'>
                      <Field
                        type='text'
                        name='wallet_id'
                        id='wallet_id'
                        className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                        value={values?.wallet_id}
                        disabled
                      />
                    </div>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name='wallet_id'
                        component='p'
                        className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>
                  {/* 2 */}
                  <div className='flex items-center justify-center gap-5 my-5'>
                    <div className='col_md_4 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='amount'>Debit Amount</label>
                    </div>
                    <div className='col_ col_md_4 col_12 flex items-center'>
                      <Field
                        type='number'
                        name='amount'
                        id='amount'
                        step='0.01'
                        className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                        onChange={handleChange}
                        value={values?.amount}
                        min='0'
                      />
                    </div>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name='amount'
                        component='p'
                        className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>
                  <div className='flex items-center justify-center gap-5 my-5'>
                    <div className='col_md_4 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='amount'>Remarks</label>
                    </div>
                    <div className='col_ col_md_4 col_12 flex items-center'>
                      <Field
                        type='text'
                        name='remark'
                        id='remark'
                        className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                        onChange={handleChange}
                        value={values?.remark}
                        min='0'
                      />
                    </div>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name='remark'
                        component='p'
                        className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>
                  <div className='flex items-center justify-center gap-5 my-5'>
                    <div className='col_md_4 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='amount'>Adjusted Account Balance</label>
                    </div>
                    <div className='col_ col_md_4 col_12 flex items-center'>
                      <Field
                        type='text'
                        name='remark'
                        id='remark'
                        className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                        value={(
                          walletDetails.availableBalance +
                          walletDetails.frozenBalance -
                          parseFloat(values.amount || 0)
                        ).toFixed(2)}
                        disabled
                      />
                    </div>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name='remark'
                        component='p'
                        className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>
                  <div className='flex items-center justify-center gap-5 my-5'>
                    <div className='col_md_4 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='amount'>Adjusted Available Balance</label>
                    </div>
                    <div className='col_ col_md_4 col_12 flex items-center'>
                      <Field
                        type='text'
                        name='remark'
                        id='remark'
                        className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                        value={(
                          walletDetails.availableBalance - parseFloat(values.amount || 0)
                        ).toFixed(2)}
                        disabled
                      />
                    </div>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name='remark'
                        component='p'
                        className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>
                  <div className='flex items-center justify-center gap-5 my-5'>
                    <div className='col_md_4 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='amount'>Adjusted Frozen Balance</label>
                    </div>
                    <div className='col_ col_md_4 col_12 flex items-center'>
                      <Field
                        type='text'
                        name='remark'
                        id='remark'
                        className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                        value={walletDetails.frozenBalance}
                        disabled
                        min='0'
                      />
                    </div>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name='remark'
                        component='p'
                        className='text-red-600 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='flex items-center justify-center gap-5 my-5'>
              <NavLink to='/dashboard/all-cards'>
                <Button type='button' classes='cs_btn'>
                  Cancel
                </Button>
              </NavLink>
              <Button classes='cs_btn bg-purple-500 text-white' type='submit'>
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default DebitCard
