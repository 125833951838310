import Table from '../../components/Table'
import { useState, useEffect } from 'react'
import Button from 'utils/Button'
import plusIcon from 'assets/images/icons/plusIcon.png'
import { generateUID } from 'utils/helpers'
import { toast } from 'react-toastify'
import Spinner from 'utils/Spinner'
import { useSortableData2 } from 'pages/Dashboard/components/useSortableData2'
import { merchant_user_role } from 'utils/dropdownOptions'
import { createMerchantUser, editMerchantUser, getAllMerchantUsers } from 'api/api'
import moment from 'moment'
import { IndicatorIcon } from 'utils/Icons'
//
const UsersTable = ({ id }) => {
  //
  const [loadingData, setLoadingData] = useState(true)
  const [loadingFunc, setLoadingFunc] = useState(false)
  // these are the config for table header
  const usersTableColumns = [
    { name: '#' },
    { name: <div>Name</div>, title: 'name', sort: true },
    { name: <div className='text-left flex-grow'>Phone</div>, title: 'phone' },
    { name: 'Email', title: 'email', sort: true },
    { name: 'role', title: 'Type', sort: true },
    { name: 'Last Login', title: 'last_login', sort: true },
    { name: <div className='text-left flex-grow'>Action</div>, sort: false },
  ]
  // users data will be like this
  const [usersData, setDataForUsers] = useState([])

  // table hook
  const { items: tblData, CsTableHead } = useSortableData2(usersData, usersTableColumns)

  //
  useEffect(() => {
    if (!id) return
    // get users data
    getAllMerchantUsers(id)
      .then((data) => {
        if (data) {
          setDataForUsers(data || [])
          setLoadingData(false)
        }
      })
      .catch((err) => {
        console.log(err)
        setLoadingData(false)
        toast.error(err.message || "Couldn't get users list!")
      })
  }, [id])

  // handle toggle event for activate and deactivate a user
  const toggleActivateUser = (id) => {
    setLoadingFunc(true)

    // Find the user in the original data array
    const index = usersData.findIndex((v) => v.id === id)
    const obj = usersData[index]

    // Create a new object with the updated is_active property
    const testObj = {
      ...obj,
      is_active: !obj.is_active,
    }

    editMerchantUser(testObj, id)
      .then(() => {
        const updatedPrev = [...usersData]
        updatedPrev[index] = { ...testObj } // Update the object in the copied array with the updated testObj
        toast.success('User Updated')
        setDataForUsers(updatedPrev)
        setLoadingFunc(false)
      })
      .catch((err) => {
        console.log(err)
        toast.error(err?.message || 'Something went wrong')
        setLoadingFunc(false) // Set loading to false even if an error occurs
      })
  }

  // this will add a new row in a table for creating a new record
  const addNewRow = () => {
    let data = [...usersData]
    data.unshift({
      id: generateUID(),
      is_active: false,
      first_name: '',
      origanization: '',
      last_name: '',
      email: '',
      role: 'MERCHANT_ADMIN',
      phone: '',
      last_login: 'N/A',
      last_updated: '',
      created: '',
      newRecord: true,
    })
    setDataForUsers(data)
  }

  // enable editing for a particular raw
  const editEnable = (rowId, status) => {
    let prev = [...usersData]
    let index = prev.findIndex((v) => v.id === rowId)
    prev[index] = { ...prev[index], editing: status }
    setDataForUsers(prev)
  }

  // cancel new record add/ cancel new record
  const cancelNewRow = (elemId, type) => {
    if (elemId === null || elemId === undefined) return
    if (type && type === 'new') {
      let filtered = usersData.filter((v) => v.id !== elemId)
      setDataForUsers(filtered)
    } else if (type && type === 'edit') {
      editEnable(elemId, false)
    } else {
      setLoadingFunc(true)
    }
  }

  // save new record / add a new record in our data
  const saveNewRow = (elemId, newRecord) => {
    let obj = usersData.find((v) => v.id === elemId)
    setLoadingFunc(true)
    if (newRecord === 'new') {
      // add new
      createMerchantUser({ ...obj }, id)
        .then((res) => {
          let data = res.data
          let prev = [...usersData]
          let index = prev.findIndex((v) => v.id === elemId)
          prev[index] = {
            ...prev[index],
            id: data?.id || elemId,
            created_at: data?.created_at,
            last_updated: data?.last_updated,
            is_active: data?.is_active,
            last_login: data?.last_login,
            newRecord: false,
            editing: false,
          }
          setDataForUsers(prev)
          toast.success('User has been saved successfully')
          setLoadingFunc(false)
        })
        .catch((err) => {
          console.log(err)
          toast.error(err.message || 'Something went wrong!')
          setLoadingFunc(false)
        })
    } else {
      // update
      delete obj.editing
      delete obj.last_login
      delete obj.id
      delete obj.password
      delete obj.merchant_id
      delete obj.last_updated
      delete obj.last_login
      delete obj.created_at

      editMerchantUser(obj, elemId)
        .then((res) => {
          let prev = [...usersData]
          let index = prev.findIndex((v) => v.id === elemId)
          prev[index] = {
            ...prev[index],
            newRecord: false,
            editing: false,
          }
          setDataForUsers(prev)
          toast.success('Updated successfully')

          setLoadingFunc(false)
        })
        .catch((err) => {
          console.log(err)
          toast.error(err.message || 'Something went wrong!')
          setLoadingFunc(false)
        })
    }
  }

  // handle input on change
  const handleInput = (e, rowId) => {
    let { name, value } = e.target
    let prev = [...usersData]
    let index = prev.findIndex((v) => v.id === rowId)
    if (loadingFunc) return
    if (!checkForInputEdit(prev[index])) {
      prev[index] = { ...prev[index], [name]: value }
      setDataForUsers(prev)
    } else {
      return
    }
  }

  //
  const checkForInputEdit = (item) => {
    if (loadingFunc) {
      return true
    } else {
      if (item?.newRecord || item.editing) {
        return false
      } else {
        return true
      }
    }
  }
  return (
    <div className='border rounded md:my-3 my_2'>
      <div className='table_heading md:px-3 px-2 py-2 flex items-center'>
        <div className='flex items-center'>
          <h6 className='text-2xl font-bold'>User</h6>
        </div>
        <Button
          disabled={loadingData || loadingFunc}
          onClick={addNewRow}
          classes='ml-auto flex items-center'
        >
          <span className='mr-2 flex items-center justify-center'>
            <img src={plusIcon} alt='' />
          </span>{' '}
          Add User
        </Button>
      </div>
      <Table CsTableHead={<CsTableHead loading={loadingFunc} />}>
        {loadingData ? (
          <tr>
            <td colSpan='7' className='text-center'>
              <div className='w_100 py_3 px-2'>
                <Spinner />
                <h6 className='py-2 w_100 text-center'>Loading...</h6>
              </div>
            </td>
          </tr>
        ) : (
          tblData?.map((item, i) => (
            <tr key={i} className=''>
              <td>{item?.newRecord ? i + 1 : i + 1}</td>
              <td className='text-center pr-2'>
                {item?.editing || item?.newRecord ? (
                  <div className='form_group flex items-center justify-center'>
                    <input
                      type='text'
                      name='first_name'
                      value={item?.first_name}
                      onChange={(e) => handleInput(e, item.id)}
                      placeholder='Name'
                      className='w_100'
                      style={{ maxWidth: '130px', textAlign: 'center' }}
                    />
                  </div>
                ) : (
                  item?.first_name + ' ' + item?.last_name
                )}
              </td>
              <td>
                {item?.editing || item?.newRecord ? (
                  <div className='form_group flex items-center justify-center'>
                    <input
                      type='text'
                      name='phone'
                      value={item?.phone}
                      onChange={(e) => handleInput(e, item.id)}
                      placeholder='phone'
                      className='w_100'
                      style={{ maxWidth: '130px', textAlign: 'left' }}
                    />
                  </div>
                ) : (
                  item?.phone
                )}
              </td>
              <td className='text-center'>
                {item?.editing || item?.newRecord ? (
                  <div className='form_group flex items-center justify-center'>
                    <input
                      type='email'
                      name='email'
                      value={item?.email}
                      onChange={(e) => handleInput(e, item.id)}
                      placeholder='email'
                      className='w_100'
                      style={{ maxWidth: '140px', textAlign: 'left' }}
                    />
                  </div>
                ) : (
                  item?.email
                )}
              </td>
              <td className='text-center'>
                {item?.editing || item?.newRecord ? (
                  <div className='form_group flex items-center justify-center'>
                    <select
                      name='role'
                      id=''
                      className='select_2 cursor-pointer'
                      value={item?.role}
                      onChange={(e) => handleInput(e, item.id)}
                    >
                      {merchant_user_role.map((v, i) => (
                        <option value={v} key={i}>
                          {v}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  item?.role
                )}
              </td>
              <td className='text-center'>
                {item?.last_login && item?.last_login !== 'N/A'
                  ? moment(item?.last_login).format('DD/MM/YYYY HH:MM:SS')
                  : 'N/A'}
              </td>
              <td>
                <div className='flex items-center justify-start tbl_action_wrapper'>
                  {item?.newRecord || item?.editing ? (
                    <>
                      <Button
                        classes='text_btn tbl_btn'
                        disabled={loadingFunc}
                        onClick={() => saveNewRow(item.id, item?.editing ? 'edit' : 'new')}
                      >
                        Save
                      </Button>
                      <Button
                        disabled={loadingFunc}
                        onClick={() => cancelNewRow(item.id, item?.editing ? 'edit' : 'new')}
                        classes='text_btn tbl_btn side_border_btn side_border_btn_left '
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      {/* {console.log(item)} */}
                      <span>
                        <IndicatorIcon type={item?.is_active ? 'active' : 'inactive'} />
                      </span>
                      {item?.is_active ? (
                        <Button
                          onClick={() => toggleActivateUser(item.id)}
                          classes='text_btn tbl_btn'
                        >
                          Active
                        </Button>
                      ) : (
                        <Button
                          onClick={() => toggleActivateUser(item.id)}
                          classes='text_btn tbl_btn'
                        >
                          Inactive
                        </Button>
                      )}
                      <Button
                        disabled={loadingFunc}
                        onClick={() => editEnable(item.id, true)}
                        classes='text_btn tbl_btn side_border_btn side_border_btn_left'
                      >
                        Edit
                      </Button>
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))
        )}
      </Table>
    </div>
  )
}

export default UsersTable
