import { phoneRegExp } from 'utils/dropdownOptions'
import * as Yup from 'yup'

export const initialValues = {
  name: '',
  address1: '',
  address2: '',
  city: '',
  country: '',
  postal_code: '',
  email: '',
  phone: '+852',
  profile_id: null,
  parent_id: null,
  is_active: '',
}

export const agentProfileSchema = {
  name: Yup.string().min(2, 'Too Short!').max(30, 'Too Long!').required('Required'),
  address1: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  postal_code: Yup.string().required(' Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string()
    .required('required')
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(9, 'too short')
    .max(15, 'too long'),
  profile_id: Yup.mixed().when('parent_id', {
    is: (parent_id) => parent_id !== null,
    then: Yup.mixed().required('Commission profile must be selected if parent account has a value'),
    otherwise: Yup.mixed().notRequired(),
  }),
  parent_id: Yup.string().nullable(),
}

export const agentSchema = Yup.object(agentProfileSchema).shape({})
