import axios from 'axios'

const getPayments = async (take, skip, sort) => {
  const res = await axios.get(`/payments?take=${take}&skip=${skip}&sort=${sort}`)
  return res.data
}
const getPaymentDetails = async (id) => {
  const res = await axios.get(`/payments/${id}`)
  return res.data
}
const doConfirmPayment = async (id, data) => {
  return await axios.post(`/payments/${id}/do-confirm`, data)
}

const doRefundPayment = async (id, req) => {
  return await axios.patch(`/payments/${id}/do-refund`, req)
}

const doAddRefundPaymentRecord = async (id, data) => {
  return await axios.patch(`/payments/${id}/add-refund-record`, data)
}

const doToggleFreezePayment = async (id) => {
  return await axios.patch(`/payments/${id}/freeze-status`)
}

export {
  getPayments,
  getPaymentDetails,
  doConfirmPayment,
  doRefundPayment,
  doAddRefundPaymentRecord,
  doToggleFreezePayment,
}
