import axios from 'axios'
import { useEffect } from 'react'

export function useCancelablePromises() {
  const controller = new AbortController()

  useEffect(() => {
    return () => {
      controller.abort()
    }
  }, [])

  const cancelRequest = () => {
    controller.abort()
  }

  const fetchData = async (url) => {
    try {
      const response = await axios.get(url, {
        signal: controller.signal,
      })
      return response.data
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        throw error
      }
    }
  }

  return { cancelRequest, fetchData }
}
