import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import acceptIconImg from '../../../../assets/images/icons/acceptIcon.png'
import { UsersProfileSchema, initialValues as userInitialValues } from '../Config/profileSchema'
import CsModal from 'utils/Modal'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { editUser } from 'api/api'
import { toast } from 'react-toastify'
import { getUserData } from 'store/reducers/UserReducer'
import UsersFormWrapper from './UsersFormWrapper'
import { useNavigate } from 'react-router-dom'

const UserProfile = ({ id, profileData }) => {
  const navigate = useNavigate()
  // modal state
  const [modalOpen, setModal] = useState({ open: false, id: '' })
  const dispatch = useDispatch()

  const handleSubmit = (values, { setSubmitting }) => {
    let obj = values
    delete obj.created_at
    delete obj.last_updated
    delete obj.id
    editUser(obj, id)
      .then((res) => {
        dispatch(getUserData())
        setSubmitting(false)
        setModal({ open: true, id: res.data.id || '' })
      })
      .catch((err) => {
        setSubmitting(false)
        console.log(err)
        toast.error(err.message || 'Something went wrong')
      })
  }
  const handleCloseModal = () => {
    navigate('/dashboard/setting/users')
    setModal({
      open: false,
      id: '',
    })
  }
  return (
    <>
      <Formik
        initialValues={
          profileData || {
            ...userInitialValues,
          }
        }
        validationSchema={Yup.object().shape({
          ...UsersProfileSchema,
        })}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, handleChange, values, errors, touched }) => (
          <Form autoComplete='off'>
            <UsersFormWrapper
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              type='edit'
            />
          </Form>
        )}
      </Formik>

      {/* Model */}
      <CsModal open={modalOpen.open} onClose={handleCloseModal}>
        <div className='bg-white text-center  cs_modal rounded md:px-3 md:py-4 px-2 py-3'>
          <div className='flex content-center items-center my-2'>
            <span className='mr-2 flex items-center'>
              <img src={acceptIconImg} alt='' />
            </span>
            <p className='ml-1'>The changes have been saved successfully</p>
          </div>
          <div className='my-2 flex flex-wrap justify-center items-center'>
            <button className='cs_btn mx-2 capitalize' onClick={handleCloseModal}>
              back to user
            </button>
          </div>
        </div>
      </CsModal>
    </>
  )
}

export default UserProfile
