import * as Yup from 'yup'

export const initialValues = {
  payout_to: '',
  merchant_id: '',
  amount: '',
  payment_method_bank_account_number: '',
  payment_method_bank: '',
  payment_method_currency: null,
  payment_method_type: null,
  client_name: '',
  bank_name: '',
  bank_account_number: '',
  branch: '',
  province: '',
  city: '',
  card_id: '',
}

export const payoutSchema = Yup.object().shape({
  payout_to: Yup.string().required('Required'),
  merchant_id: Yup.string().required('Required'),
  amount: Yup.string().required('Required'),
  card_id: Yup.string().required('Required'),

  payment_method_bank_account_number: Yup.string().when('payout_to', {
    is: 'MERCHANT',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  payment_method_bank: Yup.string().when('payout_to', {
    is: 'MERCHANT',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  payment_method_currency: Yup.string().when('payout_to', {
    is: 'MERCHANT',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),
  payment_method_type: Yup.string().when('payout_to', {
    is: 'MERCHANT',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired().nullable(),
  }),

  client_name: Yup.string().when('payout_to', {
    is: 'CUSTOMER',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  bank_name: Yup.string().when('payout_to', {
    is: 'CUSTOMER',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  bank_account_number: Yup.string().when('payout_to', {
    is: 'CUSTOMER',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  branch: Yup.string().when('payout_to', {
    is: 'CUSTOMER',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  province: Yup.string().when('payout_to', {
    is: 'CUSTOMER',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
  city: Yup.string().when('payout_to', {
    is: 'CUSTOMER',
    then: Yup.string().required('Required'),
    otherwise: Yup.string().notRequired(),
  }),
})
