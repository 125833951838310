import * as Yup from 'yup'

export const initialValues = {
  profile_name: '',
  master_agent_percent: '',
  agent_1_percent: '',
  agent_2_percent: '',
}

export const profileSchema = Yup.object().shape({
  profile_name: Yup.string().required('Required'),
  master_agent_percent: Yup.number().max(99, 'Should be less than 100').required('Required'),
  agent_1_percent: Yup.number().max(99, 'Should be less than 100').required('Required'),
})
