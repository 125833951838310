import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllWallets } from 'api/api'

export const getWalletData = createAsyncThunk(
  'wallet/getWalletData',
  async ({ query, itemsPerPage, skip }) => {
    return await getAllWallets(query || '', itemsPerPage || '', skip || '')
  },
)
const initialState = {
  data: [],
  isLoading: true,
}

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setAllWallet: (state, action) => {
      state.data = action.payload.data
      state.isLoading = action.payload.isLoading
    },
  },
  extraReducers: {
    [getWalletData.pending]: (state) => {
      state.isLoading = true
    },
    [getWalletData.fulfilled]: (state, action) => {
      state.data = action.payload || []
      state.isLoading = false
    },
    [getWalletData.rejected]: (state) => {
      state.data = []
      state.isLoading = false
    },
  },
})
export const { setAllWallet } = walletSlice.actions

export default walletSlice.reducer
