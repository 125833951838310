import { ErrorMessage, Field, Form, Formik } from 'formik'
import CurrencyInput from 'react-currency-input-field'
import { Link } from 'react-router-dom'
import Button from 'utils/Button'
import { initialValues, merchantPySettingSchema } from '../config/merchantPaymentSettingSchema'
import { toast } from 'react-toastify'
import { editMerchant } from 'api/api'

const PaymentSetting = ({ paymentSettingData, id, setProfileData }) => {
  // update merchant payment settings
  const handleSubmit = (values, { setSubmitting }) => {
    editMerchant(
      {
        transaction_currency: values?.transaction_currency,
        payin_daily_limit: parseInt(values?.payin_daily_limit),
        payin_currency: values?.payin_currency,
        payout_daily_limit: parseInt(values?.payout_daily_limit),
        payout_currency: values?.payout_currency,
      },
      id,
    )
      .then((res) => {
        toast.success(res?.statusText || 'Updated successfully')
        setSubmitting(false)
        let updatedData = { ...values }
        if (setProfileData)
          setProfileData({
            ...updatedData,
            last_updated: new Date().toISOString(),
          })
      })
      .catch((err) => {
        setSubmitting(false)
        console.log('Error while updating the merchant payment settings => ', err)
        toast.error(err.message || 'Something went wrong!')
      })
  }

  return (
    <Formik
      initialValues={paymentSettingData || initialValues}
      validationSchema={merchantPySettingSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, handleChange, values }) => (
        <Form autoComplete='off'>
          <MerchantPaySettingWrapper
            isSubmitting={isSubmitting}
            handleChange={handleChange}
            values={values}
            type='edit'
            id={id}
          />
        </Form>
      )}
    </Formik>
  )
}

// for wrapper
export const MerchantPaySettingWrapper = ({ isSubmitting, handleChange, values, type, id }) => {
  // function for stoppping negative value typed in the input
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }
  return (
    <div className='border mt-2 rounded'>
      <div className='md:px-3 px-2 py-1'>
        <h5 className='text-2xl font-bold p-2'>Payment Setting</h5>
        <div className='md:mt-2'>
          {/* 1 */}
          <div className='flex flex-wrap md:my-0 my-1 items-center'>
            <div className='col_md_4 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='transaction_currency'>Transaction currency</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <select
                  name='transaction_currency'
                  id='transaction_currency'
                  className=' cursor-pointer select-none w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  onChange={handleChange}
                  value={values.transaction_currency}
                >
                  <option value='' disabled>
                    Select transaction currency
                  </option>
                  <option>CNY</option>
                </select>
              </div>
            </div>
            <div className='col_md_4 col_12'>
              <ErrorMessage
                name='transaction_currency'
                component='p'
                className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 2 */}
          <div className='flex flex-wrap my_1 items-center'>
            <div className='form_label col_md_4 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='payin_currency'>Payin - daily limit</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='flex w-full'>
                <div className=''>
                  <div className=' flex items-center'>
                    <select
                      name='payin_currency'
                      id='payin_currency'
                      className=' cursor-pointer select-none   rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                      onChange={handleChange}
                      value={values?.payin_currency}
                    >
                      <option>CNY</option>
                    </select>
                  </div>
                </div>
                <div className='w-full ps_2'>
                  <div className=' flex items-center'>
                    <CurrencyInput
                      className='w-full text-end rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                      id=''
                      name='payin_daily_limit'
                      placeholder=''
                      type='text'
                      decimalsLimit={2}
                      allowNegativeValue={false}
                      allowDecimals
                      onChange={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*)\./g, '$1')
                        handleChange(e)
                      }}
                      value={values.payin_daily_limit?.toString()?.replaceAll(',', '')}
                      onKeyDown={preventMinus}
                      min='0'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col_md_4 col_12'>
              <ErrorMessage
                name='payin_daily_limit'
                component='p'
                className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 3 */}
          <div className='flex flex-wrap my_1 items-center'>
            <div className='form_label col_md_4 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='payout_currency'>Payout - daily limit</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='flex w-full'>
                <div className=''>
                  <div className=' flex items-center'>
                    <select
                      name='payout_currency'
                      id='payout_currency'
                      className='select_2 cursor-pointer select-none rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                      onChange={handleChange}
                      value={values?.payout_currency}
                    >
                      <option>CNY</option>
                    </select>
                  </div>
                </div>
                <div className='w-full ps_2'>
                  <div className='  flex items-center'>
                    <CurrencyInput
                      className='w-full text-end rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                      id=''
                      name='payout_daily_limit'
                      placeholder=''
                      type='text'
                      decimalsLimit={2}
                      allowNegativeValue={false}
                      allowDecimals
                      onChange={(e) => {
                        e.target.value = e.target.value
                          .replace(/[^0-9.]/g, '')
                          .replace(/(\..*)\./g, '$1')
                        handleChange(e)
                      }}
                      value={values.payout_daily_limit?.toString()?.replaceAll(',', '')}
                      onKeyDown={preventMinus}
                      min='0'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col_md_4 col_12'>
              <ErrorMessage
                name='payout_daily_limit'
                component='p'
                className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
        </div>
        {type === 'add' ? null : (
          <div className='md:mt-3 flex justify-end items-center my-2'>
            <Link
              to={isSubmitting ? '' : `/dashboard/merchant/details/${id}`}
              disabled={isSubmitting}
              className='cs_btn mx_1  px-8'
              type='button'
            >
              Cancel
            </Link>
            <Button
              disabled={isSubmitting}
              classes='bg-purple-500 text-white ml-1  border-none'
              type='submit'
            >
              {isSubmitting ? 'Saving..' : 'Save'}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default PaymentSetting
