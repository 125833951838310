import { SortingActiveIcon, SortingIcon } from 'utils/Icons'
import 'assets/css/table.css'
import { useState } from 'react'

const Table = ({ columns, children, tableClasses, hideScrollbar, CsTableHead }) => {
  const [sortingType, setSortingType] = useState('')

  // handle table sorting
  const handleSorting = (type) => {
    setSortingType(sortingType === type ? '' : type)
  }
  return (
    <div className={`cs_table_wrapper ${hideScrollbar ? ' hide-scrollbar' : ''}`}>
      <table className={`cs_table ${tableClasses} `}>
        {CsTableHead ? (
          CsTableHead
        ) : (
          <thead className='z-0'>
            <tr>
              {columns?.map((col, i) => (
                <th key={i}>
                  {col?.child ? (
                    <div
                      role='button'
                      onClick={() => (col?.sort ? handleSorting(col.title) : null)}
                    >
                      {col?.name}
                      {col?.sort && (
                        <div className='tbl_sort_icons flex justify-start items-center'>
                          {sortingType === col.title ? <SortingActiveIcon /> : <SortingIcon />}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      role='button'
                      onClick={() => (col?.sort ? handleSorting(col.title) : null)}
                      className={`flex justify-start items-center ${
                        col?.sort ? ' cursor-pointer' : ''
                      }`}
                    >
                      {col?.name}
                      {col?.sort && (
                        <div className='tbl_sort_icons flex justify-start items-center'>
                          {sortingType === col.title ? <SortingActiveIcon /> : <SortingIcon />}
                        </div>
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>{children}</tbody>
      </table>
    </div>
  )
}

export default Table
