import axios from 'axios'

const getCards = async (take, skip) => {
  const res = await axios.get(`/cards?take=${take}&skip=${skip}`)
  return res.data
}

const getPayoutCards = async (take, skip, isInHouse) => {
  const res = await axios.get(
    `/cards?take=${take}&skip=${skip}&sort=-created_at&isInHouse=${isInHouse}`,
  )
  return res.data
}

const getCardDetails = async (id) => {
  const res = await axios.get(`/cards/${id}`)
  return res.data
}

const getCardPriorities = async (id) => {
  const res = await axios.get(`/fee-settings/${id}/card-priority`)
  return res.data
}

const updateCardDetails = async (id, req) => {
  return await axios.put(`/cards/${id}`, req)
}

const updateCardPriority = async (cardId, cardPriorityId, req) => {
  return await axios.put(`/cards/${cardId}/card-priority/${cardPriorityId}`, req)
}

const doUploadQrCode = async (id, req) => {
  return await axios.post(`/cards/${id}/upload-qrcode`, req)
}

const doAssignCardToFee = async (id, autoAssign, req) => {
  return await axios.post(`/cards/${id}/do-assignment?autoAssign=${autoAssign}`, req)
}

const doUnssignCardToFee = async (id, req) => {
  return await axios.post(`/cards/${id}/un-assignment`, req)
}

const doForceAssignCardToFee = async (id, req) => {
  return await axios.post(`/cards/${id}/force-assignment`, req)
}
const doTopUpCard = async (id, req) => {
  return await axios.patch(`/wallets/${id}`, req)
}

export {
  getCards,
  getPayoutCards,
  getCardDetails,
  getCardPriorities,
  updateCardDetails,
  updateCardPriority,
  doAssignCardToFee,
  doUnssignCardToFee,
  doForceAssignCardToFee,
  doTopUpCard,
  doUploadQrCode,
}
