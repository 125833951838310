// This is a card element. we can pass our custom classes and other styles in props so it will be re-usable
// This is wrapper component, we can wrap anything inside this component.
const Card = ({ cardClasses = '', props, children }) => {
  return (
    <div className={`px-3 py-4 rounded border ${cardClasses}`} {...props}>
      {children}
    </div>
  )
}

export default Card
