import { ErrorMessage, Field } from 'formik'
import React from 'react'
import Button from 'utils/Button'
import { currency } from 'utils/dropdownOptions'

const CardPayoutFormWrapper = ({ values, handleChange, type, isSubmitting, cardVal }) => {
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }

  return (
    <div className={`border rounded md:mb-4 mb-2 ${cardVal === 'DP' ? 'bg-gray-200' : null}`}>
      <div className='md:px-3 px-2 py-1 mb-3'>
        <h5 className='m-4 font-semibold text-2xl'>Payout Limit</h5>
        <div className='md:mt-2'>
          {/* 1 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='card_nickname'>
                Transaction no. limit<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='number'
                  name='payout_transaction_limit'
                  id='card_nickname'
                  placeholder='0'
                  className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500  disabled:bg-[#C4C4C4] disabled:cursor-not-allowed'
                  disabled={cardVal === 'DP'}
                  onKeyDown={preventMinus}
                  min='0'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='payout_transaction_limit'
                component='p'
                className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 2 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='card_nickname'>
                Daily Max Limit<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <select
                  name='payout_daily_max_limit_currency'
                  id=''
                  className='cursor-pointer select-none  rounded px-[15px] py-[3px]  h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500  disabled:bg-[#C4C4C4] disabled:cursor-not-allowed'
                  disabled={cardVal === 'DP'}
                  onChange={handleChange}
                  value={values?.payin_per_transaction_limit?.currency}
                >
                  <option value='' disabled>
                    Select
                  </option>
                  {currency?.map((v, i) => (
                    <option value={v} key={i}>
                      {v}
                    </option>
                  ))}
                </select>
                <Field
                  type='number'
                  name='payout_daily_max_limit'
                  id='card_nickname'
                  placeholder='0'
                  className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500  disabled:bg-[#C4C4C4] disabled:cursor-not-allowed'
                  disabled={cardVal === 'DP'}
                  onKeyDown={preventMinus}
                  min='0'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='payout_daily_max_limit'
                component='p'
                className='text-red-600 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 3 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='card_nickname'>
                Per Transaction Limit<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <div className='flex w-full items-center'>
                  <select
                    name='payout_per_transaction_limit_currency'
                    id=''
                    className='cursor-pointer select-none  rounded px-[15px] py-[3px]  h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500  disabled:bg-[#C4C4C4] disabled:cursor-not-allowed'
                    disabled={cardVal === 'DP'}
                    onChange={handleChange}
                    value={values?.payin_per_transaction_limit?.currency}
                  >
                    <option value='' disabled>
                      Select
                    </option>
                    {currency?.map((v, i) => (
                      <option value={v} key={i}>
                        {v}
                      </option>
                    ))}
                  </select>
                  <ErrorMessage
                    name='payout_per_transaction_limit_currency'
                    component='p'
                    className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                  />
                </div>

                <div className='flex flex-wrap w-full items-center'>
                  <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                    <label htmlFor='card_nickname' className='text-gray-400'>
                      min
                    </label>
                  </div>
                  <div className='col_ col_md_4 col_12 flex items-center w-[70%]'>
                    <div className='w-full min-w-[120px] flex items-center'>
                      <Field
                        type='number'
                        name='payout_per_transaction_min_limit'
                        id='payin_max'
                        className='w-full min-w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500 text-right  disabled:bg-[#C4C4C4] disabled:cursor-not-allowed'
                        disabled={cardVal === 'DP'}
                        placeholder='0.00'
                      />
                    </div>
                  </div>
                  <div className='col_md_5 col_12'>
                    <ErrorMessage
                      name='payout_per_transaction_min_limit'
                      component='p'
                      className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <div className='flex flex-wrap  items-center pl-3'>
                <label htmlFor='payin_max' className='text-gray-400'>
                  max
                </label>
                <div>
                  <Field
                    type='number'
                    name='payout_per_transaction_max_limit'
                    id='payin_max'
                    className='w-full rounded px-[15px] py-[3px] max-w-[50%] h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500 text-right  disabled:bg-[#C4C4C4] disabled:cursor-not-allowed'
                    disabled={cardVal === 'DP'}
                    placeholder='0.00'
                    onKeyDown={preventMinus}
                    min='0'
                  />
                  <ErrorMessage
                    name='payout_per_transaction_max_limit'
                    component='p'
                    className='text_grey w_100 text_start ms_md_auto ps_md_2'
                  />
                </div>
              </div>
            </div>
            {/* <div className="col_md_5 col_12">
             
            </div> */}
          </div>
          {/* 4 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='card_nickname'>
                Card fees<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name=''
                  id='card_nickname'
                  className='w-full rounded px-[15px] py-[3px] max-w-[20%] h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500 pointer-events-none cursor-not-allowed  disabled:bg-[#C4C4C4] disabled:cursor-not-allowed'
                  disabled={cardVal === 'DP'}
                  value='%'
                  readOnly={true}
                  onKeyDown={preventMinus}
                  min='0'
                />
                <Field
                  type='number'
                  name='payout_card_fees'
                  id='card_nickname'
                  placeholder='0'
                  className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500  disabled:bg-[#C4C4C4] disabled:cursor-not-allowed'
                  disabled={cardVal === 'DP'}
                  onKeyDown={preventMinus}
                  min='0'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='payout_card_fees'
                component='p'
                className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* -------------------------- */}
        </div>
      </div>
      {/* {type === "edit" ? (
        <div className="flex items-center justify-end mr-3 gap-5 my-5">
          <Button classes="cs_btn">Cancel</Button>
          <Button classes="cs_btn bg-purple-500 text-white" type="submit">Save</Button>
        </div>
      ) : null} */}
    </div>
  )
}

export default CardPayoutFormWrapper
