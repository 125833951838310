import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { getPayouts } from 'api/RESTful/payout'
import { getMerchantDetails } from 'api/RESTful/merchant'
import { doUpdateAuditStatus } from 'api/RESTful/payout'

import payoutIcon from 'assets/images/icons/payoutIcon.png'
import {
  PagingDoubleLeftIcon,
  PagingDoubleRightIcon,
  PagingLeftIcon,
  PagingRightIcon,
} from '../../../utils/Icons'

import { useSortableData3 } from '../../Dashboard/components/useSortableData3'
import Button from '../../../utils/Button'
import BreadCrumbs from '../../../features/Dashboard/components/BreadCrumbs'
import TabTitle from '../../Dashboard/components/TabTitle'

import Spinner from '../../../utils/Spinner'
import { formatDateTime } from 'utils/formatDate'
import { formatAmount } from 'utils/formatAmount'

const Payout = () => {
  // FIXME: move this to separate constant json
  const payoutColumns = [
    { name: '#' },
    {
      title: 'created_at',
      name: <div className='text-start flex-grow w-[110px]'>Date</div>,
      sort: true,
    },
    { title: 'id', name: <div className='text-start flex-grow'>Payout ID </div>, sort: true },
    {
      title: 'merchant_id',
      name: <div className='text-start flex-grow'>Merchant</div>,
      sort: true,
    },
    {
      title: 'client_name',
      sort: true,
      name: <div className='text-start flex-grow'>Customer</div>,
    },
    { name: <div className='text-start flex-grow'>Amount(CNY)</div> },
    { name: <div className='text-start flex-grow'>Merchant Balance</div> },
    { name: <div className='text-start flex-grow'>Audit Status</div> },
    { name: <div className='text-start flex-grow'>Payout Status</div> },
  ]

  // States
  const [payoutListData, setPayoutListData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [activePage, setActivePage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [totalPages, setTotalPages] = useState(0)
  const [isNext, setIsNext] = useState(false)
  const [isPrev, setIsPrev] = useState(true)
  const [sortConfig, setSortConfig] = useState({ key: 'created_at', direction: 'descending' })

  const isPageButtonDisabled = (pageNumber) => {
    return pageNumber > totalPages
  }

  const handleUpdateAuditStatus = async (payoutItem) => {
    if (payoutItem.audit_status !== 'PENDING') {
      return // Exit the function if the status is not 'Pending'
    }

    try {
      await doUpdateAuditStatus(payoutItem.id, 'PROCESSING')
      // Optionally, you can fetch the payouts again here to refresh the list
    } catch (error) {
      console.error(`Failed to update audit status: ${error.message}`)
    }
  }

  const fetchPayouts = async (page, sortConfig) => {
    try {
      setIsLoading(true)
      const take = itemsPerPage
      const skip = (page - 1) * itemsPerPage
      const sortParam = sortConfig.key
        ? `${sortConfig.direction === 'ascending' ? '' : '-'}${sortConfig.key}`
        : ''

      const payoutsData = await getPayouts(take, skip, sortParam)
      const updatedPayouts = await Promise.all(
        payoutsData.data.map(async (item) => {
          try {
            const merchantDetails = await getMerchantDetails(item.merchant_id)
            return {
              ...item,
              available_balance: merchantDetails.wallet.available_balance,
            }
          } catch (error) {
            console.error(`Error fetching details for merchant ${item.merchant_id}:`, error)
            return { ...item, available_balance: 'N/A' } // or handle as appropriate
          }
        }),
      )

      setPayoutListData(updatedPayouts) // Update payout list with new data
      setTotalPages(Math.ceil(payoutsData.total / itemsPerPage))
      setIsLoading(false)
    } catch (error) {
      console.error(error.message || 'Cannot get payouts, Please try again.')
      setIsLoading(false)
    }
  }
  const handleSortChange = (key) => {
    let direction = 'ascending'
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending'
    }
    setSortConfig({ key, direction })
    fetchPayouts(activePage, { key, direction }) // Fetch sorted data
  }

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10)
    setItemsPerPage(newItemsPerPage)
    setActivePage(1)
  }

  const handlePageChange = (action) => {
    let newPage = activePage
    switch (action) {
      case 'fastBackward':
        newPage = 1
        break
      case 'fastForward':
        newPage = totalPages
        break
      case 'backward':
        newPage = Math.max(1, activePage - 1)
        break
      case 'forward':
        newPage = Math.max(1, activePage + 1)
        break
      default:
        newPage = parseInt(action, 10)
    }
    setActivePage(newPage)
  }
  // FIXME: rewrite the "useCustomHook"
  const { CsTable } = useSortableData3(payoutListData, payoutColumns, handleSortChange)

  useEffect(() => {
    fetchPayouts(activePage, sortConfig)
    setIsNext(activePage < totalPages)
    setIsPrev(activePage > 1)
  }, [activePage, itemsPerPage, totalPages, sortConfig])

  useEffect(() => {
    fetchPayouts(activePage, sortConfig)
  }, [activePage, itemsPerPage, sortConfig])
  return (
    <>
      <div className='min-h-[100vh] flex flex-col h-full mb-8'>
        {/* page breadcrumbs */}
        <BreadCrumbs
          navList={[
            { title: 'Home', path: '' },
            { title: 'Payout', path: '' },
          ]}
        />
        {/* Page title */}
        <div className='cs_row items-center'>
          <div className='mr-2'>
            <TabTitle title={'Payout'} icon={<img src={payoutIcon} width={30} />} />
          </div>
          <div className='ml-auto flex gap-3'>
            <Link to='addNew' className=' cs_btn flex items-center'>
              Add Payout
            </Link>
            <Link to='addNewMany' className=' cs_btn flex items-center'>
              Mass Upload
            </Link>
          </div>
        </div>

        {/* Table  */}
        <div className='mt-7'>
          <div className='md:my-4 border rounded my-2'>
            <CsTable columns={payoutColumns} loading={isLoading} tableClasses='header_fill'>
              {!isLoading ? (
                payoutListData?.length > 0 ? (
                  payoutListData.map((item, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td className='w-[155px]'>
                        <div className='text-start '>{formatDateTime(item?.created_at)}</div>
                      </td>
                      <td onClick={() => handleUpdateAuditStatus(item)}>
                        <Link to={'details/' + item.id} className='text-start text-[#499CD6]'>
                          {item?.id}
                        </Link>
                      </td>
                      <td className='text-start'>{item?.merchant_id}</td>
                      <td>{item?.client_name || 'N/A'}</td>
                      <td>{formatAmount(item?.amount)}</td>
                      <td>{item?.available_balance}</td>
                      <td>
                        <div className='bg-[#D9D9D9] p-1 flex items-center justify-center rounded'>
                          <span className='text- capitalize text-gray-400 '>
                            {item.audit_status || 'Pending'}
                          </span>
                        </div>
                      </td>
                      <td>
                        <div className='bg-[#D9D9D9] p-1 flex items-center justify-center rounded'>
                          <span className='text- capitalize text-gray-400 '>
                            {item.payout_status || 'Pending'}
                          </span>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='11'>
                      <div className='my-4 mx-auto text-center'>
                        <p>No records</p>
                      </div>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan='11'>
                    <div className='my-4 mx-auto text-center'>
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}
            </CsTable>
          </div>
        </div>
        {/* Pagination start */}
        <div className='flex flex-wrap w-full justify-end  min-h-[41px] mb-[22px] gap-2  md:min-h-[27px] md:mb-[7px]  '>
          <div className='rounded w-max ml-auto my-2 overflow-hidden flex '>
            <Button
              onClick={() => handlePageChange('fastBackward')}
              disabled={!isPrev}
              classes={`  px-[12px] disabled:opacity-50 disabled:cursor-not-allowed rounded-none ${
                activePage === 'fastBackward'
                  ? 'bg-[#01416d] text-white rounded-none'
                  : 'rounded-none'
              }`}
            >
              <PagingDoubleLeftIcon />
            </Button>
            <Button
              onClick={() => handlePageChange('backward')}
              disabled={!isPrev}
              classes={`px-[12px] disabled:opacity-50 disabled:cursor-not-allowed rounded-none ${
                activePage === 'backward' ? 'bg-[#01416d] text-white rounded-none' : 'rounded-none'
              }`}
            >
              <PagingLeftIcon />
            </Button>
            {/* Page Number Buttons */}
            {[1, 2, 3, 4, 5].map((pageNumber) => (
              <Button
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber.toString())}
                disabled={isPageButtonDisabled(pageNumber)}
                classes={`  px-[12px] ${
                  activePage === pageNumber
                    ? 'bg-[#01416d] text-white rounded-none'
                    : 'rounded-none'
                }
                } ${isPageButtonDisabled(pageNumber) ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                <span className='font-normal'>{pageNumber}</span>
              </Button>
            ))}
            <Button
              onClick={() => handlePageChange('forward')}
              disabled={!isNext}
              classes={`px-[12px] ${
                activePage === 'forward' ? 'bg-[#01416d] text-white rounded-none' : 'rounded-none'
              }`}
            >
              <PagingRightIcon />
            </Button>
            <Button
              onClick={() => handlePageChange('fastForward')}
              disabled={!isNext}
              classes={`px-[12px] ${
                activePage === 'fastForward'
                  ? 'bg-[#01416d] text-white rounded-none'
                  : 'rounded-none'
              }`}
            >
              <PagingDoubleRightIcon />
            </Button>
          </div>
          <div className='w-auto  flex items-center justify-center rounded mb-6 md:mb-0'>
            <select onChange={handlePerPageChange} name='' id='' className='border p-1 rounded'>
              <option value='10'>10 / page</option>
              <option value='30'>30 / page</option>
              <option value='60'>60 / page</option>
              <option value='100'>100 / page</option>
            </select>
          </div>
        </div>
      </div>
    </>
  )
}

export default Payout
