import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllAgents } from 'api/api'

export const getAgentData = createAsyncThunk(
  'agent/getAgentData',
  async ({ itemsPerPage, skip }) => {
    return await getAllAgents(itemsPerPage || '', skip || '')
  },
)
const initialState = {
  data: [],
  isLoading: true,
}

export const agentSlice = createSlice({
  name: 'agent',
  initialState,
  reducers: {
    setAllAgent: (state, action) => {
      state.data = action.payload.data
      state.isLoading = action.payload.isLoading
    },
  },
  extraReducers: {
    [getAgentData.pending]: (state) => {
      state.isLoading = true
    },
    [getAgentData.fulfilled]: (state, action) => {
      state.data = action.payload || []
      state.isLoading = false
    },
    [getAgentData.rejected]: (state) => {
      state.data = []
      state.isLoading = false
    },
  },
})
export const { setAllAgent } = agentSlice.actions

export default agentSlice.reducer
