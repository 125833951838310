import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import { useSortableData } from 'pages/Dashboard/components/useSortableData'

import { getPayoutCards } from 'api/RESTful/card'
import { getWalletDetails } from 'api/RESTful/wallet'

import { IndicatorIcon } from 'utils/Icons'

import Spinner from 'utils/Spinner'

const PayoutCardTable = ({ onCardSelect, payoutTo }) => {
  const payoutCardsColumn = [
    { name: 'Name' },
    { name: 'Card Number' },
    { name: 'Bank Name' },
    { name: 'Card Available Balance' },
    { name: 'Status' },
    { name: 'Action' },
  ]

  const [isLoading, setIsLoading] = useState(false)
  const [cardsData, setCardsData] = useState([])
  const [selectedCard, setSelectedCard] = useState('')

  const selectCard = (cardId) => {
    setSelectedCard(cardId)
    onCardSelect(cardId)
  }

  const fetchPayoutCards = async () => {
    try {
      setIsLoading(true)
      const response = await getPayoutCards(10, 0, payoutTo === 'MERCHANT')
      const totalCards = response.total
      const allCardsResponse = await getPayoutCards(totalCards, 0, payoutTo === 'MERCHANT')
      const allCards = allCardsResponse.data
      const filteredCards = allCards.filter(
        (card) => card.card_type === 'DP_WD' || card.card_type === 'WD',
      )
      const cardsWithBalances = await Promise.all(
        filteredCards.map(async (card) => {
          const walletDetails = await getWalletDetails(card.wallet_id)
          return { ...card, available_balance: walletDetails.available_balance }
        }),
      )
      setCardsData(cardsWithBalances)
    } catch (error) {
      toast.error(error.message || "Cannot get merchant's cards, Please try again.")
    } finally {
      setIsLoading(false)
    }
  }

  const { CsTable } = useSortableData(cardsData)

  useEffect(() => {
    fetchPayoutCards()
  }, [payoutTo])

  return (
    <>
      <div className='min-h-[571px]'>
        <div className='border rounded md:mb-4 mb-2 mt-5'>
          <div className='md:px-3 px-2 py-1 mb-3'>
            <h5 className='m-4 font-semibold text-2xl'>Payout From</h5>
            <div className='md:mt-2'>
              <CsTable loading={isLoading} columns={payoutCardsColumn} tableClasses='header_fill'>
                {!isLoading ? (
                  cardsData?.length > 0 ? (
                    cardsData.map((item, index) => (
                      <tr key={index}>
                        <td>
                          <div className='text-center'>{item?.card_nickname}</div>
                        </td>
                        <td>
                          <div className='text-center'>{item?.bank_card_number}</div>
                        </td>
                        <td>
                          <div className='text-center'>{item?.bank_name}</div>
                        </td>
                        <td>
                          <div className='text-center'>{item?.available_balance}</div>
                        </td>
                        <td>
                          <div className='flex items-center justify-center'>
                            <span className='mr-2'>
                              <IndicatorIcon
                                type={item?.status === 'INACTIVE' ? 'inactive' : 'active'}
                              />
                            </span>
                            <p className='text_sm'>
                              {item?.status === 'INACTIVE' ? 'Inactive' : 'Active'}
                            </p>
                          </div>
                        </td>
                        <td>
                          <div
                            className={`${
                              selectedCard === item.id ? 'bg-[#7367F0] text-white' : ''
                            } px-2 border justify-center flex items-center rounded cursor-pointer font-medium`}
                            onClick={() => selectCard(item.id)}
                          >
                            {selectedCard === item.id ? 'Selected' : 'Select'}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan='6'>
                        <div className='my-4 mx-auto text-center'>
                          <p>No records</p>
                        </div>
                      </td>
                    </tr>
                  )
                ) : (
                  <tr>
                    <td colSpan='6'>
                      <div className='my-4 mx-auto text-center'>
                        <Spinner />
                      </div>
                    </td>
                  </tr>
                )}
              </CsTable>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PayoutCardTable
