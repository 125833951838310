// app routes wrapper
import AppRouter from 'config/AppRouter'
// redux
import { useDispatch, useSelector } from 'react-redux'
import ScrollTop from 'utils/scrollTop'
// import { ToastContainer } from "react-toastify";
import { useEffect } from 'react'
import { useLocalStorage } from 'hook/useLocalStorage'
import { setAuth } from 'store/reducers/authReducerSlice'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import jwt_decode from 'jwt-decode'
// css
import 'react-date-range/dist/styles.css' // main css file
import 'react-date-range/dist/theme/default.css' // theme css file
import 'react-toastify/dist/ReactToastify.css'
import 'assets/css/style.css'

// ============== App component, Main Wrapper =====================
const App = () => {
  // get user token from local storage
  const { localValue } = useLocalStorage('picotop_user_token', '')
  const { localValue: userId } = useLocalStorage('picotop_user_id', '')
  const { localValue: userEmail } = useLocalStorage('picotop_user_email', '')

  // redux dispatcher
  const dispatch = useDispatch()
  useEffect(() => {
    // check for current user is authenticated or not
    const checkUser = () => {
      if (localValue) {
        // decoded
        const decoded = jwt_decode(localValue)
        axios.defaults.headers.common['Authorization'] = `Bearer ${localValue}`
        setTimeout(() => {
          dispatch(
            setAuth({
              isAuthenticated: true,
              isLoading: false,
              data: {
                email: userEmail || '',
                userId: userId,
                merchantId: decoded.merchantId || '',
                roles: [decoded.role],
              },
            }),
          )
        }, 200)
      } else {
        dispatch(
          setAuth({
            isAuthenticated: false,
            isLoading: false,
            data: {},
          }),
        )
      }
    }
    checkUser()
    return () => checkUser()
  }, [localValue])

  return (
    <>
      <AppRouter />
      <ScrollTop />
      <ToastContainer
        position='bottom-right'
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </>
  )
}

export default App
