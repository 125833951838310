import React from 'react'
import warning from 'assets/images/svgs/warning.svg'

const CheckoutExpired = () => {
  return (
    <>
      <div className='flex justify-center items-center min-h-[100vh]'>
        <div className='flex flex-col gap-5 items-center justify-center'>
          <h1 className='flex items-center gap-3 text-5xl font-bold'>
            <img src={warning} alt='' /> <span>您的订单已超时</span>
          </h1>
          <h2 className='text-4xl font-normal leading-[50px]'>
            如未付款，请到商户页面重新下单；
            <br />
            如已付款，请联络商户以取得协助
          </h2>
          <h2 className='text-[#CCCCCC] text-4xl font-normal mt-6'>3秒后跳转返回商户 (3)</h2>
        </div>
      </div>
    </>
  )
}

export default CheckoutExpired
