import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import TabTitle from 'pages/Dashboard/components/TabTitle'
import React, { useState } from 'react'
import editIcon from 'assets/images/icons/editIcon.png'
import { Form, Formik } from 'formik'
import { initialValues, UsersSchema } from '../Config/profileSchema'
import { toast } from 'react-toastify'
import UsersFormWrapper from '../Components/UsersFormWrapper'
import * as Yup from 'yup'
import CsModal from 'utils/Modal'
import acceptIconImg from 'assets/images/icons/acceptIcon.png'
import { Link } from 'react-router-dom'
import { createUser } from 'api/api'
import { useDispatch } from 'react-redux'
import { getUserData } from 'store/reducers/UserReducer'

const AddNewUser = () => {
  const dispatch = useDispatch()
  const [modalOpen, setModal] = useState({ open: false })
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let obj = values
    createUser(obj)
      .then((res) => {
        dispatch(getUserData())
        resetForm()
        setSubmitting(false)
        setModal({ open: true })
        toast.success('New User has been created')
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.response.data.message)
        setSubmitting(false)
      })
  }
  // handle close modal
  const handleCloseModal = () => {
    setModal({ open: false })
  }
  return (
    <div>
      {/* Breadcrumbs of the page */}
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '/dashboard/commision-profile' },
          { title: 'New User', path: '' },
        ]}
      />
      {/* TabTitle of the page */}
      <TabTitle title={'New User'} icon={<img src={editIcon} alt='' />} />
      {/* Form to create a new profile */}
      <Formik
        initialValues={initialValues}
        validationSchema={UsersSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, handleChange, values, errors, touched }) => (
          <Form autoComplete='off'>
            <UsersFormWrapper
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              type='add'
            />
          </Form>
        )}
      </Formik>
      <CsModal open={modalOpen.open} onClose={handleCloseModal}>
        <div className='bg-white text-center fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-[1055] w-max h-max max-h-[calc(100%-100px) ] overflow-x-hidden overflow-y-auto outline-none min-w-[550px] max-w-[730px]  rounded md:px-3 md:py-4 px-2 py-3'>
          <div className='flex justify-center items-center my-2 '>
            <span className=' flex items-center mr-2'>
              <img src={acceptIconImg} alt='' />
            </span>
            <p className='ml-1'>The agent has been created successfully</p>
          </div>
          <div className='my-2 flex flex-wrap justify-center items-center'>
            <Link to='/dashboard/setting/users' className='cs_btn mx_2' onClick={handleCloseModal}>
              Back to Profile
            </Link>
          </div>
        </div>
      </CsModal>
    </div>
  )
}

export default AddNewUser
