import { createSlice } from '@reduxjs/toolkit'

const initialState = { loading: false }

export const loadingReducerSlice = createSlice({
  name: 'loadingSlice',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload
    },
  },
})

export const { setLoading } = loadingReducerSlice.actions

export default loadingReducerSlice.reducer
