import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../../features/Dashboard/components/BreadCrumbs'
import TabTitle from '../components/TabTitle'
import walletIcon from 'assets/images/icons/walletIcon.png'
import {
  CloseIcon,
  IndicatorIcon,
  PagingDoubleLeftIcon,
  PagingDoubleRightIcon,
  PagingLeftIcon,
  PagingRightIcon,
  SearchIcon,
} from 'utils/Icons'
import { wallet_status } from 'utils/dropdownOptions'
import Button from 'utils/Button'
import Spinner from 'utils/Spinner'
import { useSortableData } from '../components/useSortableData'
import { lowerString } from 'utils/helpers'
import { Link } from 'react-router-dom'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getWalletData } from 'store/reducers/walletSlice'
import { formatDateTime } from 'utils/formatDate'
import Card from '../components/Card'
import { formatAmount } from 'utils/formatAmount'
const Wallet = () => {
  const dispatch = useDispatch()
  const [picotopBalance, setPicotopBalance] = useState(0)
  const { data: walletData, isLoading } = useSelector((store) => store.wallet)
  const walletColumn = [
    { name: '#' },
    { title: 'created_at', name: 'Created Date', sort: true },
    { title: 'name', sort: true, name: 'Name' },
    { name: 'Account Balance' },
    { name: 'Available Balance' },
    { name: 'Frozen' },
    { name: 'Status' },
    { title: 'last_updated', name: 'Last Updated', sort: true },
    { name: 'Action' },
  ]

  const [searchState, setSearchState] = useState({
    loading: false,
    data: [],
    searching: false,
  })
  const [formData, setFormData] = useState({
    query_text: '',
  })

  const { items: tblData, CsTable } = useSortableData(
    searchState.searching ? searchState.data : walletData,
    walletColumn,
  )

  // buttons pagination
  const [paginationBtns, setPaginationBtns] = useState([
    { disable: false, number: 1 },
    { disable: false, number: 2 },
    { disable: false, number: 3 },
    { disable: false, number: 4 },
    { disable: false, number: 5 },
  ])
  // .................................
  const [totalPages, setTotalPages] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [isNextDisabled, setIsNextDisabled] = useState(false)
  const [isPrevDisabled, setIsPrevDisabled] = useState(true)

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset to the first page when itemsPerPage changes
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
    if (page === 'forward' || page === 'fastForward') {
      setCurrentPage(currentPage + 1)
    }
    if (page === 'backward' || page === 'fastBackward') {
      setCurrentPage(currentPage - 1)
    }
  }
  //  function for disabling the pagination btns when no data is there in the next page
  useEffect(() => {
    let btns = [...paginationBtns]
    if (tblData && !isLoading) {
      let totalItems = tblData?.length
      if (totalItems < itemsPerPage) {
        let changedButtons = btns?.map((btn) => {
          return {
            ...btn,
            disable: btn.number > currentPage,
          }
        })
        if (currentPage === 1) {
          setIsPrevDisabled(true)
        }
        setIsPrevDisabled(false)
        setIsNextDisabled(true)
        return setPaginationBtns(changedButtons)
      }
      if ((totalItems = itemsPerPage)) {
        let changedButtons = btns?.map((btn) => {
          return {
            ...btn,
            disable: btn.number < currentPage,
          }
        })
        if (currentPage === 1) {
          setIsPrevDisabled(true)
        }
        setIsNextDisabled(false)
        return setPaginationBtns(changedButtons)
      }
    }
  }, [tblData.length])

  useEffect(() => {
    const skip = (currentPage - 1) * itemsPerPage

    const take = itemsPerPage

    dispatch(getWalletData({ query: '', take, skip }))

    setIsNextDisabled(currentPage === totalPages || isLoading)
    setIsPrevDisabled(currentPage === 1 || isLoading)
  }, [dispatch, itemsPerPage, currentPage])

  useEffect(() => {
    const skip = (currentPage - 1) * itemsPerPage
    dispatch(getWalletData({ query: '', itemsPerPage, skip }))
  }, [dispatch, itemsPerPage, currentPage])
  // redux

  // it will handle search api request
  const searchTblData = (e) => {
    e.preventDefault()
  }

  // cancel search
  const cancelSearch = () => {
    setFormData({ ...formData, query_text: '' })
    setSearchState({ data: [], loading: false, searching: false })
  }

  // search form handler
  const handleForm = (e) => {
    const { name, value } = e.target
    if (value && value.trim()?.length > 0) {
      setFormData({
        ...formData,
        [name]: value,
      })
    } else {
      cancelSearch()
    }
  }

  useEffect(() => {
    dispatch(getWalletData())
    const picotopData = tblData.find((item) => item.is_master === true)
    if (picotopData) {
      setPicotopBalance(picotopData.available_balance)
    }
  }, [])

  const filterByValues = (data) => {
    let list = [...data]
    return list.filter(
      (item) =>
        lowerString(item?.name)?.match(lowerString(formData?.query_text)) ||
        lowerString(item?.id)?.match(lowerString(formData?.query_text)) ||
        lowerString(item?.email)?.match(lowerString(formData?.query_text)),
    )
  }

  return (
    <div className='flex flex-col h-full mb-8'>
      {/* Page BreadCrumbs */}
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '' },
          { title: 'Wallet', path: '' },
        ]}
      />
      {/* Tab title */}
      <div className='mr-2'>
        <TabTitle title={'Wallet'} icon={<img src={walletIcon} alt='' />} />
      </div>
      {/* Search Form */}
      <div className='flex flex-wrap md:mt-6 mt-2'>
        <form onSubmit={searchTblData}>
          <div className='w-full flex items-center flex-wrap'>
            <div>
              <label htmlFor='query_text' className='font-medium'>
                Merchant Name
              </label>
              <div className='relative overflow-hidden'>
                <input
                  type='text'
                  id='query_text'
                  name='query_text'
                  value={formData.query_text}
                  onChange={handleForm}
                  className='w-100 border focus:outline-none m-1 p-[2px] rounded pl-3'
                />
                {formData.query_text?.length > 0 && (
                  <span
                    onClick={cancelSearch}
                    tabIndex='0'
                    role='button'
                    className='absolute top-[50%] -translate-y-[50%] right-1 pl-0 pr-2 pt-[8px] pb-[5px] h-[78%] flex justify-center items-center cursor-pointer select-none bg-white border border-l-0'
                  >
                    <CloseIcon />
                  </span>
                )}
              </div>
            </div>

            {/* status Drop Down */}
            <div className='pr-2'>
              <label htmlFor='status' className='font_500'>
                Status
              </label>
              <div className='cs_row items-center'>
                <div className='relative overflow-hidden pr-2'>
                  <select
                    name='status'
                    id='status'
                    value={formData.status}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        status: e.target.value,
                      })
                    }}
                    className='w-100 border focus:outline-none m-1 p-[2px] rounded pl-3'
                  >
                    <option value=''>Select</option>
                    {wallet_status?.map((v, i) => (
                      <option value={v} key={i}>
                        {v}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>

            {/* -------------- */}
            <Button
              classes='md:ml-[1rem] my-0 ms-2 mr-20  bg-purple-500 text-white  flex  bg-purple-500 border-purple-500 rounded cursor-pointer select-none  justify-center min-h-[30px] w-max  max-w-full text-base font-semiBold px-2 py-1 leading-6 my-[6px]   flex items-center'
              style={{ opacity: searchState.loading ? '.8' : '1' }}
            >
              {!searchState.loading ? (
                <SearchIcon className='me_2' />
              ) : (
                <div className='me_2'>
                  <Spinner type='spinner' />
                </div>
              )}
              Search
            </Button>
          </div>
        </form>
        <div className='col lg:w-[25%] w-[50%] md:pl-0 min-w-[280px]'>
          <Card cardClasses='py-4'>
            <p className='text-[20px]'>Picotop Account balance</p>
            <div className='md:mt-2 flex flex-wrap justify-end items-center'>
              <p className='lg:px-2 pr-2 text-[20px]'>CNY</p>
              <h6 className='font-medium text-[20px] lg:ml-0 ml-auto'>
                {formatAmount(picotopBalance)}
              </h6>
            </div>
          </Card>
        </div>
      </div>
      {/* Data table */}
      <div className='mt-7'>
        <div className='md:my-4 border rounded my-2'>
          <CsTable
            columns={walletColumn}
            loading={isLoading}
            tableClasses='header_fill'
            hideScrollbar
          >
            {!isLoading ? (
              filterByValues(tblData)?.length > 0 ? (
                filterByValues(tblData).map((item, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      <div className='text-center'>{formatDateTime(item?.created_at)}</div>
                    </td>
                    <td>
                      <div className='text-center'>{item?.name}</div>
                    </td>
                    <td>
                      <div className='text-center'>
                        {item?.available_balance + item?.frozen_balance}
                      </div>
                    </td>
                    <td>
                      <div className='text-center'>{item?.available_balance}</div>
                    </td>

                    <td>
                      <div className='text-center'>{item?.frozen_balance}</div>
                    </td>

                    <td className=''>
                      <div className='flex items-center justify-start'>
                        <span className='mr-2'>
                          <IndicatorIcon type={item?.is_active ? 'Active' : 'Inactive'} />
                        </span>
                        <p className='text_sm'>{item?.is_active ? 'Active' : 'Inactive'}</p>
                      </div>
                    </td>
                    <td className='text-center'>
                      {item.last_updated ? formatDateTime(item.last_updated) : 'N/A'}
                    </td>
                    <td className='text-center'>
                      <Link to={'details/' + item?.id} className='px-2 text-blue-400 underline'>
                        Details
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='9' className='text-center'>
                    {' '}
                    {/* Updated line */}
                    <div className='my-4 mx-auto'>
                      <p>No records</p>
                    </div>
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan='5'>
                  <div className='my-4 mx-auto text-center'>
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}
          </CsTable>
        </div>
      </div>

      {/* --------------------------- */}
      {/* table pagination */}
      <div className='flex flex-wrap w-full justify-end  min-h-[41px] mb-[22px] gap-2  md:min-h-[27px] md:mb-[7px]'>
        <div className='rounded w-max ml-auto my-2 overflow-hidden flex '>
          <Button
            disabled={isPrevDisabled}
            onClick={() => handlePageChange('backward')}
            classes={`  px-[12px] disabled:opacity-50 disabled:cursor-not-allowed rounded-none ${'rounded-none'}`}
          >
            <PagingDoubleLeftIcon />
          </Button>
          <Button
            disabled={isPrevDisabled}
            onClick={() => handlePageChange('fastBackward')}
            classes={`  px-[12px] disabled:opacity-50 disabled:cursor-not-allowed rounded-none ${'rounded-none'}`}
          >
            <PagingLeftIcon />
          </Button>
          {paginationBtns.map((btn) => (
            <Button
              disabled={btn?.disable}
              key={btn.number}
              onClick={() => handlePageChange(btn.number)}
              classes={`  px-[12px] ${
                currentPage === btn.number ? 'bg-[#01416d] text-white rounded-none' : 'rounded-none'
              }`}
            >
              <span className='font-normal'>{btn.number}</span>
            </Button>
          ))}
          <Button
            disabled={isNextDisabled}
            onClick={() => handlePageChange('forward')}
            classes={`  px-[12px] ${'rounded-none'}`}
          >
            <PagingRightIcon />
          </Button>
          <Button
            disabled={isNextDisabled}
            onClick={() => handlePageChange('fastForward')}
            classes={`  px-[12px] ${'rounded-none'}`}
          >
            <PagingDoubleRightIcon />
          </Button>
        </div>
        <div className='w-auto  flex items-center justify-center rounded my-2 '>
          <select onChange={handlePerPageChange} name='' id='' className='border p-1 rounded'>
            <option value='10'>10 / page</option>
            <option value='30'>30 / page</option>
            <option value='60'>60 / page</option>
            <option value='100'>100 / page</option>
          </select>
        </div>
      </div>
    </div>
  )
}

export default Wallet
