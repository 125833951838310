import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { getAllMerchant } from 'api/api'

export const getMerchantData = createAsyncThunk(
  'merchant/getMerchantData',
  async ({ query, itemsPerPage, skip }) => {
    return await getAllMerchant(query || '', itemsPerPage || '', skip || '')
  },
)

const initialState = {
  data: [],
  isLoading: false,
}

export const merchantSlice = createSlice({
  name: 'merchant',
  initialState,
  reducers: {
    setAllMerchant: (state, action) => {
      state.data = action.payload.data
      state.isLoading = action.payload.isLoading
    },
  },
  extraReducers: {
    [getMerchantData.pending]: (state) => {
      state.isLoading = true
    },
    [getMerchantData.fulfilled]: (state, action) => {
      state.data = action.payload || []
      state.isLoading = false
    },
    [getMerchantData.rejected]: (state) => {
      state.data = []
      state.isLoading = false
    },
  },
})

export const { setAllMerchant } = merchantSlice.actions

export default merchantSlice.reducer
