import moment from 'moment'
import Table from 'pages/Dashboard/components/Table'
import { useSortableData } from 'pages/Dashboard/components/useSortableData'
import React, { useState } from 'react'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { IndicatorIcon } from 'utils/Icons'
import Spinner from 'utils/Spinner'
import { numberWithCommas } from 'utils/helpers'
import { getAgentdetails } from 'api/api'
import { useParams } from 'react-router-dom'

const CardTable = () => {
  const [loadingData, setLoadingData] = useState(false)
  const [cardsData, setCardsData] = useState([])
  const { id } = useParams()
  const { data: agentData, isLoading } = useSelector((store) => store.agent)
  // these are the config for table header
  const cardsTableColumns = [
    { name: '#' },
    { name: 'Name', title: 'bank_card_holder_name', sort: true },
    {
      name: <div className='text-left flex-grow'>Card Number</div>,
      title: 'bank_card_number',
    },
    {
      name: <div className='text-start'>Bank Name</div>,
      title: 'bank_name',
      sort: true,
    },
    { name: 'Currency', title: 'currency' },
    { name: 'Max limit', title: 'max_limit' },
    { name: 'Status', title: 'status' },
    { name: 'Last Updated', title: 'last_updated', sort: true },
    { name: 'Action', sort: false },
  ]

  const fetchCardDetails = async (id) => {
    try {
      const agentData = await getAgentdetails(id)
      setCardsData(agentData.card)
    } catch (error) {
      console.log('Error fetching card details:', error)
    }
  }

  const [loadingFunc, setLoadingFunc] = useState(false)
  // Table hook
  const { items: cardData, CsTableHead } = useSortableData(cardsData, cardsTableColumns)

  useEffect(() => {
    fetchCardDetails(id)
  }, [id])

  return (
    <div className='border rounded md:my-3 my-2'>
      <div className=' px_md_3 px-2 py-2 flex items-center'>
        <div className='flex items-center'>
          <h6 className='text-2xl font-bold p-2'>Related Cards</h6>
        </div>
      </div>
      <Table CsTableHead={<CsTableHead />}>
        {loadingData ? (
          <tr>
            <td colSpan='9' className='text-center'>
              <div className='w-full py-3 px-2'>
                <Spinner />
                <h6 className='py-2 w-full text-center'>Loading...</h6>
              </div>
            </td>
          </tr>
        ) : cardsData.length <= 0 ? (
          <tr>
            <td colSpan='9' className='text-center'>
              <div className='w-full py-3 px-2'>No Records</div>
            </td>
          </tr>
        ) : (
          cardsData?.map((item, i) => {
            //  function for formating the given time and date to only time from API
            const lastLogin = new Date(item?.last_updated)
            const login_date = lastLogin.toLocaleDateString()

            return (
              <>
                {cardsData.length > 0 ? (
                  <tr key={i} className='z-0'>
                    <td>{i + 1}</td>

                    {/* 1 */}
                    <td>
                      <div className='flex items-center justify-center'>
                        {item.bank_card_holder_name}
                      </div>
                    </td>
                    {/* 2 */}
                    <td>
                      <div className='flex'>{item.bank_card_number}</div>
                    </td>
                    {/* 3 */}
                    <td>
                      <div className='flex items-center justify-center'>{item.bank_name}</div>
                    </td>
                    {/* 4 */}
                    <td>
                      <div className='flex items-center justify-center'>
                        {item.payin_daily_max_limit_currency}
                      </div>
                    </td>
                    {/* 5 */}
                    <td>
                      <div className='flex items-center justify-center'>
                        {numberWithCommas(item.payin_per_transaction_max_limit)}
                      </div>
                    </td>
                    {/* 6 */}
                    <td>
                      <div className='flex items-center justify-center'>
                        {item?.newRecord || item?.editEnable ? null : (
                          <div className='flex items-center justify-start'>
                            <span className='md:mx-2 mx-1'>
                              <IndicatorIcon type={item?.status} />
                            </span>
                            {item?.status === 'ACTIVE' ? 'Active' : 'Inactive'}
                          </div>
                        )}
                      </div>
                    </td>
                    {/* 7*/}
                    <td>
                      <div className='flex items-center justify-center'>{login_date}</div>
                    </td>
                    {/* 5 */}
                    <td>
                      <div className='flex items-center justify-center'>
                        <div className=' flex items-center justify-center'>
                          <Link to={`/dashboard/all-cards/details/${item?.id}`}>
                            <p className='text-[#499CD6] underline'>Details</p>
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <Spinner />
                )}
              </>
            )
          })
        )}
      </Table>
    </div>
  )
}

export default CardTable
