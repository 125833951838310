import { phoneRegExp } from 'utils/dropdownOptions'
import * as Yup from 'yup'
export const initialValues = {
  first_name: '',
  last_name: '',
  email: '',
  phone: '+852',
  role: '',
}

export const UsersProfileSchema = {
  first_name: Yup.string().min(2, 'Too Short!').max(30, 'Too Long!').required('Required'),
  last_name: Yup.string().min(2, 'Too Short!').max(30, 'Too Long!').required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.string()
    .matches(phoneRegExp, 'Phone number is not valid')
    .min(9, 'too short')
    .max(15, 'too long'),
  role: Yup.string().required('Required'),
}

export const UsersSchema = Yup.object(UsersProfileSchema).shape({})
