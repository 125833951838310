import { Routes, Route, Outlet, Navigate, useLocation } from 'react-router-dom'
// util
import { containsAllRoles } from 'utils/helpers'
// pages
import Dashboard from 'pages/Dashboard'
import Home from 'pages/Home'
// imports for MERCHANT PORTAL
import MPMerchantDetail from 'pages/MerchantPortal/MerchantDetails'
import MPOrder from 'pages/MerchantPortal/MPOrder/MPOrder'
import MPOrderDetails from 'pages/MerchantPortal/MPOrder/MPOrderDetails'
import MPPayment from 'pages/MerchantPortal/MPPayment/MPPayment'
import MPPaymentDetails from 'pages/MerchantPortal/MPPayment/MPPaymentDetails'
import MPPayoutDetails from 'pages/MerchantPortal/MPPayout/MPPayoutDetails'
import MPWalletDetails from 'pages/MerchantPortal/MPWallet/MPWalletDetails'
// import Overview from "pages/Dashboard/Overview";
import Merchant from 'pages/Dashboard/Merchant'
import MerchantAddNew from 'pages/Dashboard/Merchant/MerchantAddNew/AddNew'
import MerchantDetail from 'pages/Dashboard/Merchant/MerchantDetail'
import { useSelector } from 'react-redux'
import Spinner from 'utils/Spinner'
import Login from 'pages/Login'
import Agent from 'pages/Dashboard/Agent'
import AddNewAgent from 'pages/Dashboard/Agent/AddNewAgent/AddNewAgent'
import AgentDetails from 'pages/Dashboard/Agent/AgentDetails/AgentDetails'
import AllCards from 'pages/Dashboard/Card'
import CreateCard from 'pages/Dashboard/Card/AddNewCard/CreateCard'
import CardDetails from 'pages/Dashboard/Card/CardDetails/CardDetails'
import CardAssignment from 'pages/Dashboard/Card/CardAssignment'
import CommisionProfile from 'pages/Dashboard/CommisionProfile'
import AddNewProfile from 'pages/Dashboard/CommisionProfile/AddNewProfile/AddNewProfile'
import ProfileDetails from 'pages/Dashboard/CommisionProfile/ProfileDetails/ProfileDetails'
import Wallet from 'pages/Dashboard/Wallet'
import WalletDetails from 'pages/Dashboard/Wallet/WalletDetails/WalletDetails'
import Order from 'pages/Dashboard/Order'
import OrderDetails from 'pages/Dashboard/Order/OrderDetails/OrderDetails'
import Payment from 'pages/Dashboard/Payment'
import PaymentDetails from 'pages/Dashboard/Payment/PaymentDetails/PaymentDetails'
import AddToBlockList from 'pages/Checkout/AddToBlockList'
import CancelCheckout from 'pages/Checkout/Cancel'
import CheckoutSuccessful from 'pages/Checkout/CheckoutSuccessful'
import CheckoutExpired from 'pages/Checkout/CheckoutExpired'
import Checkout from 'pages/Checkout'
import Payout from 'pages/Dashboard/Payout'
import CreatePayout from 'pages/Dashboard/Payout/CreatePayout.jsx'
import CreateManyPayout from 'pages/Dashboard/Payout/CreatePayoutMany'
import PayoutDetails from 'pages/Dashboard/Payout/PayoutDetails'
import User from 'pages/Dashboard/User'
import AddNewUser from 'pages/Dashboard/User/AddNewUser/AddNewUser'
import UsersDetails from 'pages/Dashboard/User/UsersDetails/UsersDetails'
import CardsPriority from 'pages/Dashboard/Merchant/MerchantDetail/CardsPriority'
import ChangePasswordPage from 'pages/Dashboard/ChangePasswordPage'
// -------- not found/ not access ------------
import PageNotFound from 'pages/PageNotFound'
// ------ Merchant portal -----------------------
import MerchantPortal from 'pages/MerchantPortal'
import AccessDenied from 'pages/AccessDenied'
import MPUser from 'pages/MerchantPortal/MPUser/MPUser'
import MPPayout from 'pages/MerchantPortal/MPPayout/MPPayout'
import TopUpCard from 'pages/Dashboard/Card/BalanceAdjustment/TopUp'
import DebitCard from 'pages/Dashboard/Card/BalanceAdjustment/Debit'

// ========= This is a app router contains all the routes of the websites.
//  with authentication and user roles base routes

const AppRouter = () => {
  return (
    <Routes>
      {/* ==== auth routes, login register  ======== */}
      <Route exact path='/login' element={<Login />} />
      {/* ===== Home routes ========== */}
      <Route path='/' exact element={<Home />} />
      <Route path='home' exact element={<Home />} />
      {/* ===== dashboard routes with protected routes ========== */}
      <Route
        path='dashboard'
        exact
        element={
          <ProtectedRoute redirectPath='/login' roles={['SUPER_ADMIN']}>
            <Dashboard />
          </ProtectedRoute>
        }
      >
        {/*  merchant routes  */}
        <Route exact path='merchant' element={<Merchant />} />
        <Route exact path='merchant/addNew' element={<MerchantAddNew />} />
        <Route exact path='merchant/details/:id' element={<MerchantDetail />} />
        <Route exact path='merchant/details/:id/cardspriority/:feeId' element={<CardsPriority />} />
        {/* Agent Routes */}
        <Route exact path='agents' element={<Agent />} />
        <Route exact path='agents/addNew' element={<AddNewAgent />} />
        <Route exact path='agents/details/:id' element={<AgentDetails />} />

        {/* Profile Routes */}
        <Route exact path='commision-profile' element={<CommisionProfile />} />
        <Route exact path='commision-profile/addNew' element={<AddNewProfile />} />
        <Route exact path='commision-profile/details/:id' element={<ProfileDetails />} />
        {/* Cards Routes */}
        <Route exact path='all-cards' element={<AllCards />} />
        <Route exact path='all-cards/addNew' element={<CreateCard />} />
        <Route exact path='all-cards/details/:id' element={<CardDetails />} />
        <Route exact path='all-cards/details/:id/card-assignment' element={<CardAssignment />} />
        <Route exact path='all-cards/details/:id/top-up-card' element={<TopUpCard />} />
        <Route exact path='all-cards/details/:id/debit' element={<DebitCard />} />
        {/* Wallet Routes */}
        <Route exact path='wallet' element={<Wallet />} />
        <Route exact path='wallet/details/:id' element={<WalletDetails />} />
        {/* Order Routes */}
        <Route exact path='orders' element={<Order />} />
        <Route exact path='orders/details/:orderID' element={<OrderDetails />} />
        {/* Payment Routes */}
        <Route exact path='payment' element={<Payment />} />
        <Route exact path='payment/details/:paymentID' element={<PaymentDetails />} />
        {/* Payout Routes */}
        <Route exact path='payout' element={<Payout />} />
        <Route exact path='payout/addNew' element={<CreatePayout />} />
        <Route exact path='payout/addNewMany' element={<CreateManyPayout />} />
        <Route exact path='payout/details/:payoutID' element={<PayoutDetails />} />
        {/* User Tab */}
        <Route exact path='setting/users' element={<User />} />
        <Route exact path='setting/users/addNew' element={<AddNewUser />} />
        <Route exact path='setting/users/details/:id' element={<UsersDetails />} />
        {/* ------------------------------------------------------------------------- */}
        <Route exact path='change-password' element={<ChangePasswordPage />} />
      </Route>
      {/* Checkout Routes */}
      <Route exact path='/checkout' element={<Checkout />} />
      <Route exact path='/checkout/addToBlockList' element={<AddToBlockList />} />
      <Route exact path='/checkout/cancelCheckout' element={<CancelCheckout />} />
      <Route exact path='/checkout/checkoutSuccessful' element={<CheckoutSuccessful />} />
      <Route exact path='/checkout/checkoutExpire' element={<CheckoutExpired />} />

      {/* Routes for merchant portal */}
      <Route
        path='/merchantPortal'
        exact
        element={
          <ProtectedRoute redirectPath='/login' roles={['MERCHANT_ADMIN']}>
            <MerchantPortal />
          </ProtectedRoute>
        }
      >
        {/* Merchant Portal routes  */}
        <Route exact path='merchant' element={<MPMerchantDetail />} />
        <Route exact path='orders' element={<MPOrder />} />
        <Route exact path='orders/details/:orderID' element={<MPOrderDetails />} />
        <Route exact path='payment' element={<MPPayment />} />
        <Route exact path='payment/details/:paymentID' element={<MPPaymentDetails />} />
        <Route exact path='user' element={<MPUser />} />
        <Route exact path='walletDetails' element={<MPWalletDetails />} />
        <Route exact path='payout' element={<MPPayout />} />
        <Route exact path='payout/details/:payoutID' element={<MPPayoutDetails />} />
      </Route>
      <Route path='/access-denied' element={<AccessDenied />} />
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  )
}

const ProtectedRoute = ({ redirectPath = '/', children, roles }) => {
  const { isAuthenticated, isLoading, data: user } = useSelector((store) => store.auth)

  let location = useLocation()
  // if auth loading
  if (isLoading) {
    return (
      <div className='cs_container min_h_100 flex flex-col items-center justify-center text-center text-white'>
        <Spinner />
      </div>
    )
  }
  // if user is not authenticated
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace state={{ from: location }} />
  }
  // if user is authenticated but not contains provided roles
  if (roles && !containsAllRoles(roles, user?.roles)) {
    return <Navigate to={'/access-denied'} replace state={{ from: location }} />
  }
  return children ? children : <Outlet />
}

export default AppRouter
