import { card_type, currency } from 'utils/dropdownOptions'
import * as Yup from 'yup'

const assign = ['Assigned', 'UnAssigned']

const status = ['Active', 'InActive']

export const initialValues = {
  agent_id: '',
  card_nickname: '',
  bank_card_holder_name: '',
  bank_card_number: '',
  bank_name: '',
  card_type: '',
  card_wallet_type: '',
  // commission_profile_id:"",
  payin_transaction_limit: null,
  payin_daily_max_limit_currency: 'CNY',
  payin_daily_max_limit: null,
  payin_per_transaction_limit_currency: 'CNY',
  payin_per_transaction_min_limit: null,
  payin_per_transaction_max_limit: null,
  payin_card_fees: null,
  payout_daily_max_limit_currency: 'CNY',
  payout_daily_max_limit: null,
  payout_transaction_limit: null,
  payout_per_transaction_limit_currency: 'CNY',
  payout_per_transaction_min_limit: null,
  payout_per_transaction_max_limit: null,
  payout_card_fees: null,
  assignment_status: 'PENDING',
  status: 'ACTIVE',
  qr_code_url: null,
}

export const cardSchema = Yup.object().shape({
  agent_id: Yup.string().required('Required'),
  card_nickname: Yup.string('Invalid').min(2, 'Too Short!').required('Required'),
  bank_card_holder_name: Yup.string('Invalid').min(2, 'Too Short!').required('Required'),
  bank_card_number: Yup.string('Invalid').required('Required'),
  bank_name: Yup.string('Invalid').min(2, 'Too Short!').required('Required'),
  card_type: Yup.string('Invalid')
    .oneOf(card_type)
    .required('Required')
    .typeError('Select card type'),
  payin_transaction_limit: Yup.number().when('card_type', {
    is: (val) => ['DP', 'DP_WD'].includes(val),
    then: Yup.number().required('Required'),
    otherwise: Yup.number().nullable(),
  }),
  payin_daily_max_limit_currency: Yup.string().when('card_type', {
    is: (val) => ['DP', 'DP_WD'].includes(val),
    then: Yup.string().oneOf(currency).required('Required'),
    otherwise: Yup.string().nullable(),
  }),
  payin_daily_max_limit: Yup.number().when('card_type', {
    is: (val) => ['DP', 'DP_WD'].includes(val),
    then: Yup.number().nullable().required('Required'),
    otherwise: Yup.number().nullable(),
  }),
  payin_per_transaction_limit_currency: Yup.string().when('card_type', {
    is: (val) => ['DP', 'DP_WD'].includes(val),
    then: Yup.string().oneOf(currency).required('Required'),
    otherwise: Yup.string().nullable(),
  }),
  payin_per_transaction_min_limit: Yup.number().when('card_type', {
    is: (val) => ['DP', 'DP_WD'].includes(val),
    then: Yup.number().nullable().required('Required'),
    otherwise: Yup.number().nullable(),
  }),
  payin_per_transaction_max_limit: Yup.number().when('card_type', {
    is: (val) => ['DP', 'DP_WD'].includes(val),
    then: Yup.number()
      .nullable()
      .required('Required')
      .test('max-limit', 'Cannot larger than daily max limit', function (value) {
        const { payin_daily_max_limit } = this.parent
        return value <= payin_daily_max_limit
      })
      .required('Required'),
    otherwise: Yup.number().nullable(),
  }),
  payin_card_fees: Yup.number().when('card_type', {
    is: (val) => ['DP', 'DP_WD'].includes(val),
    then: Yup.number().nullable().required('Required'),
    otherwise: Yup.number().nullable(),
  }),

  payout_transaction_limit: Yup.number().when('card_type', {
    is: (val) => ['WD', 'DP_WD'].includes(val),
    then: Yup.number().required('Required'),
    otherwise: Yup.number().nullable(),
  }),
  payout_daily_max_limit_currency: Yup.string().when('card_type', {
    is: (val) => ['WD', 'DP_WD'].includes(val),
    then: Yup.string().oneOf(currency).required('Required'),
    otherwise: Yup.string().nullable(),
  }),
  payout_daily_max_limit: Yup.number().when('card_type', {
    is: (val) => ['WD', 'DP_WD'].includes(val),
    then: Yup.number().nullable().required('Required'),
    otherwise: Yup.number().nullable(),
  }),
  payout_per_transaction_limit_currency: Yup.string().when('card_type', {
    is: (val) => ['WD', 'DP_WD'].includes(val),
    then: Yup.string().oneOf(currency).required('Required'),
    otherwise: Yup.string().nullable(),
  }),
  payout_per_transaction_min_limit: Yup.number().when('card_type', {
    is: (val) => ['WD', 'DP_WD'].includes(val),
    then: Yup.number().nullable().required('Required'),
    otherwise: Yup.number().nullable(),
  }),
  payout_per_transaction_max_limit: Yup.number().when('card_type', {
    is: (val) => ['WD', 'DP_WD'].includes(val),
    then: Yup.number()
      .nullable()
      .required('Required')
      .test('max-limit', 'Cannot larger than daily max limit', function (value) {
        const { payout_daily_max_limit } = this.parent
        return value <= payout_daily_max_limit
      }),
    otherwise: Yup.number().nullable(),
  }),
  payout_card_fees: Yup.number().when('card_type', {
    is: (val) => ['WD', 'DP_WD'].includes(val),
    then: Yup.number().nullable().required('Required'),
    otherwise: Yup.number().nullable(),
  }),

  assignment_status: Yup.string(),
  status: Yup.string(),
})
