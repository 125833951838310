import React, { useEffect, useState } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { ErrorMessage, Field, Form, Formik } from 'formik'
import { changeUserPassword } from 'api/api'

import { PasswordInitialValues, PasswordValidationSchema } from './User/Config/passwordSchema'

import Button from 'utils/Button'
import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'

const ChangePasswordPage = () => {
  const { data: authData } = useSelector((store) => store.auth)

  // function for handling password changed
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmNewPassword: '',
  })

  // function for hanlding submiting user password change form
  const handlePasswordSubmit = (values, { setSubmitting, resetForm }) => {
    changeUserPassword({ password: values.password }, authData.userId)
      .then(() => {
        setSubmitting(false)
        resetForm()
        toast.success('Password changes successfully')
      })
      .catch((err) => {
        toast.error(err.response.data)
      })
  }
  // notes for chaning passwords
  const requirements = [
    'Password should contain uppercase and lowercase letters.',
    'Password should contain letters and numbers.',
    'Password should contain special characters.',
    'Minimum length of the password (the default value is 8).',
  ]

  return (
    <>
      <div>
        {/* BreadCrumbs */}
        <BreadCrumbs
          navList={[
            { title: 'Home', path: '/dashboard/agents' },
            { title: 'Change Password', path: '' },
          ]}
        />

        {/* Reset your passwords */}
        <Formik
          initialValues={PasswordInitialValues}
          validationSchema={PasswordValidationSchema}
          onSubmit={handlePasswordSubmit}
        >
          <Form>
            <div className='px-[60px] py-[21px] border-[1px] rounded-[5px] border-solid border-[#C4C4C4]'>
              <div className='flex items-center max-[1282px]:flex-wrap gap-x-[20px] gap-y-[30px] justify-between '>
                <div>
                  <h1 className='font-semibold text-[22px] text-[#262626]'>Reset Password</h1>
                  <div className='flex flex-col gap-6  pt-[52px]'>
                    {Object.keys(formData).map((key) => (
                      <div className='flex flex-col gap-1' key={key}>
                        <label htmlFor={key}>
                          {key === 'newPassword' ? 'New Password' : 'Confirm New Password'}
                        </label>
                        <Field
                          className='border-[#C4C4C4] border-solid border-[1px] h-[40px] max-w-[360px] px-[10px] rounded-[4px]'
                          type='password'
                          id={`${key === 'newPassword' ? 'password' : 'confirmPassword'}`}
                          name={`${key === 'newPassword' ? 'password' : 'confirmPassword'}`}
                          // value={formData[key]}
                          placeholder={`${
                            key === 'newPassword' ? 'New Password' : 'Confirm New Password'
                          }`}
                          // onChange={handleChange}
                        />
                        <ErrorMessage
                          name={`${key === 'newPassword' ? 'password' : 'confirmPassword'}`}
                          component='div'
                          className='error'
                        />
                      </div>
                    ))}
                  </div>
                </div>
                {/* notes for changing password */}
                <div>
                  <h2 className='font-medium'>Notes to setup ypur password:</h2>
                  <ol>
                    {requirements.map((requirement, index) => (
                      <li key={index}>{`${index + 1}. ${requirement}`}</li>
                    ))}
                  </ol>
                </div>
              </div>
              {/* buttoons */}
              <div className='md:my-3 flex justify-center items-center my-2'>
                <NavLink to={'/dashboard/setting/users/'}>
                  <Button classes='mx-1' type='button'>
                    Cancel
                  </Button>
                </NavLink>
                <Button classes='text-white ml-1 bg-purple-500 border-none rounded' type='submit'>
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  )
}

export default ChangePasswordPage
