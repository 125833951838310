import { NavLink } from 'react-router-dom'
import './dashboard-components.css'

const NavBar = ({ path, icon, title, onClick }) => {
  return (
    <NavLink
      to={path}
      role='button'
      tabIndex='0'
      className={(isActive) => `nav-link ${isActive.isActive ? 'nav-link-active' : ''}`}
      onClick={() => onClick && onClick()}
    >
      <span className='nav-icon'>{icon}</span>
      <p className='nav-title'>{title}</p>
    </NavLink>
  )
}

export { NavBar }
