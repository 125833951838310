import TabTitle from 'pages/Dashboard/components/TabTitle'
import React, { useEffect, useState } from 'react'
import StatusBtn from 'utils/statusBtn'
import cardIcon from 'assets/images/icons/cardIcon.png'
import moment from 'moment'
import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import CardForm from '../AddNewCard/CardForm'
import { Link, useParams } from 'react-router-dom'
import { getCardDetails } from 'api/api'
import { initialValues } from '../config/CardSchema'
import { toast } from 'react-toastify'
import { formatDateTime } from 'utils/formatDate'

const CardDetails = () => {
  const { id } = useParams()
  const [profileDetails, setprofileDetails] = useState(initialValues)

  useEffect(() => {
    const getDetails = () => {
      if (!id) return
      //  get profile data
      getCardDetails(id)
        .then((res) => {
          setprofileDetails(res.data)
        })
        .catch((err) => {
          console.log(err)
        })
    }
    getDetails()

    return () => {
      getDetails()
    }
  }, [id])
  return (
    <div>
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '/dashboard/all-cards' },
          { title: 'Card details', path: '' },
        ]}
      />
      {/* Page title */}
      <div className='flex flex-wrap  items-start'>
        <div className='flex items-center gap-6 '>
          <TabTitle title={profileDetails.card_nickname} icon={<img src={cardIcon} alt='' />} />
          <div className='flex flex-col md:flex-row items-center'>
            <StatusBtn
              text={profileDetails?.status === 'INACTIVE' ? 'InActive' : 'Active'}
              type={profileDetails?.status === 'INACTIVE' ? 'InActive' : 'Active'}
              indicator
            />
            <div className='text-sm font-medium text-gray-400'>
              <span className='px-2'>ID:# {profileDetails?.id}</span>
            </div>
          </div>
        </div>

        <div className='ml-auto flex flex-wrap items-end my-4'>
          <Link
            to={
              profileDetails?.status === 'INACTIVE' ||
              ['WD'].indexOf(profileDetails.card_type) === -1
                ? '#'
                : 'top-up-card'
            }
            className={`mb-0 md:mr-3 mr-2  ${
              profileDetails?.status === 'INACTIVE' ||
              ['WD'].indexOf(profileDetails.card_type) === -1
                ? 'bg-gray-300 text-gray-500'
                : 'bg-purple-500 text-white'
            } rounded text-sm px-2 py-[6px]`}
            onClick={(e) => {
              if (
                profileDetails?.status === 'INACTIVE' ||
                ['WD'].indexOf(profileDetails.card_type) === -1
              ) {
                e.preventDefault()
                //Show another toast message depending on the impediment
                if (profileDetails?.status === 'INACTIVE') {
                  toast.error('Please activate card')
                }
                if (['WD'].indexOf(profileDetails.card_type) === -1) {
                  toast.error('Invalid Card Type. You can only top up WD card.')
                }
              }
            }}
          >
            Top up
          </Link>
          <Link
            to={profileDetails?.status === 'INACTIVE' ? '#' : 'debit'}
            className={`mb-0 md:mr-3 mr-2  ${
              profileDetails?.status === 'INACTIVE'
                ? 'bg-gray-300 text-gray-500'
                : 'bg-purple-500 text-white'
            } rounded text-sm px-2 py-[6px]`}
            onClick={(e) => {
              if (profileDetails?.status === 'INACTIVE') {
                e.preventDefault()
                toast.error('Please activate card')
              }
            }}
          >
            Debit
          </Link>
          <Link
            to={profileDetails?.status === 'INACTIVE' ? '#' : 'card-assignment'}
            className={`mb-0 md:mr-3 mr-2  ${
              profileDetails?.status === 'INACTIVE'
                ? 'bg-gray-300 text-gray-500'
                : 'bg-purple-500 text-white'
            } rounded text-sm px-2 py-[6px]`}
            onClick={(e) => {
              if (profileDetails?.status === 'INACTIVE') {
                e.preventDefault()
                toast.error('Please activate card')
              }
            }}
          >
            Assignment
          </Link>
          {/* <Button classes="mb-0 md:mr-3 mr-2 py-1 bg-gray-300">
            {profileDetails?.is_active === "Active" ? null : <img className="mr-1" src={blockedIcon} alt="" />}
            {profileDetails?.is_active === "Active"?"Active":"InActive"}
          </Button>
         */}

          <div className='my-4'>
            <div className='lg:mr-2 text-gray-500 flex items-center justify-end gap-4'>
              <div className=''>
                <span className='text-sm '> Created at</span>
              </div>
              <div className='min-w-[130px] text-end'>
                <span className='text-sm'>
                  {profileDetails?.created_at
                    ? formatDateTime(profileDetails.created_at)
                    : 'Not available'}
                </span>
              </div>
            </div>
            <div className='lg:mr-2 text-gray-500 flex items-center justify-end gap-4'>
              <div className=''>
                <span className='text-sm'> Last modified</span>
              </div>
              <div className='min-w-[130px] text-end'>
                <span className='text-sm'>
                  {profileDetails?.last_updated
                    ? formatDateTime(profileDetails.last_updated)
                    : 'Not available'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Agent profile */}
      <CardForm type='edit' id={profileDetails.id} profileDetails={profileDetails} />
    </div>
  )
}

export default CardDetails
