import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { doUploadPayoutManyFile } from 'api/RESTful/payout'

import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import TabTitle from 'pages/Dashboard/components/TabTitle'

import editIcon from 'assets/images/icons/editIcon.png'
import CheckData from './CheckData'

const CreateManyPayout = () => {
  const [payoutManyRawData, setPayoutManyRawData] = useState([])
  const handleFileRead = async (event) => {
    const file = event.target.files[0]
    if (file) {
      const formData = new FormData()
      formData.append('file', file)

      try {
        const res = await doUploadPayoutManyFile(formData)
        setPayoutManyRawData(res.data)
      } catch (error) {
        toast.error(error.response.data.message)
      }
    }
  }

  return (
    <div className='min-h-[100vh] flex flex-col h-full mb-8'>
      {/* page breadcrumbs */}
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '' },
          { title: 'Create Payout', path: '' },
        ]}
      />
      {/* Page title */}
      <div className='cs_row items-center'>
        <div className='mr-2'>
          <TabTitle title={'Create Payout (Many)'} icon={<img src={editIcon} width={30} />} />
        </div>
      </div>
      {/* Uplaod files */}
      <div className='border rounded mt-10'>
        <div className='md:px-3 px-2 py-1'>
          <h5 className='text-2xl font-semibold p-2'>Upload Files</h5>
          <div className='md:mt-2'>
            {/* 1---------------- */}
            {/* 1 */}
            <div className='flex flex-wrap my-1 ml-2 items-center'>
              <label className='capitalize' htmlFor='proofInput'>
                Upload proof
              </label>
              <input
                type='file'
                id='proofInput'
                onChange={handleFileRead}
                className='w-100 border focus:outline-none m-1 ml-3 p-[2px] rounded pl-3'
              />
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex flex-col ml-2'>
                <a
                  href='https://payout-csv.s3.ap-east-1.amazonaws.com/payout_create_many_template.csv'
                  className='text-[#5665F5] underline text-sm'
                  download
                >
                  Download Template
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -----------------------------Check data------------------------------------- */}
      {payoutManyRawData.length > 0 && (
        <div className='border rounded mt-10'>
          <div className='md:px-3 px-2 py-1'>
            <h5 className='text-2xl font-semibold p-2'>Check Data</h5>
            <div className='md:mt-2'>
              <CheckData payoutManyRawData={payoutManyRawData} />
            </div>
          </div>
        </div>
      )}

      {/* -------------------------- */}
    </div>
  )
}

export default CreateManyPayout
