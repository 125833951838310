import { capitalFirstLetter, lowerString } from './helpers'
import { IndicatorIcon } from './Icons'

// *********** Status **************
const StatusBtn = ({ text, type, muted, indicator }) => {
  let statusType = lowerString(type)
  text = capitalFirstLetter(text)

  //
  if (indicator) {
    return (
      <span style={{ fontWeight: '500' }} className='p-[2px] status_chip rounded flex items-center'>
        <span className='mr-[2px]'>
          <IndicatorIcon type={type} />
        </span>
        {text}
      </span>
    )
  }
  if (muted) {
    return (
      <span
        style={{ fontWeight: '500' }}
        className='bg_grey text-black1 p-[2px] status_chip rounded'
      >
        <span className='opacity-[0.85]'>{text}</span>
      </span>
    )
  }
  if (statusType === 'success' || statusType === 'successful' || statusType === 'active') {
    return (
      <span
        style={{ fontWeight: '500' }}
        className='success_bg4 bg_success text_success1p text-white p-[2px] status_chip rounded'
      >
        {text}
      </span>
    )
  } else if (
    statusType === 'pending' ||
    statusType === 'processing' ||
    statusType === 'warn' ||
    statusType === 'isactive' ||
    statusType === 'paused'
  ) {
    return (
      <span style={{ fontWeight: '500' }} className='bg_pending text_ p-[2px] status_chip rounded'>
        {' '}
        <span className='opacity-[0.85]'>{text}</span>{' '}
      </span>
    )
  } else if (statusType === 'action_required') {
    return (
      <span style={{ fontWeight: '500' }} className='bg_pending p-[2px] status_chip rounded'>
        {text}
      </span>
    )
  } else if (
    statusType === 'blocked' ||
    statusType === 'failed' ||
    statusType === 'paused' ||
    statusType === 'block'
  ) {
    return (
      <span style={{ fontWeight: '500' }} className='bg_block p-[2px] status_chip rounded'>
        <span className='opacity-[0.85] text_danger'>{text}</span>
      </span>
    )
  }
  return text
}

export default StatusBtn
