import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import React, { useEffect, useState } from 'react'
import TabTitle from 'pages/Dashboard/components/TabTitle'
import StatusBtn from 'utils/statusBtn'
import Button from 'utils/Button'
import { toast } from 'react-toastify'
import editIcon from 'assets/images/icons/editIcon.png'
import blockedIcon from 'assets/images/icons/blockedIcon.png'
import moment from 'moment'
import { NavLink, useParams } from 'react-router-dom'
import { changeUserPassword, createProfile, getUserdetails } from 'api/api'
import { initialValues } from '../Config/profileSchema'
import UserProfile from '../Components/UserProfile'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { PasswordInitialValues, PasswordValidationSchema } from '../Config/passwordSchema'

const UsersDetails = () => {
  const { id } = useParams()
  const [profileData, setprofileData] = useState([initialValues])

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let obj = values
    createProfile(obj)
      .then((res) => {
        resetForm()
        setSubmitting(false)
        toast.success('Profile has been created')
      })
      .catch((err) => {
        console.log(err)
        toast.error('Something Went Wrong')
      })
  }
  // --------------------------
  useEffect(() => {
    const getDetails = () => {
      if (!id) return

      // get agent data
      getUserdetails(id)
        .then((data) => {
          setprofileData({
            // ...initialValues,
            ...data,
          })
        })
        .catch((err) => {
          console.log(err)
        })
    }
    getDetails()

    return () => {
      getDetails()
    }
  }, [id])
  // function for handling password changed
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmNewPassword: '',
  })

  // function for hanlding submiting user password change form
  const handlePasswordSubmit = (values, { setSubmitting, resetForm }) => {
    changeUserPassword({ password: values.password }, id)
      .then(() => {
        setSubmitting(false)
        resetForm()
        toast.success('Password changes successfully')
      })
      .catch((err) => {
        toast.error(err.response.data)
      })
  }
  // notes for chaning passwords
  const requirements = [
    'Password should contain uppercase and lowercase letters.',
    'Password should contain letters and numbers.',
    'Password should contain special characters.',
    'Minimum length of the password (the default value is 8).',
  ]

  return (
    <>
      <div>
        {/* BreadCrumbs */}
        <BreadCrumbs
          navList={[
            { title: 'Home', path: '/dashboard/agents' },
            { title: 'User details', path: '' },
          ]}
        />
        {/* page top info  */}
        <div className='flex flex-wrap items-start'>
          <div className='flex items-center'>
            <TabTitle
              title={profileData?.first_name + ' ' + profileData?.last_name}
              icon={<img src={editIcon} alt='' />}
            />
            <StatusBtn
              text={profileData?.is_active ? 'Active' : 'InActive'}
              type={profileData?.is_active ? 'Active' : 'Inactive'}
              indicator
            />
            <div className='text-sm font-medium text-gray-400'>
              <span className='px-2'>ID:# {profileData.id}</span>
            </div>
          </div>
          <div className='ml-auto flex flex-wrap items-end'>
            <Button classes='mb-0 md:mr-3 mr-2 py-1 bg-gray-300'>
              {profileData?.is_active ? null : <img className='mr-1' src={blockedIcon} alt='' />}
              {profileData?.is_active ? 'Active' : 'InActive'}
            </Button>
            <div>
              <div className='lg:mr-2 text-gray-500 flex items-center justify-end'>
                <div className=''>
                  <span className='text-sm '>Created at</span>
                </div>
                <div className='min-w-[130px] text-end'>
                  <span className='text-sm'>
                    {profileData?.created_at
                      ? moment(profileData.created_at).format('DD/MM/YYYY HH:MM')
                      : 'Not available'}
                  </span>
                </div>
              </div>
              <div className='lg:mr-2 text-gray-500 flex items-center justify-end'>
                <div className=''>
                  <span className='text-sm'> Last modified</span>
                </div>
                <div className='min-w-[130px] text-end'>
                  <span className='text-sm'>
                    {profileData?.last_updated
                      ? moment(profileData.last_updated).format('DD/MM/YYYY HH:MM')
                      : 'Not available'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Form for editing existing user */}
        <UserProfile id={id} profileData={profileData} />

        {/* Reset your passwords */}
        <Formik
          initialValues={PasswordInitialValues}
          validationSchema={PasswordValidationSchema}
          onSubmit={handlePasswordSubmit}
        >
          <Form>
            <div className='px-[60px] py-[21px] border-[1px] rounded-[5px] border-solid border-[#C4C4C4]'>
              <div className='flex items-center max-[1282px]:flex-wrap gap-x-[20px] gap-y-[30px] justify-between '>
                <div>
                  <h1 className='font-semibold text-[22px] text-[#262626]'>Reset Password</h1>
                  <p className='text-[#797979] font-normal text-[14px] '>
                    Reset the password for your user as a PICOTOP Account
                  </p>
                  <div className='flex flex-col gap-6  pt-[52px]'>
                    {Object.keys(formData).map((key) => (
                      <div className='flex flex-col gap-1' key={key}>
                        <label htmlFor={key}>
                          {key === 'newPassword' ? 'New Password' : 'Confirm New Password'}
                        </label>
                        <Field
                          className='border-[#C4C4C4] border-solid border-[1px] h-[40px] max-w-[360px] px-[10px] rounded-[4px]'
                          type='password'
                          id={`${key === 'newPassword' ? 'password' : 'confirmPassword'}`}
                          name={`${key === 'newPassword' ? 'password' : 'confirmPassword'}`}
                          // value={formData[key]}
                          placeholder={`${
                            key === 'newPassword' ? 'New Password' : 'Confirm New Password'
                          }`}
                          // onChange={handleChange}
                        />
                        <ErrorMessage
                          name={`${key === 'newPassword' ? 'password' : 'confirmPassword'}`}
                          component='div'
                          className='error'
                        />
                      </div>
                    ))}
                  </div>
                </div>
                {/* notes for changing password */}
                <div>
                  <h2 className='font-medium'>Notes to setup ypur passord:</h2>
                  <ol>
                    {requirements.map((requirement, index) => (
                      <li key={index}>{`${index + 1}. ${requirement}`}</li>
                    ))}
                  </ol>
                </div>
              </div>
              {/* buttoons */}
              <div className='md:my-3 flex justify-center items-center my-2'>
                <NavLink to={'/dashboard/setting/users/'}>
                  <Button classes='mx-1' type='button'>
                    Cancel
                  </Button>
                </NavLink>
                <Button classes='text-white ml-1 bg-purple-500 border-none rounded' type='submit'>
                  Save
                </Button>
              </div>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  )
}

export default UsersDetails
