import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllPayments } from 'api/api'

export const getPaymentData = createAsyncThunk(
  'payment/getPaymentData',
  async ({ query, itemsPerPage, skip }) => {
    return await getAllPayments(query || '', itemsPerPage || '', skip || '')
  },
)
const initialState = {
  data: [],
  isLoading: true,
}

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setAllPayment: (state, action) => {
      state.data = action.payload.data
      state.isLoading = action.payload.isLoading
    },
  },
  extraReducers: {
    [getPaymentData.pending]: (state) => {
      state.isLoading = true
    },
    [getPaymentData.fulfilled]: (state, action) => {
      state.data = action.payload || []
      state.isLoading = false
    },
    [getPaymentData.rejected]: (state) => {
      state.data = []
      state.isLoading = false
    },
  },
})
export const { setAllPayment } = paymentSlice.actions

export default paymentSlice.reducer
