import { useState } from 'react'
import acceptIconImg from 'assets/images/icons/acceptIcon.png'
import Button from 'utils/Button'
import CsModal from 'utils/Modal'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { merchantSchema, initialValues } from '../config/merchantSchema'
import { toast } from 'react-toastify'
import { getMerchantData } from 'store/reducers/merchantReducerSlice'
import { useDispatch } from 'react-redux'
import { status } from 'utils/dropdownOptions'
import { countryList } from 'utils/countryList'
import { getPhoneCode, lowerString, showPhone } from 'utils/helpers'
import { editMerchant } from 'api/api'

const MerchantProfile = ({ id, profileData, setProfileData }) => {
  const dispatch = useDispatch()
  // modal state
  const [modalOpen, setModal] = useState({ open: false, id: '' })

  // handle profile form submit/update details
  const handleSubmit = (values, { setSubmitting }) => {
    let obj = values
    delete obj.payin
    delete obj.payout
    obj.postal_code = parseInt(obj.postal_code)
    obj.user = {
      ...obj.user,
      is_active: lowerString(obj?.user?.is_active) === 'active',
    }
    obj.is_active = lowerString(obj?.is_active) === 'active'
    obj.postal_code = obj.postal_code.toString()
    editMerchant(obj, id)
      .then(() => {
        dispatch(getMerchantData())
        setSubmitting(false)
        setModal({ open: true, id: id })
        let modified = {
          ...values,
          is_active: values?.is_active === true ? 'Active' : 'Inactive',
          // is_active: values?.is_active === "Inactive" ? "Inactive" : "Active",
          last_updated: new Date().toISOString(),
        }
        setProfileData(modified)
      })
      .catch((err) => {
        setSubmitting(false)
        console.log('Error while updating the profile details of a merchant =>', err)
        toast.error(err.message || 'Something went wrong!')
      })
  }

  // handle close modal
  const handleCloseModal = () => {
    setModal({ open: false, id: '' })
  }
  return (
    <>
      <Formik
        initialValues={profileData || initialValues}
        validationSchema={merchantSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, handleChange, values, errors, touched }) => (
          <Form autoComplete='off'>
            <MerchantProfileFormWrapper
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              type='edit'
            />
          </Form>
        )}
      </Formik>
      {/* *************************************** */}
      {/* ******* Modal  */}
      <CsModal open={modalOpen.open} onClose={handleCloseModal}>
        <div className='bg-white text-center  cs_modal rounded md:px-3 md:py-4 px-2 py-3'>
          <div className='flex content-center items-center my-2'>
            <span className='mr-2 flex items-center'>
              <img src={acceptIconImg} alt='' />
            </span>
            <p className='ml-1'>Merchant profile has been updated successfully</p>
          </div>
          <div className='my-2 flex flex-wrap justify-center items-center'>
            <button className='cs_btn mx-2' onClick={handleCloseModal}>
              Okay
            </button>
          </div>
        </div>
      </CsModal>
    </>
  )
}

//
export const MerchantProfileFormWrapper = ({
  isSubmitting,
  handleChange,
  values,
  errors,
  touched,
  type,
}) => {
  const handlePhoneCodeChange = (e) => {
    const selectedPhoneCode = e.target.value
    const updatedPhone = showPhone(selectedPhoneCode, values.phone)
    handleChange(e)
    handleChange({
      target: {
        name: 'phone',
        value: updatedPhone,
      },
    })
  }

  // function for stoppping negative value typed in the input
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }
  return (
    <div className='border rounded mb-3 mt-2'>
      <div className={`md:px-3 px-2 py-1 ${type === 'add' ? 'md:mb-3 mb-2' : ''}`}>
        <h5 className='text-2xl font-bold p-2'>Profile</h5>
        <div className='md:mt-2'>
          {/* 1 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='name'>
                Account Name<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='name'
                  id='name'
                  placeholder='Account Name'
                  className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='name'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 2 */}
          <div className='flex flex-wrap md:my-0 my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='address1'>
                Business address<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex item-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='address1'
                  id='address1'
                  onKeyDown={preventMinus}
                  min='0'
                  placeholder='Address 1'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='address1'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 3 */}
          <div className=' flex flex-wrap md:my-0 my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'></div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='address2'
                  id='address2'
                  placeholder='Address 2'
                  onKeyDown={preventMinus}
                  min='0'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <p className='text-gray-400 w-full text-start md:ml-auto md:pl-2'></p>
            </div>
          </div>
          {/* 4 */}
          <div className=' flex flex-wrap md:my-0 my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'></div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='city'
                  id='city'
                  placeholder='City'
                  onKeyDown={preventMinus}
                  min='0'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='city'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 5 */}
          <div className='flex flex-wrap md:my-0 my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'></div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='country'
                  id='country'
                  placeholder='Country'
                  onKeyDown={preventMinus}
                  min='0'
                  className=' w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='country'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 6 */}
          <div className='flex flex-wrap md:my-0 my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'></div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='postal_code'
                  id='postal_code'
                  onKeyDown={preventMinus}
                  min='0'
                  placeholder='ZIP/ Postal Code'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='postal_code'
                component='p'
                className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 7 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='source'>Sources</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <select
                  name='sources'
                  id='sources'
                  className='w-full cursor-pointer select-none rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  onChange={handleChange}
                  value={values.sources}
                >
                  <option value='' disabled>
                    Select source
                  </option>
                  <option value='EMPLOYEE_REFERRAL'>Employee Referral</option>
                  <option value='WEBSITE'>Website</option>
                </select>
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <p className='text-gray-400 w-full text-start md:ml-auto md:pl-2'>
                {touched.sources && errors.sources && errors.sources}
              </p>
            </div>
          </div>
          {/* 8 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='email'>
                Email<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='email'
                  name='email'
                  id='email'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='email'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 9 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='phone'>
                Phone<b className='text-red-600'>*</b>
              </label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <select
                  name='phoneCode'
                  id='phoneCode'
                  className='cursor-pointer select-none me_2   rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  onChange={handlePhoneCodeChange}
                  value={values?.phoneCode || getPhoneCode(values.phone)}
                >
                  {countryList.map((v, i) => (
                    <option value={v.dial_code} key={i}>
                      {v.code + ' ' + v.dial_code}
                    </option>
                  ))}
                </select>
                <Field
                  type='phone'
                  name='phone'
                  id='phone'
                  placeholder='Phone'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  onChange={handleChange}
                  value={showPhone(values.phoneCode, values.phone)}
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='phone'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 10 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='business_registration'>Business Registration</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='business_registration'
                  id='business_registration'
                  placeholder=''
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='business_registration'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 11 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='is_active'>Status</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <select
                  name='is_active'
                  id='is_active'
                  className='w-full cursor-pointer select-none    rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  onChange={handleChange}
                  value={values.is_active}
                >
                  <option value='' disabled>
                    Select status
                  </option>
                  {status?.map((v, i) => (
                    <option value={v} key={i}>
                      {v}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <p className='text-gray-400 w-full text-start md:ml-auto md:pl-2'>
                {touched.source && errors.source && errors.source}
              </p>
            </div>
          </div>
        </div>
        {type === 'add' ? null : (
          <div className='md:mt-3 flex justify-end items-center my-2'>
            <Button disabled={isSubmitting} classes='cs_btn  my-1' type='button'>
              Cancel
            </Button>
            <Button
              disabled={isSubmitting}
              classes='bg-purple-500 text-white ml-1  bg-purple-500 border-none'
              type='submit'
            >
              {isSubmitting ? 'Saving...' : 'Save'}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default MerchantProfile
