import axios from 'axios'

const getCheckoutDetails = async (checkoutId, token) => {
  const res = await axios.get(`/checkouts/${checkoutId}`, {
    headers: {
      Authorization: 'Bearer ' + token,
    },
  })
  return res.data
}
export { getCheckoutDetails }
