import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getAllPayments, getOrderDetails } from 'api/api' // FIXME: use useHook
import { doConfirmOrder } from 'api/RESTful/order'

import confirmBtn from 'assets/images/icons/check.png'
import refundBtn from 'assets/images/icons/refundBtn.png'

import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import Table from 'pages/Dashboard/components/Table'

import Button from 'utils/Button'
import { formatDateTime } from 'utils/formatDate'
import { formatAmount } from 'utils/formatAmount'

// FIXME: move this to json
const paymentTableColumns = [
  { name: 'Payment ID' },
  { name: 'Type' },
  { name: 'Currency' },
  { name: 'Amount' },
  { name: 'Status' },
  { name: 'Last Updated' },
]

const OrderDetails = () => {
  const { orderID } = useParams()
  const [orderDetails, setOrderDetails] = useState([])

  const handleConfirmOrder = () => {
    doConfirmOrder(orderID)
      .then((response) => {
        toast.success(response.data.message, {
          onClose: () => window.location.reload(),
        })
      })
      .catch((err) => {
        toast.error(err.message)
      })
  }

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await getOrderDetails(orderID)
        setOrderDetails(response)
      } catch (error) {
        toast.error(error)
      }
    }
    fetchOrderDetails()
    getAllPayments()
      .then((response) => {})
      .catch((err) => {
        console.log(err)
      })
  }, [orderID])

  return (
    <div>
      {/* BreadCrumbs */}
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '/dashboard' },
          { title: 'Admin', path: '/dashboard/orders' },
          { title: 'Order Details' },
        ]}
      />
      {/* Top Details */}
      <div>
        <h5 className='text-gray-400 font-normal text-2xl'>ORDER# {orderDetails?.id}</h5>
        <div className='cs_row'>
          <div className='flex mr-2 flex-wrap items-center'>
            <h4 className='font-normal mr-2 text-3xl'>
              <span className='text-gray-400'>{orderDetails?.currency}</span>
              {` `}
              {formatAmount(orderDetails?.order_amount)}
            </h4>
            <p
              className='bg-[#D9D9D9] px-2 py-1 rounded text-gray-400'
              style={{ fontSize: '13px' }}
            >
              {orderDetails?.status}
            </p>
          </div>
          <div className='ml-auto flex items-center'>
            {orderDetails?.status === 'ACTION_REQUIRED' && (
              <>
                <Button onClick={() => handleConfirmOrder()} classes='mr-2 px-2'>
                  <span className='mr-2 flex items-center'>
                    <img src={confirmBtn} alt='' />
                  </span>
                  Confirm
                </Button>
                <Button classes='px-2'>
                  <span className='mr-2 flex items-center'>
                    <img src={refundBtn} alt='' />
                  </span>
                  Refund
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      {/* Top Table */}
      {/* *********************** */}
      <div className='my-3 cs_row py-0 border-t-2 border-gray-400'>
        <div className='md:pr-6 px-0  border-r-2 p-4'>
          <span className='text-gray-400'>Last Updated</span>
          <p className='font-medium'>{formatDateTime(orderDetails.last_updated)}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Merchant</span>
          <p className='text-[#499CD6] font-medium'>{orderDetails?.merchant_id}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Customer</span>
          <p className='text-[#499CD6] font-medium'>{orderDetails?.customer_name}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Type</span>
          <p className='font-medium'>{orderDetails?.type}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Agent</span>
          <p className='text-[#499CD6] font-medium'>{orderDetails?.agent_id}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Card</span>
          <p className='text-[#499CD6] font-medium'>
            <Link
              to={`/dashboard/all-cards/details/${orderDetails?.card_id}`}
              className='text-[#499CD6] font-medium'
            >
              {orderDetails?.card_id}
            </Link>
          </p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Transfer note</span>
          <p className='font-medium'>{orderDetails?.transfer_note}</p>
        </div>
      </div>

      {/* Timeline FIXME: Change back the div to Table */}
      <div className='my-3 py-3 '>
        <div className='flex items-center justify-between'>
          <h5 className='text-2xl font-semibold'>Timeline</h5>
        </div>
        <div className='mb-1 cs_row py-3 border-t-2 border-b-2'>
          <div className='flex w-full'>
            <div className='md:pr-12'>
              <span className='text-gray-400'>Time</span>
            </div>
            <div className='mdpl-4 px-24'>
              <span className='text-gray-400'>Event</span>
            </div>
          </div>
          {orderDetails?.timeline && orderDetails.timeline.length > 0 ? (
            orderDetails.timeline
              .sort((a, b) => b.id - a.id)
              .map((item) => (
                <div key={item.id} className='flex w-full'>
                  <div className='md:pr-12'>
                    <p className='font-normal md:mt-2 mt-1'>{formatDateTime(item.time)}</p>
                  </div>
                  <div className='mdpl-4 px-2'>
                    <p className='font-normal md:mt-2 mt-1'>{item.event}</p>
                  </div>
                </div>
              ))
          ) : (
            <div className='flex w-full'>
              <div className='md:pr-12'>
                <p className='font-normal md:mt-2 mt-1'>-</p>
              </div>
              <div className='mdpl-4 px-2'>
                <p className='font-normal md:mt-2 mt-1'>No events yet</p>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Payment Details FIXME: column should be responsive, style for the table */}
      <div className='my-3'>
        <h5 className='text-2xl font-semibold'>Payment Details</h5>
        <Table columns={paymentTableColumns}>
          {orderDetails.payment ? (
            <tr>
              <td>
                <Link
                  to={`/dashboard/payment/details/${orderDetails.payment.id}`}
                  className='text-start text-[#499CD6]'
                >
                  {orderDetails.payment.id}
                </Link>
              </td>

              <td>{orderDetails.payment.type}</td>
              <td>{orderDetails.payment.currency}</td>
              <td>{orderDetails.payment.order_amount}</td>
              <td>{orderDetails.payment.status}</td>
              <td>{formatDateTime(orderDetails.payment.last_updated)}</td>
            </tr>
          ) : (
            <tr>
              <td colSpan={5}>No payment details available</td>
            </tr>
          )}
        </Table>
      </div>
    </div>
  )
}

export default OrderDetails
