import { ErrorMessage, Field, Form, Formik } from 'formik'
import CurrencyInput from 'react-currency-input-field'
import { Link } from 'react-router-dom'
import Button from 'utils/Button'
import './index.css'
import { initialValues, merchantPySettingSchema } from '../config/merchantPaymentSettingSchema'
import { toast } from 'react-toastify'
import { editMerchant, signIn, postNewPaymentToken } from 'api/api'

import { getMerchantDetails, updateMerchantDetails } from 'api/RESTful/merchant'

// enable generate token
import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

const ApiInformation = ({ paymentSettingData, id, setProfileData }) => {
  // update merchant payment settings
  const handleSubmit = (values, { setSubmitting }) => {
    editMerchant(
      {
        transaction_currency: values?.transaction_currency,
        payin: parseInt(values?.payin),
        payin_currency: values?.payin_currency,
        payout: parseInt(values?.payout),
        payout_currency: values?.payout_currency,
      },
      id,
    )
      .then((res) => {
        toast.success(res?.statusText || 'Updated successfully')
        setSubmitting(false)
        let prev = { ...paymentSettingData }
        if (setProfileData)
          setProfileData({
            ...prev,
            last_updated: new Date().toISOString(),
          })
      })
      .catch((err) => {
        setSubmitting(false)
        console.log('Error while updating the merchant payment settings => ', err)
        toast.error(err.message || 'Something went wrong!')
      })
  }

  return (
    <Formik
      initialValues={paymentSettingData || initialValues}
      validationSchema={merchantPySettingSchema}
      onSubmit={handleSubmit}
      enableReinitialize
    >
      {({ isSubmitting, handleChange, values }) => (
        <Form autoComplete='off'>
          <MerchantPaySettingWrapper
            isSubmitting={isSubmitting}
            handleChange={handleChange}
            values={values}
            type='edit'
            id={id}
          />
        </Form>
      )}
    </Formik>
  )
}

// for wrapper
export const MerchantPaySettingWrapper = ({ isSubmitting, handleChange, values, type, id }) => {
  const [accessToken, setAccessToken] = useState('')
  const [accessKeyID, setAccessKeyID] = useState('')
  const [callbackUrl, setCallbackUrl] = useState('')

  // Retrieve the merchant details and set the initial callback URL
  useEffect(() => {
    getMerchantDetails(id)
      .then((res) => {
        setCallbackUrl(res.callback_url)
      })
      .catch((err) => {
        console.error(err)
        toast.error('Error fetching merchant details')
      })
  }, [id])

  // Handle changes to the callback URL input
  const handleCallbackUrlChange = (e) => {
    setCallbackUrl(e.target.value)
  }

  // Save the new callback URL
  const handleSubmit = (e) => {
    e.preventDefault()
    updateMerchantDetails(id, { callback_url: callbackUrl })
      .then(() => {
        toast.success('Callback URL updated successfully')
      })
      .catch((err) => {
        console.error(err)
        toast.error('Error updating callback URL')
      })
  }

  const generateToken = () => {
    postNewPaymentToken(id)
      .then((res) => {
        setAccessToken(res.data.key_secret)
        setAccessKeyID(res.data.key_id)
        toast.success('Access Token has been generated')
      })
      .catch((err) => {
        console.log('Error generating new token:', err)
      })
  }
  const copyToClipboard = (string) => {
    navigator.clipboard.writeText(string).then(() => {
      toast.success('Copied to clipboard')
    })
  }
  const getPaddingStyle = (tokenValue) => {
    return tokenValue ? 'py-[3px]' : 'py-[15px]'
  }
  return (
    <div className='border mt-2 rounded'>
      <div className='md:px-3 px-2 py-1'>
        <h5 className='text-2xl font-bold p-2'>API Information</h5>
        {/* 1 input */}
        <div className='unselectable readOnlyInput md:mt-2'>
          <div className='api-info flex flex-wrap my_1'>
            <div className='form_label text-left pl-[10px] w-[150px] '>
              <label htmlFor='payin_currency'>Access Key Secret:</label>
            </div>
            <div className='flex api-info-inputs items-center flex-1'>
              {/* input */}
              <div className='flex w-full'>
                <div className='w-full ps_2'>
                  <div className=' flex items-center max-w-[680px]'>
                    <label
                      type='text'
                      name='token'
                      id='token'
                      className={`unselectable readOnlyInput w-full rounded px-[15px] ${getPaddingStyle(
                        accessToken,
                      )} max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500`}
                    >
                      {accessToken}
                    </label>
                  </div>
                </div>
              </div>
              {/* buttons */}
              <div className='min-w-[270px] flex gap-1'>
                <Button
                  width={true}
                  classes='bg-purple-500 text-white ml-1  border-none'
                  onClick={generateToken}
                >
                  New
                </Button>
                <Button
                  width={true}
                  classes='bg-purple-500 text-white ml-1  border-none'
                  onClick={() => copyToClipboard(accessToken)}
                >
                  copy
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className='md:mt-2'>
          <div className='api-info flex flex-wrap my_1'>
            <div className='form_label text-left pl-[10px] w-[150px] '>
              <label htmlFor='payin_currency'>Access Key ID:</label>
            </div>
            <div className='flex api-info-inputs items-center flex-1'>
              {/* input */}
              <div className='flex w-full'>
                <div className='w-full ps_2'>
                  <div className=' flex items-center max-w-[680px]'>
                    <label
                      type='text'
                      name='tokenid'
                      id='tokenid'
                      className={`unselectable readOnlyInput w-full rounded px-[15px] ${getPaddingStyle(
                        accessKeyID,
                      )} max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500`}
                    >
                      {accessKeyID}
                    </label>
                  </div>
                </div>
              </div>
              {/* buttons */}
              <div className='min-w-[270px] flex gap-1'>
                <Button
                  width={true}
                  classes='bg-purple-500 text-white ml-1  border-none'
                  onClick={() => copyToClipboard(accessKeyID)}
                >
                  copy
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className='md:mt-2'>
          <div className='api-info flex flex-wrap my_1'>
            <div className='form_label text-left pl-[10px] w-[150px] '>
              <label htmlFor='callback_url'>Callback URL:</label>
            </div>
            <div className='flex api-info-inputs items-center flex-1'>
              <div className='flex w-full'>
                <div className='w-full ps_2'>
                  <div className=' flex items-center max-w-[680px]'>
                    <Field
                      type='text'
                      name='callback_url'
                      value={callbackUrl}
                      onChange={handleCallbackUrlChange}
                      id='callback_url'
                      placeholder='Callback URL'
                      className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                    />
                  </div>
                </div>
              </div>
              {/* buttons */}
              <div className='min-w-[270px] flex gap-1'>
                <Button
                  width={true}
                  classes='bg-purple-500 text-white ml-1  border-none'
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>

        {/* {type === "add" ? null : (
          <div className="md:mt-3 flex justify-end items-center my-2">
            <Link
              to={isSubmitting ? "" : `/dashboard/merchant/details/${id}`}
              disabled={isSubmitting}
              className="cs_btn mx_1  px-8"
              type="button"
            >
              Cancel
            </Link>
            <Button
              disabled={isSubmitting}
              classes="bg-purple-500 text-white ml-1  border-none"
              type="submit"
            >
              {isSubmitting ? "Saving.." : "Save"}
            </Button>
          </div>
        )} */}
      </div>
    </div>
  )
}

export default ApiInformation
