import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getPaymentDetails } from 'api/RESTful/payment'

import refundBtn from 'assets/images/icons/refundBtn.png'
import freezeBtn from 'assets/images/icons/freezeBtn.png'
import plusIcon from 'assets/images/icons/plusIcon.png'
import recieved from 'assets/images/icons/inboxIcon.png'

import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import Table from 'pages/Dashboard/components/Table'

import Button from 'utils/Button'
import { CloseIcon } from 'utils/Icons'
import { formatDateTime } from 'utils/formatDate'
import { formatAmount } from 'utils/formatAmount'

// FIXME: move this to constat json data later
const ordersTableColumns = [
  { name: 'Order ID' },
  { name: 'Type' },
  { name: 'Currency' },
  { name: 'Amount' },
  { name: 'Status' },
  { name: 'Last Updated' },
]

const MPPaymentDetails = () => {
  const { paymentID } = useParams()
  const [paymentDetails, setPaymentDetails] = useState('')

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await getPaymentDetails(paymentID)
        setPaymentDetails(response)
      } catch (error) {
        toast.error(error)
      }
    }
    fetchPaymentDetails()
  }, [paymentID])

  return (
    <div>
      {/* BreadCrumbs */}
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '/dashboard' },
          { title: 'Admin', path: '/dashboard/payment' },
          { title: 'Payment Details', path: '' },
        ]}
      />
      {/* Top Details */}
      <div>
        <h5 className='text-gray-400 font-normal text-2xl'>PAYMENT# {paymentDetails?.id}</h5>
        <div className='cs_row'>
          <div className='flex mr-2 flex-wrap items-center'>
            <h4 className='font-normal mr-2 text-3xl'>
              <span className='text-gray-400'>{paymentDetails?.currency} </span>
              {` `}
              {formatAmount(paymentDetails?.order_amount)}
            </h4>
            <p
              className='bg-[#D9D9D9] px-2 py-1 rounded text-gray-400'
              style={{ fontSize: '13px' }}
            >
              {paymentDetails?.status}
            </p>
          </div>
        </div>
      </div>
      {/* Top Table */}
      {/* *********************** */}
      <div className='my-6 cs_row py-3 border-t-2 border-gray-400'>
        <div className='md:pr-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Last updated</span>
          <p className='font-medium'>{formatDateTime(paymentDetails.last_updated)}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Merchant</span>
          <p className='text-[#499CD6] font-medium'>{paymentDetails?.merchant_id}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Customer</span>
          <p className='text-[#499CD6] font-medium'>{paymentDetails?.customer_name}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Type</span>
          <p className='font-medium'>{paymentDetails?.type}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Agent</span>
          <p className='text-[#499CD6] font-medium'>{paymentDetails?.agent_id}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Card</span>
          <p className='text-[#499CD6] font-medium'>{paymentDetails?.card_id}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Transfer note</span>
          <p className='font-medium'>{paymentDetails?.transfer_note}</p>
        </div>
      </div>
      {/* TimeLine */}
      {/* Timeline FIXME: Change back the div to Table */}
      <div className='my-3 py-3 '>
        <div className='flex items-center justify-between'>
          <h5 className='text-2xl font-semibold'>Timeline</h5>
        </div>
        <div className='mb-1 cs_row py-3 border-t-2 border-b-2'>
          <div className='flex w-full'>
            <div className='md:pr-12'>
              <span className='text-gray-400'>Time</span>
            </div>
            <div className='mdpl-4 px-24'>
              <span className='text-gray-400'>Event</span>
            </div>
          </div>
          {paymentDetails?.timeline && paymentDetails.timeline.length > 0 ? (
            paymentDetails.timeline
              .sort((a, b) => b.id - a.id)
              .map((item) => (
                <div key={item.id} className='flex w-full'>
                  <div className='md:pr-12'>
                    <p className='font-normal md:mt-2 mt-1'>{formatDateTime(item.time)}</p>
                  </div>
                  <div className='mdpl-4 px-2'>
                    <p className='font-normal md:mt-2 mt-1'>{item.event}</p>
                  </div>
                </div>
              ))
          ) : (
            <div className='flex w-full'>
              <div className='md:pr-12'>
                <p className='font-normal md:mt-2 mt-1'>-</p>
              </div>
              <div className='mdpl-4 px-2'>
                <p className='font-normal md:mt-2 mt-1'>No events yet</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='mt-8'>
        <div className='flex items-center justify-between'></div>
      </div>
      <div className='my-3'>
        <h5 className='text-2xl font-semibold'>Order Details</h5>
        <Table columns={ordersTableColumns}>
          {paymentDetails.order && paymentDetails.order.length > 0 ? (
            paymentDetails.order.map((orderItem) => (
              <tr key={orderItem.id}>
                <td>
                  <Link
                    to={`/merchantPortal/orders/details/${orderItem.id}`}
                    className='text-start text-[#499CD6]'
                  >
                    {orderItem.id}
                  </Link>
                </td>
                <td>{orderItem.type}</td>
                <td>{orderItem.currency}</td>
                <td>{orderItem.order_amount}</td>
                <td>{orderItem.status}</td>
                <td>{formatDateTime(orderItem.last_updated)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>No order details available</td>
            </tr>
          )}
        </Table>
      </div>
    </div>
  )
}

export default MPPaymentDetails
