import React from 'react'

import editIcon from 'assets/images/icons/editIcon.png'

import TabTitle from 'pages/Dashboard/components/TabTitle'
import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import PayoutForm from './PayoutForm'

const CreatePayout = () => {
  return (
    <div>
      <div>
        {/* Page breadcrumbs */}
        <BreadCrumbs
          navList={[
            { title: 'Home', path: '/dashboard/all-cards' },
            { title: 'Create Payout', path: '' },
          ]}
        />
        {/* TAB title */}
        <TabTitle title={'Create Payout'} icon={<img src={editIcon} alt='' />} />
        {/* Form */}
        <PayoutForm type='add' />
      </div>
    </div>
  )
}

export default CreatePayout
