import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../../features/Dashboard/components/BreadCrumbs'
import TabTitle from '../components/TabTitle'
import { Link } from 'react-router-dom'
import plusIcon from 'assets/images/icons/plusIcon.png'
import cardIcon from 'assets/images/icons/cardIcon.png'
import {
  CloseIcon,
  IndicatorIcon,
  PagingDoubleLeftIcon,
  PagingDoubleRightIcon,
  PagingLeftIcon,
  PagingRightIcon,
  SearchIcon,
} from 'utils/Icons'
import Button from 'utils/Button'
import Spinner from 'utils/Spinner'
import { card_assignment, card_status, card_type, status } from 'utils/dropdownOptions'
import { useSortableData } from '../components/useSortableData'
import { useDispatch, useSelector } from 'react-redux'
import { getCardsAll } from 'store/reducers/cardsReducersSlice'
import moment from 'moment'
import { lowerString } from 'utils/helpers'
import { formatDateTime } from 'utils/formatDate'

const AllCards = () => {
  const dispatch = useDispatch()
  const { data: cardsData, isLoading } = useSelector((store) => store.allCards)
  const [searchState, setSearchState] = useState({
    loading: false,
    data: [],
    searching: false,
  })
  // pagination buttons
  const [paginationBtns, setPaginationBtns] = useState([
    { disable: false, number: 1 },
    { disable: false, number: 2 },
    { disable: false, number: 3 },
    { disable: false, number: 4 },
    { disable: false, number: 5 },
  ])

  // form data state
  const [formData, setFormData] = useState({
    query_text: '',
    name: '',
    card_type: '',
    status: '',
    assignment: '',
  })
  // cancel search
  const cancelSearch = () => {
    setFormData({ ...formData, query_text: '' })
    setSearchState({ data: [], loading: false, searching: false })
  }

  const [totalPages, setTotalPages] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [isNextDisabled, setIsNextDisabled] = useState(false)
  const [isPrevDisabled, setIsPrevDisabled] = useState(true)

  // -----------------------------
  const cardColumn = [
    {
      title: 'created_at',
      name: <div className='w-[80px]'>Created at</div>,
      sort: true,
    },
    { title: 'id', name: 'Card ID', sort: true },
    {
      title: 'bank_card_holder_name',
      name: <div className='w-[120px]'>Name</div>,
      sort: true,
    },
    { title: 'bank_card_number', name: 'Card Number', sort: true },
    {
      title: 'bank_name',
      name: <div className='w-[120px]'>Bank Name</div>,
      sort: true,
    },
    { name: 'Currency' },
    { name: 'Type' },
    { name: 'Assignment' },
    { name: 'Status' },
    {
      title: 'last_updated',
      name: <div className='w-[80px]'>Last update</div>,
      sort: true,
    },
    { name: 'Action' },
  ]

  // table hook
  const { items: tblData, CsTable } = useSortableData(
    searchState.searching ? searchState.data : cardsData,
    cardColumn,
  )

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1)
  }
  const handlePageChange = (page) => {
    setCurrentPage(page)
    if (page === 'forward' || page === 'fastForward') {
      setCurrentPage(currentPage + 1)
    }
    if (page === 'backward' || page === 'fastBackward') {
      setCurrentPage(currentPage - 1)
    }
  }
  //  function for disabling the pagination btns when no data is there in the next page
  useEffect(() => {
    let btns = [...paginationBtns]
    if (tblData && !isLoading) {
      let totalItems = tblData?.length
      if (totalItems < itemsPerPage) {
        let changedButtons = btns?.map((btn) => {
          return {
            ...btn,
            disable: btn.number > currentPage,
          }
        })
        if (currentPage === 1) {
          return setIsPrevDisabled(true)
        }
        setIsPrevDisabled(false)
        setIsNextDisabled(true)
        return setPaginationBtns(changedButtons)
      }
      if ((totalItems = itemsPerPage)) {
        let changedButtons = btns?.map((btn) => {
          return {
            ...btn,
            disable: btn.number < currentPage,
          }
        })
        if (currentPage === 1) {
          setIsPrevDisabled(true)
        }
        setIsNextDisabled(false)
        return setPaginationBtns(changedButtons)
      }
    }
  }, [tblData.length])

  useEffect(() => {
    const skip = (currentPage - 1) * itemsPerPage

    const take = itemsPerPage

    dispatch(getCardsAll({ query: '', take, skip }))
    setIsNextDisabled(currentPage === totalPages || isLoading)
    setIsPrevDisabled(currentPage === 1 || isLoading)
  }, [dispatch, itemsPerPage, currentPage])

  useEffect(() => {
    const skip = (currentPage - 1) * itemsPerPage
    dispatch(getCardsAll({ query: '', itemsPerPage, skip }))
  }, [dispatch, itemsPerPage, currentPage])

  // search form handler
  const handleForm = (e) => {
    const { name, value } = e.target
    if (value && value.trim()?.length > 0) {
      setFormData({
        ...formData,
        [name]: value,
      })
    } else {
      cancelSearch()
    }
  }

  useEffect(() => {
    dispatch(getCardsAll())
  }, [])

  const filterByValues = (data) => {
    let list = [...data]
    return list.filter(
      (item) =>
        lowerString(item?.bank_card_holder_name)?.match(lowerString(formData?.query_text)) ||
        lowerString(item?.bank_card_number)?.match(lowerString(formData?.query_text)),
    )
  }

  const handleSearch = () => {}

  return (
    <>
      <div className='flex flex-col h-full mb-8'>
        {/* Page BreadCrumbs */}
        <BreadCrumbs
          navList={[
            { title: 'Home', path: '' },
            { title: 'Admin', path: '' },
            { title: 'All Card', path: '' },
          ]}
        />
        {/* Page Title */}
        <div className='cs_row items-center'>
          <div className='mr-2'>
            <TabTitle title={'All Card'} icon={<img src={cardIcon} alt='' />} />
          </div>
          <Link to='addNew' className='ml-auto cs_btn flex items-center'>
            <span className='mr-2 flex items-center justify-center'>
              <img src={plusIcon} alt='' />
            </span>{' '}
            Create Card
          </Link>
        </div>
        {/* Search Form */}
        <div className='flex flex-wrap md:mt-6 mt-2'>
          <form onSubmit={(e) => e.preventDefault()}>
            <div className='w-full flex items-center flex-wrap'>
              <div>
                <label htmlFor='query_text' className='font-medium'>
                  Card Name/ Number
                </label>
                <div className='relative overflow-hidden'>
                  <input
                    type='text'
                    id='query_text'
                    name='query_text'
                    placeholder='Search'
                    value={formData.query_text}
                    onChange={handleForm}
                    className='w-100 border focus:outline-none m-1 p-[2px] rounded pl-3'
                  />
                  {formData.query_text?.length > 0 && (
                    <span
                      onClick={cancelSearch}
                      tabIndex='0'
                      role='button'
                      className='absolute top-[50%] -translate-y-[50%] right-1 pl-0 pr-2 pt-[8px] pb-[5px] h-[78%] flex justify-center items-center cursor-pointer select-none bg-white border border-l-0'
                    >
                      <CloseIcon />
                    </span>
                  )}
                </div>
              </div>
              {/* Card Type dropDown */}
              <div className='pr-2'>
                <label htmlFor='card_type' className='font-medium'>
                  Card Type
                </label>
                <div className='relative overflow-hidden'>
                  <select
                    name='card_type'
                    id='card_type'
                    value={formData.card_type}
                    onChange={(e) => {
                      setFormData({
                        ...formData,
                        card_type: e.target.value,
                      })
                    }}
                    className='w-100 border focus:outline-none m-1 p-[2px] rounded pl-3'
                  >
                    <option value='' className='text-gray-500' disabled>
                      Dropdown
                    </option>
                    {card_type?.map((v, i) => (
                      <option value={v} key={i}>
                        {v}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* status Drop Down */}
              <div className='pr-2'>
                <label htmlFor='status' className='font_500'>
                  Status
                </label>
                <div className='cs_row items-center'>
                  <div className='relative overflow-hidden pr-2'>
                    <select
                      name='status'
                      id='status'
                      value={formData.status}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          status: e.target.value,
                        })
                      }}
                      className='w-100 border focus:outline-none m-1 p-[2px] rounded pl-3'
                    >
                      <option value='' disabled>
                        Dropdown
                      </option>
                      {status?.map((v, i) => (
                        <option value={v} key={i}>
                          {v}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {/* Assignment Drop Down */}
              <div className='pr-2'>
                <label htmlFor='status' className='font_500'>
                  Assignment
                </label>
                <div className='cs_row items-center'>
                  <div className='relative overflow-hidden pr-2'>
                    <select
                      name='status'
                      id='status'
                      value={formData.assignment}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          assignment: e.target.value,
                        })
                      }}
                      className='mt w-100 border focus:outline-none m-1 p-[2px] rounded pl-3'
                    >
                      <option value='' className='text-gray-300' disabled>
                        Dropdown
                      </option>
                      {card_assignment?.map((v, i) => (
                        <option value={v} key={i}>
                          {v}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
              {/* -------------- */}
              <Button
                classes='md:ml-[1rem] my-0 ms-2  bg-purple-500 text-white  flex  bg-purple-500 border-purple-500 rounded cursor-pointer select-none  justify-center min-h-[30px] w-max  max-w-full text-base font-semiBold px-2 py-1 leading-6 my-[6px]   flex items-center mt-6'
                style={{ opacity: searchState.loading ? '.8' : '1' }}
                onClick={handleSearch}
              >
                {!searchState.loading ? (
                  <SearchIcon className='me_2' />
                ) : (
                  <div className='me_2'>
                    <Spinner type='spinner' />
                  </div>
                )}
                Search
              </Button>
            </div>
          </form>
        </div>
        {/* Cards Data Table */}
        <div className='mt-7'>
          <div className='md:my-4 border rounded my-2'>
            <CsTable columns={cardColumn} loading={isLoading} tableClasses='header_fill'>
              {!isLoading ? (
                filterByValues(tblData)?.length > 0 ? (
                  filterByValues(tblData).map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className='text-center'>
                          {item?.created_at ? formatDateTime(item?.created_at) : '-'}
                        </div>
                      </td>
                      <td>
                        <div className='text-center'>{item?.id}</div>
                      </td>
                      <td>
                        <div className='text-center'>{item?.bank_card_holder_name}</div>
                      </td>
                      <td>
                        <div className='text-center'>{item?.bank_card_number}</div>
                      </td>
                      <td>
                        <div className='text-center'>{item?.bank_name}</div>
                      </td>
                      <td>
                        <div className='text-center'>{item?.currency || 'CNY'}</div>
                      </td>
                      <td>
                        <div className='text-center'>{item?.card_type}</div>
                      </td>
                      <td>
                        <div className='text-center'>{item?.assignment_status}</div>
                      </td>

                      <td className=''>
                        <div className='flex items-center justify-start'>
                          <span className='mr-2'>
                            <IndicatorIcon
                              type={item?.status == 'INACTIVE' ? 'InActive' : 'Active'}
                            />
                          </span>
                          <p className='text_sm'>
                            {item?.status == 'INACTIVE' ? 'InActive' : 'Active'}
                          </p>
                        </div>
                      </td>
                      <td className='text-center'>
                        {item?.last_updated ? formatDateTime(item?.last_updated) : '-'}
                      </td>
                      <td className='text-center'>
                        <Link to={'details/' + item.id} className='px-2 text-blue-400 underline'>
                          Details
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='11'>
                      <div className='my-4 mx-auto text-center'>
                        <p>No records</p>
                      </div>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan='11'>
                    <div className='my-4 mx-auto text-center'>
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}
            </CsTable>
          </div>
        </div>
        {/* Table End */}
        {/* table pagination */}
        <div className='flex flex-wrap w-full justify-end  min-h-[41px] mb-[22px] gap-2  md:min-h-[27px] md:mb-[7px]  '>
          <div className='rounded w-max ml-auto my-2 overflow-hidden flex '>
            <Button
              disabled={isPrevDisabled}
              onClick={() => handlePageChange('backward')}
              classes={`  px-[12px] disabled:opacity-50 disabled:cursor-not-allowed rounded-none ${'rounded-none'}`}
            >
              <PagingDoubleLeftIcon />
            </Button>
            <Button
              disabled={isPrevDisabled}
              onClick={() => handlePageChange('fastBackward')}
              classes={`  px-[12px] disabled:opacity-50 disabled:cursor-not-allowed rounded-none ${'rounded-none'}`}
            >
              <PagingLeftIcon />
            </Button>
            {paginationBtns.map((btn) => (
              <Button
                disabled={btn.disable}
                key={btn.number}
                onClick={() => handlePageChange(btn.number)}
                classes={`  px-[12px] ${
                  currentPage === btn.number
                    ? 'bg-[#01416d] text-white rounded-none'
                    : 'rounded-none'
                }`}
              >
                <span className='font-normal'>{btn.number}</span>
              </Button>
            ))}
            <Button
              disabled={isNextDisabled}
              onClick={() => handlePageChange('forward')}
              classes={`px-[12px] 
                   rounded-none`}
            >
              <PagingRightIcon />
            </Button>
            <Button
              disabled={isNextDisabled}
              onClick={() => handlePageChange('fastForward')}
              classes={`  px-[12px] ${'rounded-none'}`}
            >
              <PagingDoubleRightIcon />
            </Button>
          </div>
          <div className='w-auto  flex items-center justify-center rounded mb-6 md:mb-0'>
            <select onChange={handlePerPageChange} name='' id='' className='border p-1 rounded'>
              <option value='10'>10 / page</option>
              <option value='30'>30 / page</option>
              <option value='60'>60 / page</option>
              <option value='100'>100 / page</option>
            </select>
          </div>
        </div>
      </div>
    </>
  )
}

export default AllCards
