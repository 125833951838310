import Table from '../../components/Table'
import { useState } from 'react'
import infoIcon from 'assets/images/icons/infoIcon.png'
import Button from 'utils/Button'
import plusIcon from 'assets/images/icons/plusIcon.png'
import { useEffect } from 'react'
import { generateUID } from 'utils/helpers'
import { toast } from 'react-toastify'
import Spinner from 'utils/Spinner'
import { useSortableData2 } from 'pages/Dashboard/components/useSortableData2'
import { currency, payment_method_type } from 'utils/dropdownOptions'
import {
  addPaymentMethod,
  deletePaymentMethod,
  getPaymentMethods,
  updatePaymentMethod,
} from 'api/api'
import moment from 'moment'

const PaymentTable = ({ id }) => {
  const [loadingData, setLoadingData] = useState(true)
  const [loadingFunc, setLoadingFunc] = useState(false)

  const paymentTableColumns = [
    { name: '' },
    { name: 'Type', title: 'type', sort: true },
    { name: 'Currency', title: 'currency', sort: true },
    { name: 'Bank', title: 'bank', sort: true },
    { name: 'Name', title: 'name', sort: true },
    { name: 'Account Number', title: 'account_number', sort: true },
    { name: 'Last Updated', title: 'last_updated', sort: true },
    { name: <p className='w-full text-left pl-1'>Action</p>, sort: false },
  ]

  const [dataForPaymentTable, setDataForPaymentTable] = useState([])

  const { items: tblData, CsTableHead } = useSortableData2(dataForPaymentTable, paymentTableColumns)

  //
  useEffect(() => {
    if (!id) return
    getPaymentMethods(id)
      .then((data) => {
        if (data) {
          setDataForPaymentTable(data || [])
          setLoadingData(false)
        }
      })
      .catch((err) => {
        setLoadingData(false)
        toast.error(err.message || "Couldn't get payment methods!")
      })
  }, [id])

  const togglePrimaryMethod = (elemId) => {
    let prev = [...dataForPaymentTable]
    let obj = dataForPaymentTable.find((v) => v.id === elemId)
    let index = prev.findIndex((v) => v.id === elemId)
    const listItems = dataForPaymentTable?.filter((v) => v.is_primary === true) || []
    if (listItems.length < 2 && !obj.is_primary === false) {
      toast.info('At least 1 primary method is needed')
      return
    } else {
      setLoadingFunc(true)
      obj = {
        ...obj,
        is_primary: !obj.is_primary,
      }

      updatePaymentMethod(obj, elemId)
        .then(() => {
          prev[index] = obj
          toast.success('Primary method updated')
          setLoadingFunc(false)
          getPaymentMethods(id)
            .then((data) => {
              if (data) {
                setDataForPaymentTable(data.data || [])
                setLoadingData(false)
              }
            })
            .catch((err) => {
              setLoadingData(false)
              toast.error(err.message || "Couldn't get payment methods!")
            })
        })
        .catch((err) => {
          console.log(err)
          toast.error(err.message || 'Something went wrong!')
          setLoadingFunc(false)
        })
    }
  }
  const addNewRow = () => {
    let data = [...dataForPaymentTable]
    data.unshift({
      id: generateUID(),
      newRecord: true,
      is_primary: false,
      type: 'WALLET',
      currency: 'CNY',
      bank: '',
      name: '',
      account_number: '',
      last_updated: 'N/A',
    })
    setDataForPaymentTable(data)
  }

  // enable editing for a particular raw
  const editEnable = (rowId, status) => {
    let prev = [...dataForPaymentTable]
    let index = prev.findIndex((v) => v.id === rowId)
    prev[index] = { ...prev[index], editing: status }
    setDataForPaymentTable(prev)
  }

  // cancel new record add/ cancel new record
  const cancelNewRow = (elemId, type) => {
    if (elemId === null || elemId === undefined) return
    if (type && type === 'new') {
      let filtered = dataForPaymentTable.filter((v) => v.id !== elemId)
      setDataForPaymentTable(filtered)
    } else if (type && type === 'edit') {
      editEnable(elemId, false)
    } else {
      const listItems = dataForPaymentTable?.filter((v) => v.is_primary === true) || []
      const isPrimaryItem = dataForPaymentTable?.find((v) => v.id === elemId)?.is_primary
      if (listItems.length < 2 && isPrimaryItem) {
        toast.info('At least 1 primary method is needed')
        return
      } else {
        setLoadingFunc(true)
        deletePaymentMethod(elemId)
          .then(() => {
            let filtered = dataForPaymentTable.filter((v) => v.id !== elemId)
            setDataForPaymentTable(filtered)
            toast.success('Payment method deleted successfully')
            setLoadingFunc(false)
          })
          .catch((e) => {
            console.log(e)
            toast.error(e.message || 'Something went wrong!')
            setLoadingFunc(false)
          })
      }
    }
  }

  // save new record / add a new record in our data
  const saveNewRow = (elemId, newRecord) => {
    let obj = dataForPaymentTable.find((v) => v.id === elemId)
    setLoadingFunc(true)
    if (newRecord === 'new') {
      // add new
      let isPrimary = dataForPaymentTable.length > 1 ? false : true
      addPaymentMethod(
        {
          type: obj.type,
          currency: obj.currency,
          bank: obj.bank,
          name: obj.name,
          account_number: obj.account_number,
          is_primary: isPrimary,
        },
        id,
      )
        .then((res) => {
          let data = res?.data || {}
          let prev = [...dataForPaymentTable]
          let index = prev.findIndex((v) => v.id === elemId)
          prev[index] = {
            ...prev[index],
            id: data?.id,
            is_primary: data?.is_primary,
            last_updated: data?.last_updated,
            merchant_id: data?.merchant_id,
            created_at: data?.created_at,
            newRecord: false,
            editing: false,
          }
          setDataForPaymentTable(prev)
          toast.success('New method has been saved successfully')
          setLoadingFunc(false)
        })
        .catch((err) => {
          console.log(err)
          toast.error(err.message || 'Something went wrong!')
          setLoadingFunc(false)
        })
    } else {
      // update
      delete obj.created_at
      delete obj.last_updated
      delete obj.editing
      delete obj.id
      delete obj.merchant_id

      updatePaymentMethod(obj, elemId)
        .then(() => {
          let prev = [...dataForPaymentTable]
          let index = prev.findIndex((v) => v.id === elemId)
          prev[index] = {
            ...prev[index],
            newRecord: false,
            editing: false,
          }
          setDataForPaymentTable(prev)
          toast.success('Updated successfully')
          setLoadingFunc(false)
        })
        .catch((err) => {
          console.log(err)
          toast.error(err.message || 'Something went wrong!')
          setLoadingFunc(false)
        })
    }
  }

  // handle input on change
  const handleInput = (e, rowId) => {
    if (loadingFunc) return
    let { name, value } = e.target
    let prev = [...dataForPaymentTable]
    let index = prev.findIndex((v) => v.id === rowId)
    prev[index] = { ...prev[index], [name]: value }
    setDataForPaymentTable(prev)
  }

  return (
    <div className='border rounded md:my-3 my-2'>
      <div className=' md:px-3 px-2 py-2 flex flex-wrap items-center'>
        <div className='flex flex-wrap items-center'>
          <h6 className='p-2 text-2xl font-bold'>Payment Method</h6>
          <p className='flex justify-start items-center text-sm'>
            <span className='mr-2 flex items-center justify-center'>
              <img src={infoIcon} alt='' />
            </span>
            (at least 1 record for chossing schedule settlement)
          </p>
        </div>
        <Button
          disabled={loadingData || loadingFunc}
          onClick={addNewRow}
          classes='ml-auto flex items-center'
        >
          <span className='mr-2 flex items-center justify-center'>
            <img src={plusIcon} alt='' />
          </span>{' '}
          Add method
        </Button>
      </div>
      <Table CsTableHead={<CsTableHead loading={loadingFunc} />}>
        {loadingData ? (
          <tr>
            <td colSpan='8' className='text-center'>
              <div className='w-full py-3 px-2'>
                <Spinner />
                <h6 className='py-2 w-full text-center'>Loading...</h6>
              </div>
            </td>
          </tr>
        ) : (
          tblData?.map((item, i) => (
            <tr key={i} className=''>
              <td>
                <p className='font-semibold font_prompt text-sm'>
                  {item.is_primary ? 'PRIMARY' : ''}
                </p>
              </td>
              <td>
                {item.newRecord || item?.editing ? (
                  <div className='flex items-center justify-center'>
                    <select
                      name='type'
                      id=''
                      className=' cursor-pointer  w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                      value={item?.type}
                      onChange={(e) => handleInput(e, item.id)}
                    >
                      {payment_method_type.map((v, i) => (
                        <option value={v} key={i}>
                          {v}
                        </option>
                      ))}
                    </select>
                  </div>
                ) : (
                  item?.type
                )}
              </td>
              <td>
                {item.newRecord || item?.editing ? (
                  <div className='flex items-center justify-center'>
                    <select
                      name='currency'
                      id=''
                      className='cursor-pointer  w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                      value={item?.currency}
                      onChange={(e) => handleInput(e, item.id)}
                    >
                      <option>CNY</option>
                    </select>
                  </div>
                ) : (
                  item?.currency
                )}
              </td>
              <td>
                {item.newRecord || item?.editing ? (
                  <div className='form_group flex items-center justify-center'>
                    <input
                      type='text'
                      name='bank'
                      id=''
                      placeholder='Bank'
                      onChange={(e) => handleInput(e, item.id)}
                      value={item?.bank}
                      readOnly={loadingFunc}
                      disabled={loadingFunc}
                    />
                  </div>
                ) : (
                  item?.bank
                )}
              </td>
              <td>
                {item.newRecord || item?.editing ? (
                  <div className='form_group flex items-center justify-center'>
                    <input
                      type='text'
                      name='name'
                      id=''
                      placeholder='Name'
                      onChange={(e) => handleInput(e, item.id)}
                      value={item?.name}
                      style={{ textAlign: 'left' }}
                      readOnly={loadingFunc}
                      disabled={loadingFunc}
                    />
                  </div>
                ) : (
                  item?.name
                )}
              </td>
              <td className='account_num_td'>
                {item.newRecord || item?.editing ? (
                  <div className='form_group flex items-center justify-center'>
                    <input
                      type='text'
                      name='account_number'
                      id=''
                      placeholder='Account No'
                      readOnly={loadingFunc}
                      disabled={loadingFunc}
                      onChange={(e) => handleInput(e, item.id)}
                      value={item?.account_number}
                      style={{ maxWidth: '100%', textAlign: 'left' }}
                    />
                  </div>
                ) : (
                  item?.account_number
                )}
              </td>
              <td>
                {item?.last_updated !== 'N/A' && moment(item?.last_updated).format('DD/MM/YYYY')}
              </td>
              <td>
                <div className='flex items-center justify-start tbl_action_wrapper'>
                  {item.newRecord || item?.editing ? (
                    <>
                      <Button
                        disabled={loadingFunc}
                        onClick={() => saveNewRow(item.id, item?.editing ? 'edit' : 'new')}
                        classes={'text_btn  tbl_btn'}
                      >
                        Save
                      </Button>
                      <Button
                        disabled={loadingFunc}
                        onClick={() => cancelNewRow(item.id, item?.editing ? 'edit' : 'new')}
                        classes='text_btn tbl_btn side_border_btn side_border_btn_left'
                      >
                        Cancel
                      </Button>
                    </>
                  ) : (
                    <>
                      {!item.is_primary && (
                        <Button
                          disabled={loadingFunc}
                          onClick={() => togglePrimaryMethod(item.id)}
                          classes='text_btn tbl_btn side_border_btn side_border_btn_right'
                        >
                          Set as Primary
                        </Button>
                      )}
                      <Button
                        disabled={loadingFunc}
                        onClick={() => editEnable(item.id, true)}
                        classes={'text_btn  tbl_btn'}
                      >
                        Edit
                      </Button>
                      <Button
                        disabled={loadingFunc}
                        onClick={() => cancelNewRow(item.id)}
                        classes='text_btn tbl_btn side_border_btn side_border_btn_left'
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))
        )}
      </Table>
    </div>
  )
}

export default PaymentTable
