import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllCards } from 'api/api'

export const getCardsAll = createAsyncThunk(
  'cards/getAllCards',
  async ({ query, itemsPerPage, skip }) => {
    return await getAllCards(query || '', itemsPerPage || '', skip || '')
  },
)

const initialState = {
  data: [],
  isLoading: true,
}

export const cardSlice = createSlice({
  name: 'cards',
  initialState,
  reducers: {
    setAllCards: (state, action) => {
      state.data = action.payload.data
      state.isLoading = action.payload.isLoading
    },
  },
  extraReducers: {
    [getCardsAll.pending]: (state) => {
      state.isLoading = true
    },
    [getCardsAll.fulfilled]: (state, action) => {
      state.data = action.payload || []
      state.isLoading = false
    },
    [getCardsAll.rejected]: (state) => {
      state.data = []
      state.isLoading = false
    },
  },
})

export const { setAllCards } = cardSlice.actions
export default cardSlice.reducer
