import { Form, Formik } from 'formik'
import React, { useState } from 'react'
import acceptIconImg from '../../../../assets/images/icons/acceptIcon.png'
import { agentProfileSchema, initialValues as agentInitialValues } from '../config/agentSchema'
import CsModal from 'utils/Modal'
import AgentFormWrapper from './AgentFormWrapper'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { editAgent } from 'api/api'
import { getAgentData } from 'store/reducers/agentReducerSlice'
import { toast } from 'react-toastify'
import { lowerString } from 'utils/helpers'

const AgentProfile = ({ id, profileData, setProfileData }) => {
  // modal state
  const [modalOpen, setModal] = useState({ open: false, id: '' })
  const dispatch = useDispatch()

  const handleSubmit = (values, { setSubmitting }) => {
    let obj = values
    delete obj.last_updated
    obj.is_active = lowerString(obj?.is_active) === 'active'
    editAgent(obj, id)
      .then(() => {
        dispatch(getAgentData())
        setSubmitting(false)
        setModal({ open: true, id: id })
        let modified = {
          ...values,
          is_active: values?.is_active === true ? 'Active' : 'Inactive',
          last_updated: new Date().toISOString(),
        }
        setProfileData(modified)
      })
      .catch((err) => {
        setSubmitting(false)
        console.log(err)
        toast.error(err.message || 'Something went wrong')
      })
  }
  const handleCloseModal = () => {
    setModal({
      open: false,
      id: '',
    })
  }

  return (
    <>
      <Formik
        initialValues={profileData || agentInitialValues}
        validationSchema={Yup.object().shape({
          ...agentProfileSchema,
        })}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, handleChange, values, errors, touched }) => (
          <Form autoComplete='off'>
            <AgentFormWrapper
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              id={id}
              profileData={profileData}
              type='edit'
            />
          </Form>
        )}
      </Formik>

      {/* Model */}
      <CsModal open={modalOpen.open} onClose={handleCloseModal}>
        <div className='bg-white text-center  cs_modal rounded md:px-3 md:py-4 px-2 py-3'>
          <div className='flex content-center items-center my-2'>
            <span className='mr-2 flex items-center'>
              <img src={acceptIconImg} alt='' />
            </span>
            <p className='ml-1'>Agent profile has been updated successfully</p>
          </div>
          <div className='my-2 flex flex-wrap justify-center items-center'>
            <button className='cs_btn mx-2' onClick={handleCloseModal}>
              Okay
            </button>
          </div>
        </div>
      </CsModal>
    </>
  )
}

export default AgentProfile
