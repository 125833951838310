import axios from 'axios'

const getOrders = async (take, skip, sort) => {
  const res = await axios.get(`/orders?take=${take}&skip=${skip}&sort=${sort}`)
  return res.data
}
const getOrderDetails = async (id) => {
  const res = await axios.get(`/orders/${id}`)
  return res.data
}
const doConfirmOrder = async (id) => {
  return await axios.patch(`/orders/${id}/do-confirm`)
}

export { getOrders, getOrderDetails, doConfirmOrder }
