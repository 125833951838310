import moment from 'moment'

const formatDateTime = (date) => {
  return moment(date).format('DD/MM/YYYY') + ' ' + moment(date).format('HH:mm')
}

const formatDate = (date) => {
  return moment(date).format('DD/MM/YYYY')
}

export { formatDate, formatDateTime }
