import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'

import { getMerchantData } from 'store/reducers/merchantReducerSlice'
import { getWalletDetails } from 'api/RESTful/wallet'

import Icon from 'common/components/Icons'

import Button from 'utils/Button'
import {
  CloseIcon,
  IndicatorIcon,
  PagingDoubleLeftIcon,
  PagingDoubleRightIcon,
  PagingLeftIcon,
  PagingRightIcon,
} from 'utils/Icons'
import plusIcon from 'assets/images/icons/plusIcon.png'

import TabTitle from '../components/TabTitle'
import BreadCrumbs from '../../../features/Dashboard/components/BreadCrumbs'
import { useSortableData } from '../components/useSortableData'

import { lowerString, numberWithCommas } from 'utils/helpers'
import Spinner from 'utils/Spinner'
import { formatDateTime } from 'utils/formatDate'

// ===== Dashboard merchant tab/screen ===========
const Merchant = () => {
  const dispatch = useDispatch()
  // table header  config
  const merchantColumns = [
    { name: 'Merchant ID', title: 'id', sort: true },
    { name: 'Merchant Name', title: 'name', sort: true },
    { name: 'Available Balance (CNY)', title: 'payin', sort: true },
    { name: <div className='text-left flex-grow '>Status</div> },
    { name: 'Action' },
  ]
  // redux
  const { data: merchantData, isLoading } = useSelector((store) => store.merchant)

  // buttons pagination
  const [paginationBtns, setPaginationBtns] = useState([
    { disable: false, number: 1 },
    { disable: false, number: 2 },
    { disable: false, number: 3 },
    { disable: false, number: 4 },
    { disable: false, number: 5 },
  ])
  // Pagination logic start from here.................................
  const [totalPages, setTotalPages] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [activePagination, setActivePaging] = useState('1')
  const [isNextDisabled, setIsNextDisabled] = useState(false)
  const [isPrevDisabled, setIsPrevDisabled] = useState(true)

  // for search loading and data filtering
  const [searchState, setSearchState] = useState({
    loading: false,
    data: [],
    searching: false,
  })
  // form data state
  const [formData, setFormData] = useState({
    query_text: '',
  })

  // table hook
  const { items: tblData, CsTable } = useSortableData(merchantData)

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset to the first page when itemsPerPage changes
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
    if (page === 'forward' || page === 'fastForward') {
      setCurrentPage(currentPage + 1)
    }
    if (page === 'backward' || page === 'fastBackward') {
      setCurrentPage(currentPage - 1)
    }
  }
  //  function for disabling the pagination btns when no data is there in the next page
  useEffect(() => {
    let btns = [...paginationBtns]
    if (tblData && !isLoading) {
      let totalItems = tblData?.length
      if (totalItems < itemsPerPage) {
        let changedButtons = btns?.map((btn) => {
          return {
            ...btn,
            disable: btn.number > currentPage,
          }
        })
        if (currentPage === 1) {
          setIsPrevDisabled(true)
        }
        setIsPrevDisabled(false)
        setIsNextDisabled(true)
        return setPaginationBtns(changedButtons)
      }
      if ((totalItems = itemsPerPage)) {
        let changedButtons = btns?.map((btn) => {
          return {
            ...btn,
            disable: btn.number < currentPage,
          }
        })
        if (currentPage === 1) {
          setIsPrevDisabled(true)
        }
        setIsNextDisabled(false)
        return setPaginationBtns(changedButtons)
      }
    }
  }, [tblData.length])

  //
  useEffect(() => {
    const skip = (currentPage - 1) * itemsPerPage
    const take = itemsPerPage
    dispatch(getMerchantData({ query: '', take, skip }))
    setIsNextDisabled(currentPage === totalPages || isLoading)
    setIsPrevDisabled(currentPage === 1 || isLoading)
  }, [dispatch, itemsPerPage, currentPage])

  //
  useEffect(() => {
    const skip = (currentPage - 1) * itemsPerPage
    dispatch(getMerchantData({ query: '', itemsPerPage, skip }))
  }, [dispatch, itemsPerPage, currentPage])

  // cancel search
  const cancelSearch = () => {
    setFormData({ ...formData, query_text: '' })
    setSearchState({ data: [], loading: false, searching: false })
  }

  // search form handler
  const handleForm = (e) => {
    const { name, value } = e.target
    if (value && value.trim()?.length > 0) {
      setFormData({
        ...formData,
        [name]: value,
      })
    } else {
      cancelSearch()
    }
  }

  const filterByValues = (data) => {
    let list = [...data]
    return list.filter(
      (item) =>
        lowerString(item?.name)?.match(lowerString(formData?.query_text)) ||
        lowerString(item?.id)?.match(lowerString(formData?.query_text)) ||
        lowerString(item?.email)?.match(lowerString(formData?.query_text)),
    )
  }

  useEffect(() => {
    if (isLoading || merchantData?.length > 0) return
    dispatch(getMerchantData('?take=10&skip=0'))
  }, [])
  //

  return (
    <div>
      {/* ********** page breadcrumbs  ************** */}
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '/dashboard/merchant' },
          { title: 'Merchant', path: '' },
        ]}
      />
      {/*  */}
      <div className='flex flex-wrap items-center'>
        <div className='mr-2'>
          <TabTitle title={'Merchant'} icon={<Icon name='merchantNavBar' size='40px' />} />
        </div>
        <Link to='addNew' className='ml-auto cs_btn flex items-center'>
          <span className='mr-2 flex items-center justify-center'>
            <img src={plusIcon} alt='' />
          </span>
          Create Merchant
        </Link>
      </div>
      {/* ********************** */}
      <div className='flex flex-wrap'>
        <form onSubmit={(e) => e.preventDefault()}>
          <label htmlFor='query_text' className='text_sm font-medium'>
            Merchant ID/ Name/ Email
          </label>
          <div className='w-full flex items-center'>
            <div className='relative overflow-hidden'>
              <input
                type='text'
                id='query_text'
                name='query_text'
                value={formData.query_text}
                onChange={handleForm}
                className='w-100 border focus:outline-none p-[2px] rounded pl-3'
              />
              {formData.query_text?.length > 0 && (
                <span
                  onClick={cancelSearch}
                  tabIndex='0'
                  role='button'
                  className='absolute top-[50%] -translate-y-[50%] right-1 pl-0 pr-2 pt-[8px] pb-[5px] h-[78%] flex justify-center items-center cursor-pointer select-none bg-white border border-l-0'
                >
                  <CloseIcon />
                </span>
              )}
            </div>
            <Button
              classes='btn_primary1 text-white text_sm font-normal leading-5 cursor-pointer ml-2 '
              style={{ opacity: searchState.loading ? '.8' : '1' }}
            >
              {!searchState.loading ? (
                <div className='mr-2'>
                  <Icon name='search' size='15' className='mr-2' />
                </div>
              ) : (
                <div className=''>
                  <Spinner type='spinner' />
                </div>
              )}
              Search
            </Button>
          </div>
        </form>
      </div>

      {/* ************************ */}
      {/* ****** merchant data table  */}
      <div>
        <div className='md:my-4 border rounded my-2'>
          <CsTable columns={merchantColumns} loading={isLoading} tableClasses='header_fill'>
            {!isLoading ? (
              filterByValues(tblData)?.length > 0 ? (
                filterByValues(tblData).map((item, index) => (
                  <tr key={index}>
                    <td>
                      <div className='text-center'>{'#' + (item.id || index + 1)}</div>
                    </td>
                    <td>
                      <div className='text-center'>{item.name}</div>
                    </td>
                    <td>
                      <div className='text-end pr-12'>
                        {numberWithCommas(item?.available_balance) || '-'}
                      </div>
                    </td>
                    <td
                      style={{ minWidth: '190px' }}
                      className='items-start flex flex-col justify-center p'
                    >
                      <div className='flex items-center justify-start'>
                        <span className='mr-2'>
                          <IndicatorIcon type={item?.is_active ? 'active' : 'inactive'} />
                        </span>
                        <p className='text_sm'>{item?.is_active ? 'Active' : 'Inactive'}</p>
                      </div>
                      <p className='text-gray-400 text_sm'>
                        Last Updated: {formatDateTime(item?.last_updated)}
                      </p>
                    </td>
                    <td className='text-center'>
                      <Link to={'details/' + item.id} className='px-2 text-blue-400 underline'>
                        Details
                      </Link>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='5'>
                    <div className='my-4 mx-auto text-center'>
                      <p>No records</p>
                    </div>
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan='5'>
                  <div className='my-4 mx-auto text-center'>
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}
          </CsTable>
        </div>
      </div>
      {/* table pagination */}
      <div className='flex flex-wrap w-full justify-end  min-h-[41px] mb-[22px] gap-2  md:min-h-[27px] md:mb-[7px]'>
        <div className='rounded w-max ml-auto my-2 overflow-hidden flex '>
          <Button
            disabled={isPrevDisabled}
            onClick={() => handlePageChange('backward')}
            classes={`  px-[12px] disabled:opacity-50 disabled:cursor-not-allowed rounded-none ${
              activePagination === 'backward'
                ? 'bg-[#01416d] text-white rounded-none'
                : 'rounded-none'
            }`}
          >
            <PagingDoubleLeftIcon />
          </Button>
          <Button
            disabled={isPrevDisabled}
            onClick={() => handlePageChange('fastBackward')}
            classes={`  px-[12px] disabled:opacity-50 disabled:cursor-not-allowed rounded-none ${
              activePagination === 'fastBackward'
                ? 'bg-[#01416d] text-white rounded-none'
                : 'rounded-none'
            }`}
          >
            <PagingLeftIcon />
          </Button>
          {paginationBtns.map((btn) => (
            <Button
              disabled={btn?.disable}
              key={btn.number}
              onClick={() => handlePageChange(btn.number)}
              classes={`  px-[12px] ${
                currentPage === btn.number ? 'bg-[#01416d] text-white rounded-none' : 'rounded-none'
              }`}
            >
              <span className='font-normal'>{btn.number}</span>
            </Button>
          ))}
          <Button
            disabled={isNextDisabled}
            onClick={() => handlePageChange('forward')}
            classes={`  px-[12px] ${
              activePagination === 'forward'
                ? 'bg-[#01416d] text-white rounded-none'
                : 'rounded-none'
            }`}
          >
            <PagingRightIcon />
          </Button>
          <Button
            disabled={isNextDisabled}
            onClick={() => handlePageChange('fastForward')}
            classes={`  px-[12px] ${
              activePagination === 'fastForward'
                ? 'bg-[#01416d] text-white rounded-none'
                : 'rounded-none'
            }`}
          >
            <PagingDoubleRightIcon />
          </Button>
        </div>
        <div className='w-auto  flex items-center justify-center rounded my-2 '>
          <select onChange={handlePerPageChange} name='' id='' className='border p-1 rounded'>
            <option value='10'>10 / page</option>
            <option value='30'>30 / page</option>
            <option value='60'>60 / page</option>
            <option value='100'>100 / page</option>
          </select>
        </div>
      </div>
      {/* *********** */}
    </div>
  )
}

export default Merchant
