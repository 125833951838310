import { ErrorMessage, Field, useFormikContext } from 'formik'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import Button from 'utils/Button'
import { countryList } from 'utils/countryList'
import { getPhoneCode, showPhone } from 'utils/helpers'
import Select from 'react-select'
import './index.css'
import { getAgentDropdown, getCommissionProfilesDropdown } from 'api/api'
import { status } from 'utils/dropdownOptions'
const AgentFormWrapper = ({ isSubmitting, handleChange, values, type, profileData, id }) => {
  const navigate = useNavigate()
  const { setFieldValue } = useFormikContext()

  const [selectedOptions, setSelectedOptions] = useState([null, null])
  const [commissionProfiles, setCommissionProfiles] = useState([])
  const [parentAccounts, setParentAccounts] = useState([])
  const [selectedProfile, setSelectedProfile] = useState(null)
  const [selectedParent, setSelectedParent] = useState(null)
  const [selectedOptionIds, setSelectedOptionIds] = useState([null, null])
  const [commissionProfileOptions, setCommissionProfilesOptions] = useState([])
  const [parentAccountOptions, setParentAccountOptions] = useState([])

  const dropdowns = [
    { label: 'Commission Profile', selectedOption: selectedProfile },
    { label: 'Parent Account', selectedOption: selectedParent },
  ]
  const handlePhoneCodeChange = (e) => {
    const selectedPhoneCode = e.target.value
    const updatedPhone = showPhone(selectedPhoneCode, values.phone)
    handleChange(e)
    handleChange({
      target: {
        name: 'phone',
        value: updatedPhone,
      },
    })
  } // function for handling dropdown select option

  const handleSelectOption = (index, selectedOption) => {
    const newSelectedOptions = [...selectedOptions]
    newSelectedOptions[index] = selectedOption
    setSelectedOptions(newSelectedOptions)
    if (index === 0) {
      setSelectedProfile(selectedOption)
      setFieldValue('profile_id', selectedOption ? selectedOption.id : null)
    } else if (index === 1) {
      setSelectedParent(selectedOption)
      setFieldValue('parent_id', selectedOption ? selectedOption.id : null)
    }
    // Update the selected option IDs
    const newSelectedOptionIds = [...selectedOptionIds]
    newSelectedOptionIds[index] = selectedOption ? selectedOption.id : null
    setSelectedOptionIds(newSelectedOptionIds)
  }

  useEffect(() => {
    setCommissionProfilesOptions(
      selectedParent && selectedParent.value !== ''
        ? [
            { value: '', label: 'Unassign the profile', id: null }, // Replace null with empty string
            ...commissionProfiles
              .filter((profile) => profile.agent_2_percent !== null)
              .map((profile) => ({
                value: profile.profile_name,
                label: profile.profile_name,
                id: profile.id,
              })),
          ]
        : [
            ...commissionProfiles
              .filter((profile) => profile.agent_2_percent == null)
              .map((profile) => ({
                value: profile.profile_name,
                label: profile.profile_name,
                id: profile.id,
              })),
          ],
    )

    setParentAccountOptions(
      selectedParent
        ? [
            { value: '', label: 'Unassign the parent account', id: null }, // Replace null with empty string
            ...parentAccounts.map((profile) => ({
              value: profile.name,
              label: profile.name,
              id: profile.id,
            })),
          ]
        : parentAccounts.map((profile) => ({
            value: profile.name,
            label: profile.name,
            id: profile.id,
          })),
    )
  }, [selectedParent, commissionProfiles, parentAccounts])

  useEffect(() => {
    setSelectedProfile(
      profileData
        ? commissionProfileOptions.find((option) => option.id === profileData.profile_id)
        : null,
    )
    setSelectedParent(
      profileData
        ? parentAccountOptions.find((option) => option.id === profileData.parent_id)
        : null,
    )
  }, [profileData])

  // getting data for dropdown options
  useEffect(() => {
    getCommissionProfilesDropdown().then((res) => {
      setCommissionProfiles(res.data || [])
    })
    getAgentDropdown().then((res) => {
      const filteredData = res.data?.filter((item) => !item.is_master)
      setParentAccounts(filteredData || [])
    })
  }, [])

  return (
    <>
      <div className='border rounded mb-3 mt-4 md:mt-9'>
        <div className={`md:px-3 px-2 py-1 ${type === 'add' ? 'md:mb-3 mb-2' : ''}`}>
          {type === 'edit' ? (
            <h5 className='text-2xl font-bold p-2'>Profile</h5>
          ) : (
            <h5 className='text-2xl font-bold p-2'>New Agent Profile</h5>
          )}

          <div className='md:mt-2'>
            {/* 1 */}
            <div className='flex flex-wrap my-1 items-center'>
              <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                <label htmlFor='name'>
                  Account Name<b className='text-red-600'>*</b>
                </label>
              </div>
              <div className='col_ col_md_4 col_12 flex items-center'>
                <div className='w-full flex items-center'>
                  <Field
                    type='text'
                    name='name'
                    id='name'
                    placeholder='Account Name'
                    className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  />
                </div>
              </div>
              <div className='col_md_5 col_12'>
                <ErrorMessage
                  name='name'
                  component='p'
                  className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                />
              </div>
            </div>
            {/* 2 */}
            <div className='flex flex-wrap md:my-0 my-1 items-center'>
              <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                <label htmlFor='address1'>Business address</label>
              </div>
              <div className='col_ col_md_4 col_12 flex item-center'>
                <div className='w-full flex items-center'>
                  <Field
                    type='text'
                    name='address1'
                    id='address1'
                    placeholder='Address 1'
                    className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  />
                </div>
              </div>
              <div className='col_md_5 col_12'>
                <ErrorMessage
                  name='address1'
                  component='p'
                  className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                />
              </div>
            </div>
            {/* 3 */}
            <div className=' flex flex-wrap md:my-0 my-1 items-center'>
              <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'></div>
              <div className='col_ col_md_4 col_12 flex items-center'>
                <div className='w-full flex items-center'>
                  <Field
                    type='text'
                    name='address2'
                    id='address2'
                    placeholder='Address 2'
                    className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  />
                </div>
              </div>
              <div className='col_md_5 col_12'>
                <p className='text-gray-400 w-full text-start md:ml-auto md:pl-2'></p>
              </div>
            </div>
            {/* 4 */}
            <div className=' flex flex-wrap md:my-0 my-1 items-center'>
              <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'></div>
              <div className='col_ col_md_4 col_12 flex items-center'>
                <div className='w-full flex items-center'>
                  <Field
                    type='text'
                    name='city'
                    id='city'
                    placeholder='City'
                    className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  />
                </div>
              </div>
              <div className='col_md_5 col_12'>
                <ErrorMessage
                  name='city'
                  component='p'
                  className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                />
              </div>
            </div>
            {/* 5 */}
            <div className='flex flex-wrap md:my-0 my-1 items-center'>
              <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'></div>
              <div className='col_ col_md_4 col_12 flex items-center'>
                <div className='w-full flex items-center'>
                  <Field
                    type='text'
                    name='country'
                    id='country'
                    placeholder='Country'
                    className=' w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  />
                </div>
              </div>
              <div className='col_md_5 col_12'>
                <ErrorMessage
                  name='country'
                  component='p'
                  className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                />
              </div>
            </div>
            {/* 6 */}
            <div className='flex flex-wrap md:my-0 my-1 items-center'>
              <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'></div>
              <div className='col_ col_md_4 col_12 flex items-center'>
                <div className='w-full flex items-center'>
                  <Field
                    type='number'
                    name='postal_code'
                    id='postal_code'
                    placeholder='ZIP/ Postal Code'
                    className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  />
                </div>
              </div>
              <div className='col_md_5 col_12'>
                <ErrorMessage
                  name='postal_code'
                  component='p'
                  className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                />
              </div>
            </div>
            {/* 8 */}
            <div className='flex flex-wrap my-1 items-center'>
              <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                <label htmlFor='email'>
                  Email<b className='text-red-600'>*</b>
                </label>
              </div>
              <div className='col_ col_md_4 col_12 flex items-center'>
                <div className='w-full flex items-center'>
                  <Field
                    type='email'
                    name='email'
                    id='email'
                    className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  />
                </div>
              </div>
              <div className='col_md_5 col_12'>
                <ErrorMessage
                  name='email'
                  component='p'
                  className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                />
              </div>
            </div>
            {/* 9 */}
            <div className='flex flex-wrap my-1 items-center'>
              <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                <label htmlFor='phone'>
                  Phone<b className='text-red-600'>*</b>
                </label>
              </div>
              <div className='col_ col_md_4 col_12 flex items-center'>
                <div className='w-full flex items-center'>
                  <select
                    name='phoneCode'
                    id='phoneCode'
                    className='cursor-pointer select-none me_2   rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                    onChange={handlePhoneCodeChange}
                    value={values?.phoneCode || getPhoneCode(values.phone)}
                  >
                    {countryList.map((v, i) => (
                      <option value={v.dial_code} key={i}>
                        {v.code + ' ' + v.dial_code}
                      </option>
                    ))}
                  </select>
                  <Field
                    type='phone'
                    name='phone'
                    id='phone'
                    placeholder='Phone'
                    className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                    onChange={handleChange}
                    value={showPhone(values.phoneCode, values.phone)}
                  />
                </div>
              </div>
              <div className='col_md_5 col_12'>
                <ErrorMessage
                  name='phone'
                  component='p'
                  className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                />
              </div>
            </div>
            {/* 10 */}
            <>
              <div>
                {dropdowns.map((dropdown, index) => (
                  <div key={index} className='flex flex-wrap my-1 items-center mb-3'>
                    <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='business_registration'>{dropdown.label}</label>
                    </div>
                    <div className='col_ col_md_4 col_12 flex items-center'>
                      <div className='w-full flex items-center'>
                        {/* Select dropdown for commision profile */}
                        <Select
                          value={
                            index === 0
                              ? commissionProfileOptions.find(
                                  (option) => option.id === values.profile_id,
                                )
                              : parentAccountOptions.find(
                                  (option) => option.id === values.parent_id,
                                )
                          }
                          onChange={(selectedOption) => handleSelectOption(index, selectedOption)}
                          options={
                            index === 0
                              ? commissionProfileOptions
                              : parentAccountOptions.filter((option) => option.id !== id)
                          }
                          isSearchable={true}
                          placeholder={`Select an option`}
                        />
                      </div>
                    </div>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name={index === 0 ? 'profile_id' : 'parent_id'}
                        component='p'
                        className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
            {/* 11 */}
            <div className='flex flex-wrap my-1 items-center'>
              <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                <label htmlFor='is_active'>Status</label>
              </div>
              <div className='col_ col_md_4 col_12 flex items-center'>
                <div className='w-full flex items-center'>
                  <select
                    name='is_active'
                    id='is_active'
                    className='w-full cursor-pointer select-none    rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                    onChange={handleChange}
                    value={values.is_active}
                  >
                    <option value='' disabled>
                      Select status
                    </option>
                    {status?.map((v, i) => (
                      <option value={v} key={i}>
                        {v}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className='col_md_5 col_12'>
                <ErrorMessage
                  name='status'
                  component='p'
                  className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                />
              </div>
            </div>
          </div>
          {type === 'edit' ? (
            <>
              <div className='md:my-3 flex justify-end items-center my-2'>
                <Button
                  disabled={isSubmitting}
                  onClick={() => navigate('/dashboard/agents')}
                  classes='mx-1'
                  type='button'
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  classes='text-white ml-1 bg-purple-500 border-none rounded'
                  type='submit'
                >
                  {isSubmitting ? 'Saving..' : 'Save'}
                </Button>
              </div>
            </>
          ) : (
            <div className='md:my-3 flex justify-center items-center my-2'>
              <Button
                disabled={isSubmitting}
                onClick={() => navigate('/dashboard/agents')}
                classes='mx-1'
                type='button'
              >
                Cancel
              </Button>
              <Button
                disabled={isSubmitting}
                classes='text-white ml-1 bg-purple-500 border-none rounded'
                type='submit'
              >
                {isSubmitting ? 'Creating..' : 'Create'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default AgentFormWrapper
