import * as Yup from 'yup'

export const initialValues = {
  name: '',
  address1: '',
  address2: '',
  city: '',
  country: '',
  postal_code: '',
  sources: '',
  email: '',
  phone: '',
  phoneCode: '+852',
  business_registration: '',
  transaction_currency: 'CNY',
  payin_daily_limit: 0,
  payin_currency: 'CNY',
  payout_daily_limit: 0,
  payout_currency: 'CNY',
  is_active: '',
  user: {
    email: '',
    phone: '',
    phoneCode: '+852',
    first_name: '',
    last_name: '',
    is_active: 'Active',
    role: 'MERCHANT_ADMIN',
  },
}

export const schemaObj = {
  phone: Yup.string().required('required').min(9, 'Too short').max(15, 'Max length is 11 digits'),
  email: Yup.string().email("Email must has '@'").required('Required'),
  sources: Yup.string().required('Required'),
  postal_code: Yup.string().required('Required'),
  country: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  address1: Yup.string().required('Required'),
  name: Yup.string().min(2, 'Too Short!').max(30, 'Too Long!').required('Required'),
}

export const merchantSchema = Yup.object().shape(schemaObj)
