import React from 'react'
import './components.css'

const TabTitle = ({ title, icon }) => {
  return (
    <div className='tab-title-container'>
      {icon && <span className='tab-title-icon'>{icon}</span>}
      <h4 className='tab-title-text'>{title}</h4>
    </div>
  )
}

export default TabTitle
