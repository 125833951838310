import { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { KeyIcon, StoreIcon, UserGroupIcon } from 'utils/Icons'
// links images & icons
import { NavBar } from '../../features/Dashboard/components/NavBar'
import agreementIcon from 'assets/images/icons/agreementIcon.png'
import cardIcon from 'assets/images/icons/cardIcon.png'
import clipboardIcon from 'assets/images/icons/clipboardIcon.png'
import handIcon from 'assets/images/icons/handIcon.png'
import walletIcon from 'assets/images/icons/walletIcon.png'
import profile from '../../assets/images/svgs/resume 1.svg'
import payout from '../../assets/images/svgs/payoutIcon.svg'
import api from '../../assets/images/svgs/api 1.svg'
import { useDispatch, useSelector } from 'react-redux'
import { capitalizeString } from 'utils/helpers'
import { useLocalStorage } from 'hook/useLocalStorage'
import { toast } from 'react-toastify'
import { setAuth } from 'store/reducers/authReducerSlice'

//
import './index.css'

// This is Layout component for Merchant Portal, layout contains a sidebar, top header and tabs in the right side
const MerchantPortal = () => {
  const { setLocalVal } = useLocalStorage('picotop_user_token', '')
  const { setLocalVal: setLocalUserId } = useLocalStorage('picotop_user_id', '')
  const { setLocalVal: setLocalUserEmail } = useLocalStorage('picotop_user_email', '')
  const dispatch = useDispatch()
  const { data: user } = useSelector((store) => store.auth)
  const element = useRef(null)
  const { pathname } = useLocation()
  const [menuOpen, setMenuOpen] = useState(false)
  const userRole = user.roles[0]
  // close side menu
  const closeMenu = () => {
    setMenuOpen(false)
  }
  const sidebarData = [
    {
      icon: <StoreIcon disabled />,
      path: 'merchant',
      title: 'Merchant',
    },
    {
      icon: <img width='21' height='21' src={clipboardIcon} alt='' />,
      path: 'orders',
      title: 'orders',
    },
    {
      icon: <img width='21' height='21' src={handIcon} alt='' />,
      path: 'payment',
      title: 'payment',
    },
    {
      icon: <UserGroupIcon />,
      path: 'user',
      title: 'user',
    },
    {
      icon: <img width='21' height='21' src={walletIcon} alt='' />,
      path: 'walletDetails',
      title: 'wallet',
    },
    {
      icon: <img width='21' height='21' src={payout} alt='' />,
      path: 'payout',
      title: 'payout',
    },
  ]

  // logout
  const logout = () => {
    setLocalVal('')
    setLocalUserId('')
    setLocalUserEmail('')
    dispatch(
      setAuth({
        isAuthenticated: false,
        isLoading: false,
        data: {},
      }),
    )
    toast.success('Logged out successfully!')
  }
  // scroll top on any tab change
  useEffect(() => {
    element?.current?.scrollTo(0, 0)
  }, [pathname])

  //

  return (
    <main className='dashboard_main flex mb-6 md:mb-0'>
      {/* Dashboard sidebar  */}
      <nav className={`dashboard_nav flex flex-col ${menuOpen ? ' opened' : ''} hide-scrollbar`}>
        <div className='dashboard_logo select-none flex flex-col'>
          <h4>PICOTOP</h4>
          <div className='cs_chip bg-[#31B167] text-white mx_auto bg_primary text_white '>
            Merchant
          </div>
          <div
            onClick={closeMenu}
            role='button'
            tabIndex='0'
            className='menu_close_btn flex-col  justify-between cursor-pointer select-none'
          >
            <span></span>
            <span></span>
          </div>
        </div>
        <div className='dashboard_menu hide-scrollbar'>
          {/* **** Merchant tabs **** */}
          {sidebarData.map((sidebar) => (
            <NavBar
              key={sidebar.path}
              icon={sidebar.icon}
              path={sidebar.path}
              title={sidebar.title}
              onClick={closeMenu}
            />
          ))}
        </div>
      </nav>
      {/* Dashboard tabs and header wrapper  */}
      <aside className='dashboard_aside  flex flex-col'>
        {/* here is the header of the dashboard*/}
        <header className='dashboard_header flex items-center'>
          <div
            onClick={() => setMenuOpen(true)}
            role='button'
            tabIndex='0'
            className='hamburg_icon flex-col   justify-between cursor-pointer select-none '
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <div className='ml-auto profile_name flex items-center justify-center'>
            <div className='avatar bg-orange-500 rounded-full text-white font-bold select-none flex items-center justify-center'>
              <span>{capitalizeString(user?.email?.toString()?.slice(0, 2))}</span>
            </div>
            <button className='cs_btn'>My Account</button>
            <button className='cs_btn' onClick={logout}>
              Logout
            </button>
          </div>
        </header>
        {/* this is main wrapper for all tabs that will nested in dashboard */}
        <section ref={element} className='dashboard_section_wrapper'>
          <Outlet />
        </section>
      </aside>
      {/* backdrop blur  */}
      {menuOpen && <div onClick={closeMenu} className='dashboard_backdrop'></div>}
    </main>
  )
}

export default MerchantPortal
