import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../../features/Dashboard/components/BreadCrumbs'
import TabTitle from '../components/TabTitle'
import plusIcon from 'assets/images/icons/plusIcon.png'
import { Link } from 'react-router-dom'
import { useSortableData } from '../components/useSortableData'
import {
  CloseIcon,
  PagingDoubleLeftIcon,
  PagingDoubleRightIcon,
  PagingLeftIcon,
  PagingRightIcon,
  SearchIcon,
} from 'utils/Icons'
import Button from 'utils/Button'
import Spinner from 'utils/Spinner'
import profileIcon from 'assets/images/svgs/resume 1.svg'
import { useDispatch, useSelector } from 'react-redux'
import { getAllCommissionProfiles } from 'store/reducers/profilesReducerSlice'
import { lowerString } from 'utils/helpers'
import moment from 'moment'
import { formatDateTime } from 'utils/formatDate'
const CommisionProfile = () => {
  // -----------------------------
  const profileColumn = [
    { name: '#' },
    { name: <div className='text-left flex-grow'>Profile ID</div>, title: 'id', sort: true },
    { name: <div className='text-left flex-grow'>Profile Name</div> },
    { name: <div className='text-left flex-grow'>Master Agent</div> },
    { name: 'Agent1' },
    { name: 'Agent2' },
    { title: 'last_updated', name: 'Last Updated', sort: true },
    { name: 'Action' },
  ]
  // buttons
  const [paginationBtns, setPaginationBtns] = useState([
    { disable: false, number: 1 },
    { disable: false, number: 2 },
    { disable: false, number: 3 },
    { disable: false, number: 4 },
    { disable: false, number: 5 },
  ])
  // redux

  const dispatch = useDispatch()
  const { data: profileData, isLoading } = useSelector((store) => store.profiles)

  const [searchState, setSearchState] = useState({
    loading: false,
    data: [],
    searching: false,
  })
  // table hook
  const { items: tblData, CsTable } = useSortableData(
    searchState.searching ? searchState.data : profileData,
    profileColumn,
  )

  // states
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [activePagination, setActivePaging] = useState(1)
  const [isNextDisabled, setIsNextDisabled] = useState(false)
  const [isPrevDisabled, setIsPrevDisabled] = useState(true)
  const totalPages = Math.ceil(tblData.length / itemsPerPage)

  // cancel search
  const cancelSearch = () => {
    setFormData({ ...formData, query_text: '' })
    setSearchState({ data: [], loading: false, searching: false })
  }

  // const [totalPages, setTotalPages] = useState(1);

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset to the first page when itemsPerPage changes
  }
  useEffect(() => {}, [currentPage, totalPages])

  const handlePageChange = (page) => {
    setCurrentPage(page)
    if (page === 'forward' || page === 'fastForward') {
      setCurrentPage(currentPage + 1)
    }
    if (page === 'backward' || page === 'fastBackward') {
      setCurrentPage(currentPage - 1)
      setActivePaging((prev) => prev - 1)
    }
  }
  //  function for disabling the pagination btns when no data is there in the next page
  useEffect(() => {
    let btns = [...paginationBtns]
    if (tblData && !isLoading) {
      let totalItems = tblData?.length
      if (totalItems < itemsPerPage) {
        let changedButtons = btns?.map((btn) => {
          return {
            ...btn,
            disable: btn.number > currentPage,
          }
        })
        if (currentPage === 1) {
          setIsPrevDisabled(true)
        }
        setIsPrevDisabled(false)
        setIsNextDisabled(true)
        return setPaginationBtns(changedButtons)
      }
      if ((totalItems = itemsPerPage)) {
        let changedButtons = btns?.map((btn) => {
          return {
            ...btn,
            disable: btn.number < currentPage,
          }
        })
        if (currentPage === 1) {
          setIsPrevDisabled(true)
        }
        setIsNextDisabled(false)
        return setPaginationBtns(changedButtons)
      }
    }
  }, [tblData.length])

  useEffect(() => {
    const skip = (currentPage - 1) * itemsPerPage

    const take = itemsPerPage

    dispatch(getAllCommissionProfiles({ query: '', take, skip }))
  }, [dispatch, itemsPerPage, currentPage])

  useEffect(() => {
    // getting data from api on taking values from pagination
    const skip = (currentPage - 1) * itemsPerPage
    dispatch(getAllCommissionProfiles({ query: '', itemsPerPage, skip }))
  }, [dispatch, itemsPerPage, currentPage])
  const disableBtns = () => {}
  const [formData, setFormData] = useState({
    query_text: '',
  })
  // redux
  // search form handler
  const handleForm = (e) => {
    const { name, value } = e.target
    if (value && value.trim()?.length > 0) {
      setFormData({
        ...formData,
        [name]: value,
      })
    } else {
      cancelSearch()
    }
  }

  const filterByValues = (data) => {
    let list = [...data]
    return list.filter(
      (item) =>
        lowerString(item?.profile_name)?.match(lowerString(formData?.query_text)) ||
        lowerString(item?.id)?.match(lowerString(formData?.query_text)) ||
        lowerString(item?.master_agent)?.match(lowerString(formData?.query_text)),
    )
  }
  // disable pagination buttons when no data is there

  useEffect(() => {
    dispatch(getAllCommissionProfiles())
  }, [])
  return (
    <>
      <div className='flex flex-col h-full mb-8'>
        {/* Page BreadCrumbs */}
        <BreadCrumbs
          navList={[
            { title: 'Home', path: '' },
            { title: 'Profile', path: 'agents' },
          ]}
        />
        {/* Page Title */}
        <div className='cs_row items-center '>
          <div className='mr-2'>
            <TabTitle
              title={'Commission Profile'}
              icon={<img src={profileIcon} alt='' width={25} />}
            />
          </div>
          <Link to='addNew' className='ml-auto cs_btn flex items-center '>
            <span className='mr-2 flex items-center justify-center'>
              <img src={plusIcon} alt='' />
            </span>{' '}
            Create Profile
          </Link>
        </div>
        {/* Search Form */}
        {/* ********************** */}
        <div className='flex flex-wrap md:mt-6 mt-2'>
          <form onSubmit={(e) => e.preventDefault()}>
            <label htmlFor='query_text' className='font-medium'>
              Profile ID/ Name/ Agent
            </label>
            <div className='w-full flex items-center'>
              <div className='relative overflow-hidden'>
                <input
                  type='text'
                  id='query_text'
                  name='query_text'
                  value={formData.query_text}
                  onChange={handleForm}
                  className='w-100 border focus:outline-none m-1 p-[2px] rounded pl-3'
                />
                {formData.query_text?.length > 0 && (
                  <span
                    onClick={cancelSearch}
                    tabIndex='0'
                    role='button'
                    className='absolute top-[50%] -translate-y-[50%] right-1 pl-0 pr-2 pt-[8px] pb-[5px] h-[78%] flex justify-center items-center cursor-pointer select-none bg-white border border-l-0'
                  >
                    <CloseIcon />
                  </span>
                )}
              </div>
              <Button
                classes='md:ml-[1rem] my-0 ms-2  bg-purple-500 text-white  flex  bg-purple-500 border-purple-500 rounded cursor-pointer select-none  justify-center min-h-[30px] w-max  max-w-full text-base font-semiBold px-2 py-1 leading-6 my-[6px]   flex items-center'
                style={{ opacity: searchState.loading ? '.8' : '1' }}
              >
                {!searchState.loading ? (
                  <SearchIcon className='me_2' />
                ) : (
                  <div className='me_2'>
                    <Spinner type='spinner' />
                  </div>
                )}
                Search
              </Button>
            </div>
          </form>
        </div>
        {/* Cards Data Table */}
        <div className='mt-7'>
          <div className='md:my-4 border rounded my-2'>
            <CsTable columns={profileColumn} loading={isLoading} tableClasses='header_fill'>
              {!isLoading ? (
                filterByValues(tblData)?.length > 0 ? (
                  filterByValues(tblData).map((item, index) => (
                    <tr key={index}>
                      <td className='text-center'>{index + 1}</td>
                      <td>{item?.id}</td>
                      <td>{item?.profile_name}</td>
                      <td className=''>{item?.master_agent_percent + '%' || '-'}</td>
                      <td className='text-center'>{item?.agent_1_percent + '%' || '-'}</td>
                      <td className='text-center'>
                        {item?.agent_2_percent === null ? '-' : +item?.agent_2_percent + '%'}
                      </td>
                      <td className='text-center'>
                        {item?.last_updated ? formatDateTime(item?.last_updated) : '-'}
                      </td>
                      <td className='text-center'>
                        <Link to={'details/' + item?.id} className='text-blue-400 underline'>
                          Details
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='8'>
                      <div className='my-4 mx-auto text-center'>
                        <p>No records</p>
                      </div>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan='8'>
                    <div className='my-4 mx-auto text-center'>
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}
            </CsTable>
          </div>
        </div>
        {/* Table End */}
        {/* table pagination */}
        <div className='flex flex-wrap w-full justify-end  min-h-[41px] mb-[22px] gap-2  md:min-h-[27px] md:mb-[7px]  '>
          <div className='rounded w-max ml-auto my-2 overflow-hidden flex '>
            <Button
              disabled={isPrevDisabled}
              onClick={() => handlePageChange('backward')}
              classes={`disabled:opacity-50 disabled:cursor-not-allowed rounded-none px-[12px] ${
                // activePagination === "backward"
                // ? "bg-[#01416d] text-white rounded-none"
                'rounded-none'
              }`}
            >
              <PagingDoubleLeftIcon />
            </Button>
            <Button
              disabled={isPrevDisabled}
              onClick={() => handlePageChange('fastBackward')}
              classes={`disabled:opacity-50 disabled:cursor-not-allowed rounded-none  px-[12px] ${'rounded-none'}`}
            >
              <PagingLeftIcon />
            </Button>
            {paginationBtns.map((btn) => {
              return (
                <Button
                  disabled={btn?.disable}
                  onClick={() => handlePageChange(btn.number)}
                  classes={` px-[12px] ${
                    currentPage === btn.number
                      ? 'bg-[#01416d] text-white rounded-none'
                      : 'rounded-none'
                  }`}
                >
                  <span className='font-normal'>{btn.number}</span>
                </Button>
              )
            })}
            <Button
              onClick={() => handlePageChange('forward')}
              disabled={isNextDisabled}
              classes={` px-[12px] ${
                // activePagination === "forward"
                // ? "bg-[#01416d] text-white rounded-none"
                'rounded-none'
              }`}
            >
              <PagingRightIcon />
            </Button>
            <Button
              disabled={isNextDisabled}
              onClick={() => handlePageChange('fastForward')}
              classes={` px-[12px] ${
                // activePagination === "fastForward"
                // ? "bg-[#01416d] text-white rounded-none"
                'rounded-none'
              }`}
            >
              <PagingDoubleRightIcon />
            </Button>
          </div>
          <div className='w-auto  flex items-center justify-center rounded mb-6 md:mb-0'>
            <select onChange={handlePerPageChange} name='' id='' className='border p-1 rounded'>
              <option value='10'>10 / page</option>
              <option value='30'>30 / page</option>
              <option value='60'>60 / page</option>
              <option value='100'>100 / page</option>
            </select>
          </div>
        </div>
      </div>
    </>
  )
}

export default CommisionProfile
