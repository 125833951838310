import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { doRefundPayment } from 'api/RESTful/payment'

import Button from 'utils/Button'

const RequestRefundModal = ({ paymentID, clientName, cardDigits, handleCloseModal }) => {
  const [refundData, setRefundData] = useState({
    bankCardNumber: '',
    bankName: '',
    bankBranch: '',
  })

  const handleChange = (e) => {
    setRefundData({ ...refundData, [e.target.name]: e.target.value })
  }

  const handleSubmit = async () => {
    const req = {
      refund_bank_card_number: refundData.bankCardNumber,
      refund_bank_name: refundData.bankName,
      refund_bank_branch: refundData.bankBranch,
    }

    doRefundPayment(paymentID, req)
      .then((response) => {
        handleCloseModal()
        toast.success(response.data.message, {
          onClose: () => window.location.reload(),
        })
      })
      .catch((error) => {
        toast.error('Cannot refund: ' + error.response.data.message)
      })
  }
  return (
    <div
      className='bg-white text-center fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-[1055] w-max h-max max-h-[calc(100%-100px) ] overflow-x-hidden overflow-y-auto outline-none min-w-[350px] max-w-[830px] 
     w-[390px] rounded md:px-3 md:py-4 px-2 py-3'
    >
      {/* Top Heading */}
      <div className='text-start pb-4 border-b'>
        <h3 className='text-lg font-semibold'>Request Refund</h3>
      </div>
      {/* Inputs */}
      <div className='mx-10 my-2'>
        <div className='mb-14'>
          <div className='flex justify-between mb-1'>
            <span className='text-xs font-normal leading-[19px] text-start w-[150px]'>
              Client Name
            </span>
            <span className='text-xs font-normal w-[100px] text-end pr-4 leading-[19px]'>
              {clientName}
            </span>
          </div>
          {/* ------------------second------------------- */}
          <div className='flex justify-between'>
            <span className='text-xs font-normal leading-[19px] text-start w-[150px]'>
              Last 4 digits of the card
            </span>
            <span className='text-xs font-normal w-[100px] text-end pr-4 leading-[19px]'>
              {cardDigits || 'N/A'}
            </span>
          </div>
        </div>
        {/* --------------third---------------------- */}
        <div className='mb-10'>
          <div className='flex justify-between my-1'>
            <span className='text-xs font-normal leading-[19px] text-start w-[150px]'>
              Bank Card Number
            </span>
            <input
              name='bankCardNumber'
              type='text'
              className='w-[130px] text-end rounded text-xs placeholder:pr-3 focus:outline-gray-300 border border-gray-300'
              onChange={handleChange}
            />
          </div>
          <div className='flex justify-between my-1'>
            <span className='text-xs font-normal leading-[19px] text-start w-[150px]'>
              Bank Name
            </span>
            <input
              name='bankName'
              type='text'
              className='w-[130px] text-end rounded text-xs placeholder:pr-3 focus:outline-gray-300 border border-gray-300'
              onChange={handleChange}
            />
          </div>
          <div className='flex justify-between my-1'>
            <span className='text-xs font-normal leading-[19px] text-start w-[150px]'>
              Bank Branch
            </span>
            <input
              name='bankBranch'
              type='text'
              className='w-[130px] text-end rounded text-xs placeholder:pr-3 focus:outline-gray-300 border border-gray-300'
              onChange={handleChange}
            />
          </div>
        </div>
        {/* Last Text box */}
        <div className='flex flex-col justify-between my-1'>
          <span className='text-xs mb-1 font-normal leading-[19px] text-start w-[150px]'>
            Refund Reason
          </span>

          <textarea
            name=''
            id=''
            cols='60'
            rows='5'
            className='p-2 focus:border-gray-300   focus:outline-none rounded text-xs border border-gray-300'
            placeholder=''
          ></textarea>
        </div>
        {/* Buttons */}
        <div className='flex  justify-center items-center gap-3'>
          <Button className='cs_btn' onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} className='bg-[#625BF6] cs_btn text-white'>
            Confirm
          </Button>
        </div>
        {/* ------------------------------------------------- */}
      </div>
    </div>
  )
}

export default RequestRefundModal
