import { currency } from '../../../../utils/dropdownOptions'
import * as Yup from 'yup'

export const initialValues = {
  transaction_currency: '',
  payin_daily_limit: '',
  payin_currency: '',
  payout_daily_limit: '',
  payout_currency: '',
}

export const schemaObj = {
  transaction_currency: Yup.string().oneOf(currency).required('Required'),
  payin_daily_limit: Yup.string().required('Required'),
  payin_currency: Yup.string().required('Required'),
  payout_daily_limit: Yup.string().required('Required'),
  payout_currency: Yup.string().required('Required'),
}
export const merchantPySettingSchema = Yup.object().shape(schemaObj)
