import axios from 'axios'

const getMerchants = async (take, skip) => {
  const res = await axios.get(`/merchants?take=${take}&skip=${skip}`)
  return res.data
}

const getMerchantDetails = async (id) => {
  const res = await axios.get(`/merchants/${id}/`)
  return res.data
}

const getMerchantFeeSettings = async (id) => {
  const res = await axios.get(`/merchants/${id}/fee-settings`)
  return res.data
}

const getMerchantFeeSettingsAssignments = async (id) => {
  const res = await axios.get(`/merchants/${id}/fee-settings/get-assignments`)
  return res.data
}

const getMerchantPaymentMethods = async (id) => {
  const res = await axios.get(`/merchants/${id}/payment-methods`)
  return res.data
}

const updateMerchantDetails = async (id, req) => {
  return await axios.put(`/merchants/${id}`, req)
}

export {
  getMerchants,
  getMerchantDetails,
  getMerchantFeeSettings,
  getMerchantFeeSettingsAssignments,
  getMerchantPaymentMethods,
  updateMerchantDetails,
}
