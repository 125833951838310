import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getMerchants, getMerchantFeeSettingsAssignments } from 'api/RESTful/merchant'
import { getAgentDetails } from 'api/RESTful/agent'
import {
  getCardDetails,
  updateCardDetails,
  doAssignCardToFee,
  doUnssignCardToFee,
  doForceAssignCardToFee,
} from 'api/RESTful/card'

import leftArrowSvg from 'assets/images/svgs/left-arrow.svg'
import rightArrowSvg from 'assets/images/svgs/right-arrow.svg'

import BreadCrumbs from '../../../features/Dashboard/components/BreadCrumbs'
import TabTitle from '../components/TabTitle'
import cardIcon from 'assets/images/icons/cardIcon.png'

import Button from 'utils/Button'
import CsModal from 'utils/Modal'
import { formatDateTime } from 'utils/formatDate'

const CardAssignment = () => {
  const { id } = useParams()
  const [isSaving, setIsSaving] = useState(false)
  const [cardDetails, setCardDetails] = useState([])
  const [commissionProfile, setCommissionProfile] = useState('')

  const [isLoading, setIsLoading] = useState(true)
  const [merchantDropdownList, setMerchantDropdownList] = useState([])
  const [selectedMerchant, setSelectedMerchant] = useState('')

  const [feeSettings, setFeeSettings] = useState([])
  const [selectedFeeSetting, setSelectedFeeSetting] = useState('')
  const [assignedFeeSettings, setAssignedFeeSettings] = useState([])
  const [unassignedFeeSettings, setUnassignedFeeSettings] = useState([])

  const [modalOpen, setModal] = useState({ open: false, id: '' })

  const fetchCardDetails = () => {
    getCardDetails(id)
      .then((res) => {
        setCardDetails(res)
      })
      .catch((err) => {
        toast.error('Cannot get card details: ' + err.message || 'Cannot get card details')
      })
  }

  const fetchAgentCommissionrofile = (agentId) => {
    getAgentDetails(agentId)
      .then((res) => {
        setCommissionProfile(res.profile_id)
      })
      .catch((err) => {
        toast.error(
          "Cannot get agent's commission profile: " + err.message ||
            "Cannot get agent's commission profile",
        )
      })
  }

  const fetchMerchantDropdownList = () => {
    getMerchants(10000, 0) // FIXME: should not be hardcoded here, should be replaced by total_counts
      .then((res) => {
        setMerchantDropdownList(res.data)
        setIsLoading(false)
      })
      .catch((err) => {
        toast.error('Cannot get merchants list: ' + err.message || 'Cannot get merchants list')
      })
  }

  const fetchFeeSettings = (merchantId) => {
    getMerchantFeeSettingsAssignments(merchantId)
      .then((res) => {
        setFeeSettings(res)
        const unassigned = res.filter((setting) => {
          return (
            setting.cards.length === 0 ||
            setting.cards.some(
              (card) => card.id === id && card.assignment_status === 'UNASSIGNED',
            ) ||
            !setting.cards.some((card) => card.id === id)
          )
        })

        const assigned = res.filter((setting) => {
          return setting.cards.some(
            (card) => card.id === id && card.assignment_status === 'ASSIGNED',
          )
        })

        setUnassignedFeeSettings(unassigned)
        setAssignedFeeSettings(assigned)
      })
      .catch((err) => {
        toast.error(
          'Cannot get fee settings of the merchant: ' + err.message ||
            'Cannot get fee settings of the merchant',
        )
      })
  }

  const moveToAssignedFeeSetting = () => {
    if (selectedFeeSetting) {
      const selectedFee = unassignedFeeSettings.find((item) => item.id === selectedFeeSetting)
      if (selectedFee) {
        setUnassignedFeeSettings((prev) => prev.filter((item) => item.id !== selectedFeeSetting))
        setAssignedFeeSettings((prev) => [...prev, selectedFee])
        setSelectedFeeSetting('')
      }
    }
  }

  const moveToUnassignedFeeSetting = () => {
    if (selectedFeeSetting) {
      const selectedFee = assignedFeeSettings.find((item) => item.id === selectedFeeSetting)
      if (selectedFee) {
        setAssignedFeeSettings((prev) => prev.filter((item) => item.id !== selectedFeeSetting))
        setUnassignedFeeSettings((prev) => [...prev, selectedFee])
        setSelectedFeeSetting('')
      }
    }
  }

  const handleAutoAssign = () => {
    if (!selectedMerchant) {
      toast.error('Please select a merchant')
      return
    } else if (feeSettings.length === 0) {
      toast.error('There are no fees settings in this merchant')
      return
    }
    const feeSettingsData = feeSettings.map((item) => ({
      id: item.id,
      percent: item.percent,
      is_active: item.is_active,
    }))

    const req = {
      fee_settings: [...feeSettingsData],
      card_fee_percent: cardDetails?.payin_card_fees,
      commission_profile_id: commissionProfile,
    }
    const updatedCardDetails = {
      ...cardDetails,
      assignment_status: 'ASSIGNED',
    }

    doAssignCardToFee(id, true, req)
      .then(() => {
        updateCardDetails(id, updatedCardDetails).then(() => {
          toast.success('The auto assignment has been successfully saved')
        })
      })
      .catch((err) => {
        toast.error(
          'Cannot auto assign the fee settings to the card: ' + err.message ||
            'Cannot auto assign the fee settings to the card',
        )
      })
  }

  const handleSave = () => {
    if (!selectedMerchant) {
      toast.error('Please select a merchant')
      return
    } else if (feeSettings.length === 0) {
      toast.error('There are no fee settings in this merchant')
      return
    }
    setIsSaving(true)
    if (assignedFeeSettings.some((item) => !item.is_active)) {
      setModal({ open: true })
    } else {
      const assignedfeeSettingsData = assignedFeeSettings.map((item) => ({
        id: item.id,
        percent: item.percent,
        is_active: item.is_active,
      }))
      const assignReq = {
        fee_settings: [...assignedfeeSettingsData],
        card_fee_percent: cardDetails?.payin_card_fees,
        commission_profile_id: commissionProfile,
      }
      const defaultUnassignedfeeSettingsData = assignedFeeSettings.map((item) => ({
        id: item.id,
      }))
      const defaultUnassignReq = {
        fee_settings: [...defaultUnassignedfeeSettingsData],
        assignment_status: 'ASSIGNED',
      }
      const unassignedfeeSettingsData = unassignedFeeSettings.map((item) => ({
        id: item.id,
      }))
      const unassignReq = {
        fee_settings: [...unassignedfeeSettingsData],
        assignment_status: 'ASSIGNED',
      }
      const updatedCardDetails = {
        ...cardDetails,
        assignment_status: 'ASSIGNED',
      }
      doUnssignCardToFee(id, defaultUnassignReq).then(() => {
        doAssignCardToFee(id, false, assignReq)
          .then(() => {
            doUnssignCardToFee(id, unassignReq).then(() => {
              updateCardDetails(id, updatedCardDetails).then(() => {
                toast.success('The assignment has been successfully saved')
              })
            })
          })
          .catch((err) => {
            toast.error(
              'Cannot assign the fee settings to the card: ' + err.message ||
                'Cannot assign the fee settings to the card',
            )
          })
          .finally(() => {
            setIsSaving(false)
          })
      })
    }
  }

  const handleYesModal = () => {
    const assignedfeeSettingsData = assignedFeeSettings.map((item) => ({
      id: item.id,
      percent: item.percent,
    }))
    const assignReq = {
      fee_settings: [...assignedfeeSettingsData],
    }
    const updatedCardDetails = {
      ...cardDetails,
      assignment_status: 'ASSIGNED',
    }
    doForceAssignCardToFee(id, assignReq)
      .then(() => {
        updateCardDetails(id, updatedCardDetails).then(() => {
          toast.success('The force assignment has been successfully saved')
        })
      })
      .catch((err) => {
        toast.error(
          'Cannot force assign the fee settings to the card: ' + err.message ||
            'Cannot force assign the fee settings to the card',
        )
      })
    setModal({ open: false })
  }

  const handleCloseModal = () => {
    setModal({ open: false, id: '' })
  }

  useEffect(() => {
    fetchCardDetails()
    if (cardDetails.agent_id === undefined) return
    fetchAgentCommissionrofile(cardDetails.agent_id)
  }, [cardDetails.agent_id])

  useEffect(() => {
    fetchMerchantDropdownList()
  }, [])

  useEffect(() => {
    if (selectedMerchant === '') return
    fetchFeeSettings(selectedMerchant)
  }, [selectedMerchant])

  return (
    <>
      <div>
        <BreadCrumbs
          navList={[
            { title: 'Home', path: '/dashboard/all-cards' },
            { title: 'Card details', path: `/dashboard/all-cards/details/${id}` },
            { title: 'Card Assignment', path: '' },
          ]}
        />
        <div className='flex flex-wrap items-start'>
          <div className='flex items-center'>
            <TabTitle title={cardDetails.card_nickname} icon={<img src={cardIcon} alt='' />} />
          </div>

          <div className='ml-auto flex flex-wrap items-end'>
            <div>
              <div className='lg:mr-2 text-gray-500 flex items-center justify-end'>
                <div className=''>
                  <span className='text-sm '> Created at</span>
                </div>
                <div className='min-w-[130px] text-end'>
                  <span className='text-sm'>
                    {cardDetails?.created_at
                      ? formatDateTime(cardDetails.created_at)
                      : 'Not available'}
                  </span>
                </div>
              </div>
              <div className='lg:mr-2 text-gray-500 flex items-center justify-end'>
                <div className=''>
                  <span className='text-sm'> Last modified</span>
                </div>
                <div className='min-w-[130px] text-end'>
                  <span className='text-sm'>
                    {cardDetails?.last_updated
                      ? formatDateTime(cardDetails.last_updated)
                      : 'Not available'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Body */}
        <div className='p-2 rounded border-2 border-gray-200 my-5'>
          <div className='md:px-3 px-2 py-1 mb-3 flex justify-between'>
            <h5 className='m-4 font-semibold text-2xl'>Assignment Setting</h5>
            <div>
              <Button className='cs_btn bg-purple-500 text-white' onClick={handleAutoAssign}>
                Auto Assign
              </Button>
            </div>
          </div>
          {/* ------------- */}
          <div className='flex items-center w-[80%] md:w-[40%] pl-4 gap-2'>
            <label htmlFor='name'>Merchant</label>

            <select
              name='agent_name'
              id='agent_name'
              className='w_100 select_2 cursor_pointer user_select_none w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
              onChange={(e) => {
                setSelectedMerchant(e.target.value)
              }}
              value={selectedMerchant}
            >
              <option value='' disabled>
                Select Merchant
              </option>
              {isLoading ? (
                <option value='' disabled>
                  Loading all merchants
                </option>
              ) : merchantDropdownList.length < 0 ? (
                <option value='' disabled>
                  No merchant found
                </option>
              ) : (
                merchantDropdownList.map((item, ind) => (
                  <option value={item.id} key={item.id || ind}>
                    {item.name}
                  </option>
                ))
              )}
            </select>
          </div>
          {/* --------------- */}
          <div>
            <div className='flex mx-10 md:mx-20 my-8 flex-col md:flex-row'>
              <div className='flex flex-col  gap-2 min-w-[40%]'>
                <span className='text-sm'>Unassign</span>
                <div className='min-w-[40%] border min-h-[300px] rounded p-2'>
                  {unassignedFeeSettings.map(({ percent, id }, ind) => (
                    <div
                      key={id || ind}
                      onClick={() => setSelectedFeeSetting(id)}
                      className={`fee-setting-item ${selectedFeeSetting === id ? 'selected' : ''}`}
                    >
                      <span className='font-semibold' key={id || ind}>
                        {percent + '%'}
                      </span>
                      <br />
                    </div>
                  ))}
                </div>
              </div>

              <div className='flex justify-center items-center'>
                <span
                  className='border m-2 p-1 rounded cursor-pointer'
                  onClick={moveToUnassignedFeeSetting}
                >
                  <img src={leftArrowSvg} alt='' />
                </span>
                <span
                  className='border m-2 p-1 rounded cursor-pointer'
                  onClick={moveToAssignedFeeSetting}
                >
                  <img src={rightArrowSvg} alt='' />
                </span>
              </div>

              <div className='flex flex-col  gap-2 min-w-[40%]'>
                <span className='text-sm'>Assigned</span>
                <div className='min-w-[40%] border min-h-[300px] rounded p-2'>
                  {assignedFeeSettings.map(({ percent, id }, ind) => (
                    <div
                      key={id || ind}
                      onClick={() => setSelectedFeeSetting(id)}
                      className={`fee-setting-item ${selectedFeeSetting === id ? 'selected' : ''}`}
                    >
                      <span className='font-semibold' key={id || ind}>
                        {percent + '%'}
                      </span>
                      <br />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='flex justify-end items-center gap-3 mr-5'>
              <Button className='cs_btn'>Cancel</Button>
              <Button
                className='cs_btn bg-purple-500 text-white'
                onClick={handleSave}
                disabled={isSaving}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* Modal */}
      <CsModal open={modalOpen.open} onClose={handleCloseModal}>
        <div className='bg-white text-center  cs_modal rounded md:px-3 md:py-4 px-2 py-3'>
          <div className='flex content-center items-center my-2'>
            <p className='ml-1'>
              One or more fee settings are inactive. Would you like to force assign the inactive fee
              settings to this card?
            </p>
          </div>
          <div className='my-2 flex flex-wrap justify-center items-center'>
            <button className='cs_btn mx-2' onClick={handleYesModal}>
              Yes
            </button>
            <button className='cs_btn mx-2' onClick={handleCloseModal}>
              Cancel
            </button>
          </div>
        </div>
      </CsModal>
    </>
  )
}

export default CardAssignment
