import React, { useEffect, useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getPaymentDetails, doToggleFreezePayment } from 'api/RESTful/payment'

import refundBtn from 'assets/images/icons/refundBtn.png'
import freezeBtn from 'assets/images/icons/freezeBtn.png'
import plusIcon from 'assets/images/icons/plusIcon.png'
import recieved from 'assets/images/icons/inboxIcon.png'

import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import Table from 'pages/Dashboard/components/Table'
import PaymentConfirmationModal from '../Modals/PaymentConfirmationModal'
import RequestRefundModal from '../Modals/RequestRefundModal'
import AddRefundPaymentRecord from '../Modals/AddRefundPaymentRecord'

import Spinner from '../../../../utils/Spinner'
import Button from 'utils/Button'
import CsModal from 'utils/Modal'
import { CloseIcon } from 'utils/Icons'
import { formatDateTime } from 'utils/formatDate'
import { formatAmount } from 'utils/formatAmount'

// FIXME: move this to constat json data later
const ordersTableColumns = [
  { name: 'Order ID' },
  { name: 'Type' },
  { name: 'Currency' },
  { name: 'Amount' },
  { name: 'Status' },
  { name: 'Last Updated' },
]

const PaymentDetails = () => {
  const { paymentID } = useParams()
  const [paymentDetails, setPaymentDetails] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const handleFreezePayment = () => {
    setIsLoading(true)
    doToggleFreezePayment(paymentID)
      .then((response) => {
        toast.success(response.data.message, {
          onClose: () => window.location.reload(),
        })
      })
      .catch((err) => {
        toast.error(err.message)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const [isRequestRefundModalOpen, setIsRequestModalOpen] = useState({ open: false })
  const handleCloseRequestRefundModal = () => {
    setIsRequestModalOpen({ open: false })
  }

  const [isAddRefundPaymentModalOpen, setIsAddRefundPaymentModalOpen] = useState({ open: false })
  const handleCloseAddRefundPaymentModal = () => {
    setIsAddRefundPaymentModalOpen({ open: false })
  }

  const [modalOpen, setModal] = useState({ open: false })
  const handleCloseModal = () => {
    setModal({ open: false })
  }

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await getPaymentDetails(paymentID)
        setPaymentDetails(response)
      } catch (error) {
        toast.error(error)
      }
    }
    fetchPaymentDetails()
  }, [paymentID])
  const renderAttachmentSection = () => {
    const hasAttachment = paymentDetails?.reciept_url || paymentDetails?.proof_of_payment_url

    if (!hasAttachment) {
      return (
        <div className='attachment-section '>
          <h5 className='text-2xl font-semibold mt-4'>Attachment</h5>
          <p>No attachments available</p>
        </div>
      )
    }

    return (
      <div className='attachment-section'>
        <h5 className='text-2xl font-semibold'>Attachment</h5>
        {paymentDetails?.reciept_url && (
          <div className='attachment-item'>
            <img src={paymentDetails.reciept_url} alt='Receipt' className='attachment-image' />
          </div>
        )}
        {paymentDetails?.proof_of_payment_url && (
          <div className='attachment-item'>
            <img
              src={paymentDetails.proof_of_payment_url}
              alt='Proof of Payment'
              className='attachment-image'
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div>
      {/* BreadCrumbs */}
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '/dashboard' },
          { title: 'Admin', path: '/dashboard/payment' },
          { title: 'Payment Details', path: '' },
        ]}
      />
      {/* Top Details */}
      <div>
        <h5 className='text-gray-400 font-normal text-2xl'>PAYMENT# {paymentDetails?.id}</h5>
        <div className='cs_row'>
          <div className='flex mr-2 flex-wrap items-center'>
            <h4 className='font-normal mr-2 text-3xl'>
              <span className='text-gray-400'>{paymentDetails?.currency} </span>
              {` `}
              {formatAmount(paymentDetails?.order_amount)}
            </h4>
            <p
              className='bg-[#D9D9D9] px-2 py-1 rounded text-gray-400'
              style={{ fontSize: '13px' }}
            >
              {paymentDetails?.status}
            </p>
          </div>
          <div className='ml-auto flex items-center'>
            {paymentDetails?.status === 'PROCESSING' && paymentDetails?.type === 'DEPOSIT' && (
              <>
                <Button onClick={() => setModal({ open: true })} classes='mr-2 px-2'>
                  <span className='mr-2 flex items-center'>
                    <img src={recieved} alt='' />
                  </span>
                  Receive
                </Button>
                <Button classes='px-2'>
                  <span className='mr-2 flex items-center'>
                    <CloseIcon />
                  </span>
                  Cancel
                </Button>
              </>
            )}
            {paymentDetails?.status === 'SUCCESSFUL' && (
              <>
                <Button onClick={() => setIsRequestModalOpen({ open: true })} ƒclasses='mr-2 px-2'>
                  <span className='mr-2 flex items-center'>
                    <img src={refundBtn} alt='' />
                  </span>
                  Refund
                </Button>
                <Button onClick={() => handleFreezePayment()} classes='px-2'>
                  <span className='mr-2 flex items-center'>
                    <img src={freezeBtn} alt='' />
                  </span>
                  {isLoading ? 'Processing...' : 'Freeze'}
                </Button>
              </>
            )}
            {paymentDetails?.status === 'FROZEN' && (
              <>
                <Button onClick={() => handleFreezePayment()} classes='px-2'>
                  <span className='mr-2 flex items-center'>
                    <img src={freezeBtn} alt='' />
                  </span>
                  {isLoading ? 'Processing...' : 'Unfreeze'}
                </Button>
              </>
            )}
            {paymentDetails?.status === 'PROCESSING' && paymentDetails?.type === 'PAYOUT' && (
              <>
                <Button
                  onClick={() => setIsAddRefundPaymentModalOpen({ open: true })}
                  classes='mr-2 px-2'
                >
                  <span className='mr-2 flex items-center'>
                    <img src={recieved} alt='' />
                  </span>
                  Add Payment Record
                </Button>
                <Button onClick={() => handleFreezePayment()} classes='px-2'>
                  <span className='mr-2 flex items-center'>
                    <img src={freezeBtn} alt='' />
                  </span>
                  {isLoading ? 'Processing...' : 'Freeze'}
                </Button>
              </>
            )}
          </div>
        </div>
      </div>
      {/* Top Table */}
      {/* *********************** */}
      <div className='my-6 cs_row py-3 border-t-2 border-gray-400'>
        <div className='md:pr-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Last updated</span>
          <p className='font-medium'>{formatDateTime(paymentDetails.last_updated)}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Merchant</span>
          <p className='text-[#499CD6] font-medium'>{paymentDetails?.merchant_id}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Customer</span>
          <p className='text-[#499CD6] font-medium'>{paymentDetails?.customer_name}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Type</span>
          <p className='font-medium'>{paymentDetails?.type}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Agent</span>
          <p className='text-[#499CD6] font-medium'>{paymentDetails?.agent_id}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Card</span>
          <p className='text-[#499CD6] font-medium'>
            <Link
              to={`/dashboard/all-cards/details/${paymentDetails?.card_id}`}
              className='text-[#499CD6] font-medium'
            >
              {paymentDetails?.card_id}
            </Link>
          </p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Transfer note</span>
          <p className='font-medium'>{paymentDetails?.transfer_note}</p>
        </div>
      </div>
      {/* TimeLine */}
      {/* Timeline FIXME: Change back the div to Table */}
      <div className='my-3 py-3 '>
        <div className='flex items-center justify-between'>
          <h5 className='text-2xl font-semibold'>Timeline</h5>
          <Button>
            <span className='px-2'>
              <img src={plusIcon} alt='' />
            </span>
            Add Note
          </Button>
        </div>
        <div className='mb-1 cs_row py-3 border-t-2 border-b-2'>
          <div className='flex w-full'>
            <div className='md:pr-12'>
              <span className='text-gray-400'>Time</span>
            </div>
            <div className='mdpl-4 px-24'>
              <span className='text-gray-400'>Event</span>
            </div>
          </div>
          {paymentDetails?.timeline && paymentDetails.timeline.length > 0 ? (
            paymentDetails.timeline
              .sort((a, b) => b.id - a.id)
              .map((item) => (
                <div key={item.id} className='flex w-full'>
                  <div className='md:pr-12'>
                    <p className='font-normal md:mt-2 mt-1'>{formatDateTime(item.time)}</p>
                  </div>
                  <div className='mdpl-4 px-2'>
                    <p className='font-normal md:mt-2 mt-1'>{item.event}</p>
                  </div>
                </div>
              ))
          ) : (
            <div className='flex w-full'>
              <div className='md:pr-12'>
                <p className='font-normal md:mt-2 mt-1'>-</p>
              </div>
              <div className='mdpl-4 px-2'>
                <p className='font-normal md:mt-2 mt-1'>No events yet</p>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='mt-8'>
        <div className='flex items-center justify-between'></div>
        {/* //FIXME: this should be open when the user clicks on the add note button
        <div className='form_group'>
          <textarea
            className='w-100 py-2'
            name='note'
            id='note'
            cols='150'
            rows='5'
            placeholder='Add a note...'
          ></textarea>
        </div>  
        <div className='cs_row justify-end'>
          <Button classes='mr-2'>Cancel</Button>
          <Button classes='btn_primary1'>Add note</Button>
        </div> */}
      </div>

      {/* Orders Details FIXME: column should be responsive, style for the table */}
      <div className='my-3'>
        <h5 className='text-2xl font-semibold'>Order Details</h5>
        <Table columns={ordersTableColumns}>
          {paymentDetails.order && paymentDetails.order.length > 0 ? (
            paymentDetails.order.map((orderItem) => (
              <tr key={orderItem.id}>
                <td>
                  <Link
                    to={`/dashboard/orders/details/${orderItem.id}`}
                    className='text-start text-[#499CD6]'
                  >
                    {orderItem.id}
                  </Link>
                </td>
                <td>{orderItem.type}</td>
                <td>{orderItem.currency}</td>
                <td>{orderItem.order_amount}</td>
                <td>{orderItem.status}</td>
                <td>{formatDateTime(orderItem.last_updated)}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={5}>No order details available</td>
            </tr>
          )}
        </Table>
      </div>

      {/* Attachment Section */}
      {renderAttachmentSection()}

      <CsModal open={modalOpen.open} onClose={handleCloseModal}>
        <PaymentConfirmationModal
          paymentID={paymentID}
          orderAmount={paymentDetails?.order_amount}
          reciept_url={paymentDetails?.reciept_url}
          handleCloseModal={handleCloseModal}
        />
      </CsModal>
      <CsModal open={isRequestRefundModalOpen.open} onClose={handleCloseRequestRefundModal}>
        <RequestRefundModal
          paymentID={paymentID}
          orderAmount={paymentDetails?.order_amount}
          clientName={paymentDetails?.customer_name}
          cardDigits={paymentDetails?.last_four_card_digits}
          handleCloseModal={handleCloseRequestRefundModal}
        />
      </CsModal>
      <CsModal open={isAddRefundPaymentModalOpen.open} onClose={handleCloseAddRefundPaymentModal}>
        <AddRefundPaymentRecord
          paymentID={paymentID}
          orderAmount={paymentDetails?.order_amount}
          clientName={paymentDetails?.customer_name}
          cardDigits={paymentDetails?.last_four_card_digits}
          handleCloseModal={handleCloseAddRefundPaymentModal}
        />
      </CsModal>
    </div>
  )
}

export default PaymentDetails
