import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllProfiles } from 'api/api'

export const getAllCommissionProfiles = createAsyncThunk(
  'profiles/getAllProfiles',
  async ({ query, itemsPerPage, skip }) => {
    return await getAllProfiles(query || '', itemsPerPage || '', skip || '')
  },
)

const initialState = {
  data: [],
  isLoading: true,
}

export const allProfilesSlice = createSlice({
  name: 'profiles',
  initialState,
  reducers: {
    setAllProfiles: (state, action) => {
      state.data = action.payload.data
      state.isLoading = action.payload.isLoading
    },
  },
  extraReducers: {
    [getAllCommissionProfiles.pending]: (state) => {
      state.isLoading = true
    },
    [getAllCommissionProfiles.fulfilled]: (state, action) => {
      state.data = action.payload || []
      state.isLoading = false
    },
    [getAllCommissionProfiles.rejected]: (state) => {
      state.data = []
      state.isLoading = false
    },
  },
})

export const { setAllProfiles } = allProfilesSlice.actions

export default allProfilesSlice.reducer
