import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  data: {},
  isLoading: true,
  isAuthenticated: false,
}

export const authReducerSlice = createSlice({
  name: 'userAuthSlice',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      state.data = action.payload.data
      state.isAuthenticated = action.payload.isAuthenticated
      state.isLoading = action.payload.isLoading
    },
  },
})

export const { setAuth } = authReducerSlice.actions

export default authReducerSlice.reducer
