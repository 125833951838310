export const phoneRegExp = /^\+[\d]{1,4}-[\d]{7,14}$/

export const settlement_frequency = ['WEEKLY', 'DAILY', 'MONTHLY']
export const days = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY']

export const dates = () => {
  let d = []
  for (let i = 0; i < 31; i++) {
    d.push((i + 1).toString())
  }
  return d
}

export const currency = ['CNY']

export const merchant_service = ['P2P']
export const agent_service = ['P2P']

export const merchant_status = ['ACTIVE', 'INACTIVE', 'WAITING_FOR_APPROVAL']

// export const card_status = ["ACTIVE", "INACTIVE", "FREEZE"];
export const card_assignment = ['ASSIGNED', 'UNASSIGNED']

export const fx_rate_status = ['ACTIVE', 'INACTIVE']

export const order_status = ['SUCCESS', 'PENDING', 'FAILED', 'CANCEL', 'PROCESSING', 'REFUND']

export const payment_status = ['SUCCESS', 'PROCESSING', 'FAILED']

export const transaction_type = ['DEPOSIT', 'WITHDRAW']

export const wallet_type = ['MERCHANT', 'AGENT']

export const payment_method_type = ['WALLET']

export const settlement_type = ['MANUAL', 'SCHEDULED']

export const merchant_user_role = ['MERCHANT_ADMIN']

export const admin_user_role = ['SUPER_ADMIN']

export const commission_level = ['T1', 'T2']

export const source = [
  {
    title: 'Employee Referral',
    value: 'EMPLOYEE_REFFERRAL',
  },
  {
    title: 'Website',
    value: 'WEBSITE',
  },
]
export const status = ['Active', 'Inactive']
export const card_status = ['ACTIVE', 'INACTIVE']

//const card_status = ['ACTIVE', 'INACTIVE', 'FREEZE', 'SUSPENDED']

export const wallet_status = ['ACTIVE', 'INACTIVE', 'FREEZE']

export const fee_status = ['VOID', 'SUCCESSFUL', 'REFUND']

export const fee_type = ['COMMISSION', 'FEE']

export const card_type = ['DP', 'WD', 'DP_WD']

export const card_wallet_type = ['CARD', 'E_WALLET']
