import React, { useEffect, useState } from 'react'
import BreadCrumbs from '../../../features/Dashboard/components/BreadCrumbs'
import TabTitle from '../components/TabTitle'
import {
  CloseIcon,
  IndicatorIcon,
  PagingDoubleLeftIcon,
  PagingDoubleRightIcon,
  PagingLeftIcon,
  PagingRightIcon,
  SearchIcon,
  UserGroupIcon,
} from 'utils/Icons'
import { Link } from 'react-router-dom'
import plusIcon from 'assets/images/icons/plusIcon.png'
import Spinner from 'utils/Spinner'
import Button from 'utils/Button'
import { useSortableData } from '../components/useSortableData'
import { lowerString } from 'utils/helpers'
import moment from 'moment'
import { useDispatch, useSelector } from 'react-redux'
import { getUserData } from 'store/reducers/UserReducer'
import { formatDateTime } from 'utils/formatDate'
const User = () => {
  const dispatch = useDispatch()
  const { data: userData, isLoading } = useSelector((store) => store.user)
  const agentColumn = [
    { title: 'id', name: 'User ID', sort: true },
    { title: 'first_name', name: 'Name', sort: true },
    { title: 'email', name: 'Email', sort: true },
    { title: 'phone', name: 'Phone', sort: true },
    { title: 'role', name: 'Role', sort: true },
    { title: 'is_active', name: 'Status', sort: true },
    { title: 'last_updated', name: 'Last Updated', sort: true },
    { name: 'Action' },
  ]

  const [searchState, setSearchState] = useState({
    loading: false,
    data: [],
    searching: false,
  })
  const [formData, setFormData] = useState({
    query_text: '',
  })

  const { items: tblData, CsTable } = useSortableData(
    searchState.searching ? searchState.data : userData,
    agentColumn,
  )
  // buttons pagination
  const [paginationBtns, setPaginationBtns] = useState([
    { disable: false, number: 1 },
    { disable: false, number: 2 },
    { disable: false, number: 3 },
    { disable: false, number: 4 },
    { disable: false, number: 5 },
  ])
  // .................................
  const [totalPages, setTotalPages] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [currentPage, setCurrentPage] = useState(1)
  const [isNextDisabled, setIsNextDisabled] = useState(false)
  const [isPrevDisabled, setIsPrevDisabled] = useState(true)

  const handlePerPageChange = (e) => {
    const newItemsPerPage = parseInt(e.target.value, 10)
    setItemsPerPage(newItemsPerPage)
    setCurrentPage(1) // Reset to the first page when itemsPerPage changes
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
    if (page === 'forward' || page === 'fastForward') {
      setCurrentPage(currentPage + 1)
    }
    if (page === 'backward' || page === 'fastBackward') {
      setCurrentPage(currentPage - 1)
    }
  }
  //  function for disabling the pagination btns when no data is there in the next page
  useEffect(() => {
    let btns = [...paginationBtns]
    if (tblData && !isLoading) {
      let totalItems = tblData?.length
      if (totalItems < itemsPerPage) {
        let changedButtons = btns?.map((btn) => {
          return {
            ...btn,
            disable: btn.number > currentPage,
          }
        })
        if (currentPage === 1) {
          setIsPrevDisabled(true)
        }
        setIsPrevDisabled(false)
        setIsNextDisabled(true)
        return setPaginationBtns(changedButtons)
      }
      if ((totalItems = itemsPerPage)) {
        let changedButtons = btns?.map((btn) => {
          return {
            ...btn,
            disable: btn.number < currentPage,
          }
        })
        if (currentPage === 1) {
          setIsPrevDisabled(true)
        }
        setIsNextDisabled(false)
        return setPaginationBtns(changedButtons)
      }
    }
  }, [tblData.length])

  useEffect(() => {
    const skip = (currentPage - 1) * itemsPerPage

    const take = itemsPerPage

    dispatch(getUserData({ query: '', take, skip }))
    setIsNextDisabled(currentPage === totalPages || isLoading)
    setIsPrevDisabled(currentPage === 1 || isLoading)
  }, [dispatch, itemsPerPage, currentPage])

  useEffect(() => {
    const skip = (currentPage - 1) * itemsPerPage
    dispatch(getUserData({ query: '', itemsPerPage, skip }))
  }, [dispatch, itemsPerPage, currentPage])
  // redux

  // it will handle search api request
  const searchTblData = (e) => {
    e.preventDefault()
  }

  // cancel search
  const cancelSearch = () => {
    setFormData({ ...formData, query_text: '' })
    setSearchState({ data: [], loading: false, searching: false })
  }

  // search form handler
  const handleForm = (e) => {
    const { name, value } = e.target
    if (value && value.trim()?.length > 0) {
      setFormData({
        ...formData,
        [name]: value,
      })
    } else {
      cancelSearch()
    }
  }

  useEffect(() => {
    dispatch(getUserData())
  }, [])

  const filterByValues = (data) => {
    let list = [...data]
    return list.filter(
      (item) =>
        lowerString(item?.name)?.match(lowerString(formData?.query_text)) ||
        lowerString(item?.id)?.match(lowerString(formData?.query_text)) ||
        lowerString(item?.email)?.match(lowerString(formData?.query_text)),
    )
  }

  return (
    <>
      <div className='flex flex-col h-full'>
        {/* Page BreadCrumbs */}
        <BreadCrumbs
          navList={[
            { title: 'Home', path: '' },
            { title: 'User', path: '' },
          ]}
        />
        {/* Page Title */}
        <div className='cs_row items-center'>
          <div className='mr-2'>
            <TabTitle title={'User'} icon={<UserGroupIcon fill='' width={'100'} height={'50'} />} />
          </div>
          <Link to='addNew' className='ml-auto cs_btn flex items-center'>
            <span className='mr-2 flex items-center justify-center'>
              <img src={plusIcon} alt='' />
            </span>{' '}
            Create user
          </Link>
        </div>
        {/* Search Form */}
        <div className='flex flex-wrap md:mt-6 mt-2'>
          <form onSubmit={searchTblData}>
            <label htmlFor='query_text' className='font-medium'>
              User ID/ Name/ Email
            </label>
            <div className='w-full flex items-center'>
              <div className='relative overflow-hidden'>
                <input
                  type='text'
                  id='query_text'
                  name='query_text'
                  value={formData.query_text}
                  onChange={handleForm}
                  className='w-100 border focus:outline-none m-1 p-[2px] rounded pl-3'
                />
                {formData.query_text?.length > 0 && (
                  <span
                    onClick={cancelSearch}
                    tabIndex='0'
                    role='button'
                    className='absolute top-[50%] -translate-y-[50%] right-1 pl-0 pr-2 pt-[8px] pb-[5px] h-[78%] flex justify-center items-center cursor-pointer select-none bg-white border border-l-0'
                  >
                    <CloseIcon />
                  </span>
                )}
              </div>
              <Button
                classes='md:ml-[1rem] my-0 ms-2  bg-purple-500 text-white  flex  bg-purple-500 border-purple-500 rounded cursor-pointer select-none  justify-center min-h-[30px] w-max  max-w-full text-base font-semiBold px-2 py-1 leading-6 my-[6px]   flex items-center'
                style={{ opacity: searchState.loading ? '.8' : '1' }}
              >
                {!searchState.loading ? (
                  <SearchIcon className='me_2' />
                ) : (
                  <div className='me_2'>
                    <Spinner type='spinner' />
                  </div>
                )}
                Search
              </Button>
            </div>
          </form>
        </div>
        {/* Agent Data Table */}
        <div>
          <div className='md:my-4 border rounded my-2'>
            <CsTable
              columns={agentColumn}
              loading={isLoading}
              tableClasses='header_fill'
              hideScrollbar
            >
              {!isLoading ? (
                filterByValues(tblData)?.length > 0 ? (
                  filterByValues(tblData).map((item, index) => (
                    <tr key={index}>
                      <td>
                        <div className='text-center'>{item?.id}</div>
                      </td>
                      <td>
                        <div className='text-center'>{item?.first_name}</div>
                      </td>
                      <td>
                        <div className='text-center'>{item?.email}</div>
                      </td>
                      <td>
                        <div className='text-center'>{item?.phone}</div>
                      </td>
                      <td>
                        <div className='text-center'>{item?.role}</div>
                      </td>
                      <td className='flex justify-center items-center'>
                        <div className='flex items-center justify-start'>
                          <span className='mr-2'>
                            <IndicatorIcon type={item?.is_active ? 'active' : 'inactive'} />
                          </span>
                          <p className='text_sm'>{item?.is_active ? 'Active' : 'Inactive'}</p>
                        </div>
                      </td>
                      <td className='text-center'>
                        {item.last_updated ? formatDateTime(item.last_updated) : 'N/A'}
                      </td>
                      <td className='text-center'>
                        <Link to={'details/' + item?.id} className='px-2 text-blue-400 underline'>
                          Details
                        </Link>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan='8'>
                      <div className='my-4 mx-auto text-center'>
                        <p>No records</p>
                      </div>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td colSpan='8'>
                    <div className='my-4 mx-auto text-center'>
                      <Spinner />
                    </div>
                  </td>
                </tr>
              )}
            </CsTable>
          </div>
        </div>
        {/* table pagination */}
        <div className='flex flex-wrap w-full justify-end  min-h-[41px] mb-[22px] gap-2  md:min-h-[27px] md:mb-[7px]'>
          <div className='rounded w-max ml-auto my-2 overflow-hidden flex '>
            <Button
              disabled={isPrevDisabled}
              onClick={() => handlePageChange('backward')}
              classes={`  px-[12px] disabled:opacity-50 disabled:cursor-not-allowed rounded-none ${'rounded-none'}`}
            >
              <PagingDoubleLeftIcon />
            </Button>
            <Button
              disabled={isPrevDisabled}
              onClick={() => handlePageChange('fastBackward')}
              classes={`  px-[12px] disabled:opacity-50 disabled:cursor-not-allowed rounded-none ${'rounded-none'}`}
            >
              <PagingLeftIcon />
            </Button>
            {paginationBtns.map((btn) => (
              <Button
                disabled={btn?.disable}
                key={btn.number}
                onClick={() => handlePageChange(btn.number)}
                classes={`  px-[12px] ${
                  currentPage === btn.number
                    ? 'bg-[#01416d] text-white rounded-none'
                    : 'rounded-none'
                }`}
              >
                <span className='font-normal'>{btn.number}</span>
              </Button>
            ))}
            <Button
              disabled={isNextDisabled}
              onClick={() => handlePageChange('forward')}
              classes={`  px-[12px] ${'rounded-none'}`}
            >
              <PagingRightIcon />
            </Button>
            <Button
              disabled={isNextDisabled}
              onClick={() => handlePageChange('fastForward')}
              classes={`  px-[12px] ${'rounded-none'}`}
            >
              <PagingDoubleRightIcon />
            </Button>
          </div>
          <div className='w-auto  flex items-center justify-center rounded my-2 '>
            <select onChange={handlePerPageChange} name='' id='' className='border p-1 rounded'>
              <option value='10'>10 / page</option>
              <option value='30'>30 / page</option>
              <option value='60'>60 / page</option>
              <option value='100'>100 / page</option>
            </select>
          </div>
        </div>
        {/* *************End*************** */}
      </div>
    </>
  )
}

export default User
