import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getAllUsers } from 'api/api'

export const getUserData = createAsyncThunk(
  'user/getUserData',
  async ({ query, itemsPerPage, skip }) => {
    return await getAllUsers(query || '', itemsPerPage || '', skip || '')
  },
)
const initialState = {
  data: [],
  isLoading: true,
}

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAllUser: (state, action) => {
      state.data = action.payload.data
      state.isLoading = action.payload.isLoading
    },
  },
  extraReducers: {
    [getUserData.pending]: (state) => {
      state.isLoading = true
    },
    [getUserData.fulfilled]: (state, action) => {
      state.data = action.payload || []
      state.isLoading = false
    },
    [getUserData.rejected]: (state) => {
      state.data = []
      state.isLoading = false
    },
  },
})
export const { setAllUser } = UserSlice.actions

export default UserSlice.reducer
