import { configureStore } from '@reduxjs/toolkit'
import authReducer from './reducers/authReducerSlice'
import merchantReducer from './reducers/merchantReducerSlice'
import loadingReducer from './reducers/loadingReducerSlice'
import agentReducerSlice from './reducers/agentReducerSlice'
import allProfilesSlice from './reducers/profilesReducerSlice'
import cardsReducersSlice from './reducers/cardsReducersSlice'
import UserReducer from './reducers/UserReducer'
import paymentReducer from './reducers/paymentReducer'
import orderReducer from './reducers/orderReducer'
import walletReducer from './reducers/walletSlice'
//
export const store = configureStore({
  reducer: {
    auth: authReducer,
    merchant: merchantReducer,
    agent: agentReducerSlice,
    user: UserReducer,
    orders: orderReducer,
    payments: paymentReducer,
    allCards: cardsReducersSlice,
    profiles: allProfilesSlice,
    loading: loadingReducer,
    wallet: walletReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})
