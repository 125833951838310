import { ErrorMessage, Field } from 'formik'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'utils/Button'

const ProfileFormWrapper = ({ isSubmitting, handleChange, values, errors, touched, type, id }) => {
  const navigate = useNavigate()
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }
  return (
    <div className='border rounded mb-3 mt-4 md:mt-9'>
      <div className={`md:px-3 px-2 py-1 ${type === 'add' ? 'md:mb-3 mb-2' : ''}`}>
        <div className='md:mt-2'>
          {/* 1 */}
          <div className='flex flex-wrap md:my-0 my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='profile_name'>Profile Name</label>
            </div>
            <div className='col_ col_md_4 col_12 flex item-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='profile_name'
                  id='profile_name'
                  placeholder='Profile Name'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  onKeyDown={preventMinus}
                  min='0'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='profile_name'
                component='p'
                className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>

          {/* 2 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='master_agent'>Master Agent</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='master_agent_percent'
                  id='phone'
                  placeholder='0.00%'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500 text-end disabled:bg-[#C4C4C4] disabled:cursor-not-allowed'
                  onChange={handleChange}
                  disabled={!values.profile_name}
                  onKeyDown={preventMinus}
                  min='0'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='master_agent_percent'
                component='p'
                className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 3 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='agent1'> Agent 1</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='agent_1_percent'
                  id='agent1percent'
                  placeholder='0.00%'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500 text-end disabled:bg-[#C4C4C4] disabled:cursor-not-allowed'
                  onChange={handleChange}
                  disabled={!values.master_agent_percent}
                  onKeyDown={preventMinus}
                  min='0'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='agent_1_percent'
                component='p'
                className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 4 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='agent2'>Agent 2</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='agent_2_percent'
                  id='phone'
                  placeholder='0.00%'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500 text-end disabled:bg-[#C4C4C4] disabled:cursor-not-allowed'
                  onChange={handleChange}
                  disabled={!values.agent_1_percent}
                  onKeyDown={preventMinus}
                  min='0'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='agent_2_percent'
                component='p'
                className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
        </div>
        {type === 'edit' ? (
          <>
            <div className='md:my-3 flex justify-end items-center my-2'>
              <Button
                disabled={isSubmitting}
                onClick={() => navigate('/dashboard/commision-profile')}
                classes='mx-1'
                type='button'
              >
                Cancel
              </Button>
              <Button
                disabled={isSubmitting}
                classes='text-white ml-1 bg-purple-500 border-none rounded'
                type='submit'
              >
                {isSubmitting ? 'Saving..' : 'Save'}
              </Button>
            </div>
          </>
        ) : (
          <div className='md:my-3 flex justify-center items-center my-2'>
            <Button
              disabled={isSubmitting}
              onClick={() => navigate('/dashboard/commision-profile')}
              classes='mx-1'
              type='button'
            >
              Cancel
            </Button>
            <Button
              disabled={isSubmitting}
              classes='text-white ml-1 bg-purple-500 border-none rounded'
              type='submit'
            >
              {isSubmitting ? 'Creating..' : 'Create'}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ProfileFormWrapper
