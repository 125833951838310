// import { v4 } from 'uuid';

// check all the roles are available or not
var containsAllRoles = (rolesWeWantToCheck, rolesUserHave) => {
  return rolesWeWantToCheck?.every((i) => rolesUserHave?.includes(i?.toUpperCase()))
}

// email validator function
const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

//
const generateUID = () => {
  // I generate the UID from two parts here
  // to ensure the random number provide enough bits.
  var firstPart = (Math.random() * 46656) | 0
  var secondPart = (Math.random() * 46656) | 0
  firstPart = ('000' + firstPart.toString(36)).slice(-3)
  secondPart = ('000' + secondPart.toString(36)).slice(-3)
  return firstPart + secondPart
}

//
const addMinutes = (minutes) => {
  return new Date(new Date().getTime() + minutes * 60000)
}

// Format numbers with commas
export const numberWithCommas = (x) => {
  return new Intl.NumberFormat('en-IN', {}).format(parseInt(x?.toString().replace(/\D/g, '')) || 0)
}

// Create our number formatter.

export const numberFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
})

//
export const priceFormatter = (val) => {
  if (!val) return val
  return numberFormatter.format(val)?.replace('$', '')
}

// Formate 1st letter of string into capitalize
export const capitalFirstLetter = (str = '') => {
  return str?.charAt(0)?.toUpperCase() + str?.slice(1)?.toLowerCase()
}

// convert string into capitalize
export const capitalizeString = (str = '') => {
  return str?.toUpperCase()
}

// convert string into lowercase
export const lowerString = (str = '') => {
  return str?.toString()?.toLocaleLowerCase()
}

// get phone from a string
export const getPhoneCode = (str) => {
  let val = Array.from(new Set(str?.split('-')))?.toString()
  return /[^,]*/.exec(val)[0]
}

// formate text for phone number field
export const showPhone = (code, number) => {
  let list = Array.from(new Set(number?.split('-')))
  let oldCode = list[0]?.toString() || ''
  let val = list[1]?.toString() || ''
  let phone_code = code || getPhoneCode(number || '')
  return phone_code + '-' + val?.replaceAll(code + '-', '')?.replaceAll(oldCode + '-', '')
}

// export const randomId = () => {
//   return v4();
// }

export { containsAllRoles, validateEmail, generateUID, addMinutes }
