import React from 'react'
import cross from 'assets/images/svgs/multiply.svg'

const CancelCheckout = () => {
  return (
    <>
      <div className='flex justify-center items-center min-h-[100vh]'>
        <div className='flex flex-col gap-5 items-center justify-center'>
          <h1 className='flex items-center gap-3 text-5xl font-bold'>
            <img src={cross} alt='' /> <span>您的订单已取消</span>
          </h1>
          <h2 className='text-4xl font-normal'>如却重新付款，请到商户页面重新下单</h2>
          <h2 className='text-[#CCCCCC] text-4xl font-normal mt-6'>3秒后跳转返回商户 (3)</h2>
        </div>
      </div>
    </>
  )
}

export default CancelCheckout
