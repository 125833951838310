import React from 'react'
import {
  Merchant,
  Agent,
  CommissionProfile,
  Card,
  Order,
  Payment,
  User,
  Wallet,
  Payout,
  ChangePassword,
  Search,
} from 'assets/Icons'

const icons = {
  merchantNavBar: <Merchant />,
  agentNavBar: <Agent />,
  commissionProfileNavBar: <CommissionProfile />,
  cardNavBar: <Card />,
  orderNavBar: <Order />,
  paymentNavBar: <Payment />,
  userNavBar: <User />,
  walletNavBar: <Wallet />,
  payoutNavBar: <Payout />,
  changePasswordNavBar: <ChangePassword />,
  search: <Search />,
}

const Icon = ({ name, size }) => {
  const IconSvg = icons[name]
  if (!IconSvg) return null
  const IconElement = React.cloneElement(IconSvg, { width: size, height: size })
  return IconElement
}

export default Icon
