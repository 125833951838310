import React, { useState, useRef } from 'react'
import { toast } from 'react-toastify'

import { doAddRefundPaymentRecord } from 'api/RESTful/payment'

import Button from 'utils/Button'
import { formatAmount } from 'utils/formatAmount'

const AddRefundPaymentRecord = ({
  paymentID,
  orderAmount,
  clientName,
  cardDigits,
  handleCloseModal,
}) => {
  const [refundData, setRefundData] = useState({
    bankCardNumber: '',
    bankName: '',
    bankBranch: '',
  })
  const [selectedFile, setSelectedFile] = useState(null)
  const fileInputRef = useRef(null)
  const handleChange = (e) => {
    setRefundData({ ...refundData, [e.target.name]: e.target.value })
  }

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0])
  }

  const handleFileDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files && files.length > 0) {
      setSelectedFile(files[0])
    }
  }

  const openFileSelector = () => {
    fileInputRef.current.click()
  }

  const handleSubmit = async () => {
    const formReq = new FormData()
    formReq.append('refund_bank_card_number', refundData.bankCardNumber)
    formReq.append('refund_bank_name', refundData.bankName)
    formReq.append('refund_bank_branch', refundData.bankBranch)
    if (selectedFile) {
      formReq.append('file', selectedFile, selectedFile.name)
    }

    doAddRefundPaymentRecord(paymentID, formReq)
      .then((response) => {
        handleCloseModal()
        toast.success(response.data.message, {
          onClose: () => window.location.reload(),
        })
      })
      .catch((error) => {
        toast.error('Cannot add refund record: ' + error.response.data.message)
      })
  }

  return (
    <div
      className='bg-white text-center fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-[1055] w-max h-max max-h-[calc(100%-100px) ] overflow-x-hidden outline-none min-w-[350px] max-w-[830px] 
     w-[350px] rounded md:px-3 md:py-4 px-2 py-3 overflow-y-scroll'
    >
      {/* Headingh */}
      <div className='text-start pb-4 border-b'>
        <h3 className='text-lg font-semibold'>Add Refund Payment Record</h3>
      </div>
      {/* Inputs */}
      <div className='mx-8 flex flex-col mb-8'>
        {/* first input */}
        <div className='flex justify-between my-1'>
          <span className='text-xs font-normal leading-[19px] text-start w-[190px]'>
            Refund Amount
          </span>
          <span className='text-xs font-normal leading-[19px] w-[150px]'>CNY</span>
          <span className='text-xs font-normal w-[100px] text-end pr-4 leading-[19px]'>
            {formatAmount(orderAmount)}
          </span>
        </div>
        {/* Second input */}
        <div className='flex justify-between my-1'>
          <span className='text-xs font-normal leading-[19px] text-start w-[150px]'>
            Client Name
          </span>
          <span className='text-xs font-normal w-[100px] text-end pr-4 leading-[19px]'>
            {clientName}
          </span>
        </div>
        {/* Third input */}
        <div className='flex justify-between my-1'>
          <span className='text-xs font-normal leading-[19px] text-start w-[150px]'>
            Last 4 digits of the card
          </span>
          <span className='text-xs font-normal w-[100px] text-end pr-4 leading-[19px]'>
            {cardDigits || 'N/A'}
          </span>
        </div>
        {/* Forth input */}
        <div className='flex justify-between my-1'>
          <span className='text-xs font-normal leading-[19px] text-start w-[150px]'>
            Bank Card Number
          </span>
          <input
            name='bankCardNumber'
            type='text'
            className='w-[130px] text-end rounded text-xs placeholder:pr-3 focus:outline-gray-300 border border-gray-300'
            onChange={handleChange}
          />
        </div>
        {/* Fifth input */}
        <div className='flex justify-between my-1'>
          <span className='text-xs font-normal leading-[19px] text-start w-[150px]'>Bank Name</span>
          <input
            name='bankName'
            type='text'
            className='w-[130px] text-end rounded text-xs placeholder:pr-3 focus:outline-gray-300 border border-gray-300'
            onChange={handleChange}
          />
        </div>
        {/* Sixth input */}
        <div className='flex justify-between my-1'>
          <span className='text-xs font-normal leading-[19px] text-start w-[150px]'>
            Bank Branch
          </span>
          <input
            name='bankBranch'
            type='text'
            className='w-[130px] text-end rounded text-xs placeholder:pr-3 focus:outline-gray-300 border border-gray-300'
            onChange={handleChange}
          />
        </div>
      </div>
      {/* Proof of Payment */}
      <div className='mx-8'>
        <div className='text-start'>
          <span className='text-[13px] font-normal leading-[19px] text-start w-[120px]'>
            Proof of Payment
          </span>
        </div>
        {/* Uploading box */}
        <div
          className='border my-1 min-h-[240px] py-10 border-dashed border-black'
          onClick={openFileSelector}
          onDrop={handleFileDrop}
          onDragOver={(e) => e.preventDefault()}
        >
          {selectedFile ? (
            <div>{selectedFile.name}</div>
          ) : (
            <span className='text-xs cursor-pointer p-2'>
              Drag and drop to upload or{' '}
              <span className='text-[#625BF6] underline'>browse for files</span>
            </span>
          )}
        </div>
        <input ref={fileInputRef} type='file' onChange={handleFileChange} hidden />
        {/* Buttons */}
        <div className='flex justify-center items-center gap-3 '>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button onClick={handleSubmit} className='bg-[#625BF6] cs_btn text-white'>
            Confirm
          </Button>
        </div>
      </div>
      {/* ------------------------------------------------------- */}
    </div>
  )
}

export default AddRefundPaymentRecord
