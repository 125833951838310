import TabTitle from '../../components/TabTitle'
import circleImgIcon from 'assets/images/icons/circleImgIcon.png'
import blockedIcon from 'assets/images/icons/blockedIcon.png'
import BreadCrumbs from '../../../../features/Dashboard/components/BreadCrumbs'
import Card from '../../components/Card'
import PaymentTable from './PaymentTable'
import UsersTable from './UsersTable'
import FeeSettingsTable from './FeeSettingsTable'
import { useParams } from 'react-router-dom'
import { useEffect } from 'react'
import { useState } from 'react'
import { initialValues as profileInitial } from '../config/merchantSchema'
import MerchantProfile from '../components/MerchantProfile'
import {
  capitalFirstLetter,
  lowerString,
  numberFormatter,
  numberWithCommas,
  getPhoneCode,
} from 'utils/helpers'
import MerchantPaymentSetting from '../components/MerchantPaymentSetting'
import Button from 'utils/Button'
import StatusBtn from 'utils/statusBtn'
import moment from 'moment'
import { getOneMerchant } from 'api/api'
import { toast } from 'react-toastify'
import './index.css'
import ApiInformation from './ApiInformation'
import { formatDateTime } from 'utils/formatDate'

// ===== merchant detail page ===========
const MerchantDetail = () => {
  // params
  const { id } = useParams()
  // profile state
  const [profileData, setProfileData] = useState(profileInitial)
  // summary state
  const [summaryData, setSummaryData] = useState({
    account_balance: 0,
    available_balance: 0,
    frozen_balance: 0,
  })

  //get merchant detail
  useEffect(() => {
    const getDetails = () => {
      if (!id) return
      // get profile data
      getOneMerchant(id)
        .then((data) => {
          if (data) {
            let obj = {
              ...profileInitial,
              ...data,
              phoneCode: getPhoneCode(data?.phone) || '+852',
              is_active: data?.is_active ? 'Active' : 'Inactive',
              user: {
                ...profileInitial?.user,
                ...data?.user,
                is_active: data?.user?.is_active ? 'Active' : 'Inactive',
              },
            }
            setProfileData(obj)
          }
        })
        .catch((err) => {
          console.log(err)
          toast.error(err.message || "Couldn't get profile data!")
        })
    }

    getDetails()
  }, [id])

  const capitalizeTabName = capitalFirstLetter(profileData.name)
  return (
    <div>
      {/* ****************** page bread crumbs ***************** */}
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '/dashboard/merchant' },
          { title: 'Merchant details', path: '' },
        ]}
      />
      {/* ************************** */}
      {/* page top info  */}
      <div className='flex flex-wrap items-start'>
        <div className='flex items-center'>
          <TabTitle title={capitalizeTabName} icon={<img src={circleImgIcon} alt='' />} />
          <StatusBtn text={profileData?.is_active} type={profileData?.is_active} indicator />
          <div className='text-sm font-medium text-gray-400'>
            <span className='px-2'>ID: #{id}</span>
          </div>
        </div>
        <div className='ml-auto flex flex-wrap items-end'>
          <div>
            <div className='lg:mr-2 text-gray-500 flex items-center justify-end'>
              <div className=''>
                <span className='text-sm '> Created at</span>
              </div>
              <div className='min-w-[130px] text-end'>
                <span className='text-sm'>
                  {profileData?.created_at
                    ? formatDateTime(profileData.created_at)
                    : 'Not available'}
                </span>
              </div>
            </div>
            <div className='lg:mr-2 text-gray-500 flex items-center justify-end'>
              <div className=''>
                <span className='text-sm'> Last modified</span>
              </div>
              <div className='min-w-[130px] text-end'>
                <span className='text-sm'>
                  {profileData?.last_updated
                    ? formatDateTime(profileData.last_updated)
                    : 'Not available'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* **************************** */}
      {/* account balance info */}
      <div className='flex flex-wrap my-2'>
        <div className='col lg:w-[25%] w-[50%] md:pl-0 min-w-[280px]'>
          <Card cardClasses='py-2'>
            <p>Account Balance</p>
            <div className='md:mt-2 flex flex-wrap justify-end items-center'>
              <p className='lg:px-2 pr-2'>CNY</p>
              <h6 className='font-medium lg:ml-0 ml-auto'>
                {numberFormatter.format(
                  profileData?.wallet?.available_balance + profileData?.wallet?.frozen_balance,
                )}
              </h6>
            </div>
          </Card>
        </div>
        <div className='col lg:w-[25%] w-[50%] md:pl-0 min-w-[280px]'>
          <Card cardClasses='py-2'>
            <p>Frozen Balance</p>
            <div className='md:mt-2 flex flex-wrap justify-end items-center'>
              <p className='lg:px-2 pr-2'>CNY</p>
              <h6 className='font-medium lg:ml-0 ml-auto'>
                {numberFormatter.format(profileData?.wallet?.frozen_balance)}
              </h6>
            </div>
          </Card>
        </div>
        <div className='col lg:w-[25%] w-[50%] md:pl-0 min-w-[280px]'>
          <Card cardClasses='py-2'>
            <p>Available balance</p>
            <div className='md:mt-2 flex flex-wrap justify-end items-center'>
              <p className='lg:px-2 pr-2'>CNY</p>
              <h6 className='font-medium lg:ml-0 ml-auto'>
                {numberFormatter.format(profileData?.wallet?.available_balance)}
              </h6>
            </div>
          </Card>
        </div>
      </div>
      {/* ******************************** */}
      {/* ************** profile form ********** */}
      <MerchantProfile type='' id={id} profileData={profileData} setProfileData={setProfileData} />
      {/* *************************************** */}
      {/* ************** Payment settings form ********** */}
      <MerchantPaymentSetting
        id={id}
        paymentSettingData={profileData}
        setProfileData={setProfileData}
      />
      {/* *************************************** */}
      {/* ******* Table for payment method records */}
      <PaymentTable id={id} />
      {/* *************************************** */}
      {/* ******* Fee  settings records table */}
      <FeeSettingsTable id={id} title={'Payin Fee Setting'} tableType='PAYIN' />
      {/* *********************** */}
      {/* Payout fee setting  */}
      <FeeSettingsTable id={id} title={'Payout Fee Setting'} tableType='PAYOUT' />
      {/* <PayoutFeeSetting id={id} title={"Payout Fee Setting"}/> */}
      {/* ******* User records table */}
      <UsersTable id={id} />
      {/* *********************** */}
      <ApiInformation id={id} />
      {/* *********************** */}
    </div>
  )
}

export default MerchantDetail
