import { useState } from 'react'
import acceptIconImg from '../../../assets/images/icons/acceptIcon.png'
import Button from '../../../utils/Button'
// import CsModal from "../../../utils/Modal";
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { initialValues } from './config/merchantSchema'
// import { getMerchantData } from "store/reducers/merchantReducerSlice";
import { useDispatch } from 'react-redux'
import { getPhoneCode, lowerString, showPhone } from '../../../utils/helpers'
import '../index.css'

// import { editMerchant } from "api/api";

const sourceOptions = {
  EMPLOYEE_REFERRAL: 'Employee Referral',
  WEBSITE: 'Website',
}

const MerchantProfile = ({ id, profileData, setProfileData }) => {
  const dispatch = useDispatch()
  const [modalOpen, setModal] = useState({ open: false, id: '' })
  const handleSubmit = (values, { setSubmitting }) => {}
  return (
    <>
      <Formik
        initialValues={profileData || initialValues}
        // validationSchema={merchantSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, handleChange, values, errors, touched }) => (
          <Form autoComplete='off'>
            <MerchantProfileFormWrapper
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              type='edit'
              readOnly
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

//
export const MerchantProfileFormWrapper = ({
  isSubmitting,
  handleChange,
  values,
  errors,
  touched,
  type,
}) => {
  // function for stoppping negative value typed in the input
  const preventMinus = (e) => {
    if (e.code === 'Minus') {
      e.preventDefault()
    }
  }
  return (
    <div className='border rounded mb-3 mt-2'>
      <div className={`md:px-3 px-2 py-1 ${type === 'add' ? 'md:mb-3 mb-2' : ''}`}>
        <h5 className='text-2xl text-left font-bold p-2'>Profile</h5>
        <div className='md:mt-2'>
          {/* 1 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='name'>Account Name</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='name'
                  id='name'
                  readOnly
                  placeholder='Account Name'
                  className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='name'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 2 */}
          <div className='flex flex-wrap md:my-0 my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='address1'>Business address</label>
            </div>
            <div className='col_ col_md_4 col_12 flex item-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  readOnly
                  name='address1'
                  id='address1'
                  onKeyDown={preventMinus}
                  min='0'
                  placeholder='Address 1'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='address1'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 3 */}
          <div className=' flex flex-wrap md:my-0 my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'></div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='address2'
                  id='address2'
                  placeholder='Address 2'
                  readOnly
                  onKeyDown={preventMinus}
                  min='0'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <p className='text-gray-400 w-full text-start md:ml-auto md:pl-2'></p>
            </div>
          </div>
          {/* 4 */}
          <div className=' flex flex-wrap md:my-0 my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'></div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  readOnly
                  name='city'
                  id='city'
                  placeholder='City'
                  onKeyDown={preventMinus}
                  min='0'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='city'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 5 */}
          <div className='flex flex-wrap md:my-0 my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'></div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='country'
                  id='country'
                  readOnly
                  placeholder='Country'
                  onKeyDown={preventMinus}
                  min='0'
                  className=' w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='country'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 6 */}
          <div className='flex flex-wrap md:my-0 my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'></div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='postal_code'
                  id='postal_code'
                  onKeyDown={preventMinus}
                  min='0'
                  readOnly
                  placeholder='ZIP/ Postal Code'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='postal_code'
                component='p'
                className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 7 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='sources'>Sources</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  name='sources'
                  id='sources'
                  readOnly
                  placeholder='Source'
                  value={sourceOptions[values.sources]}
                  className='w-full cursor-not-allowed rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <p className='text-gray-400 w-full text-start md:ml-auto md:pl-2'>
                {touched.sources && errors.sources && errors.sources}
              </p>
            </div>
          </div>
          {/* 8 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='email'>Email</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='email'
                  name='email'
                  placeholder='Email'
                  disabled
                  id='email'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='email'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 9 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='phone'>Phone</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <input
                  name='phoneCode'
                  id='phoneCode'
                  disabled
                  style={{ width: '30%' }}
                  className='cursor-pointer select-none me_2   rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  onChange={handleChange}
                  value={values?.phoneCode || getPhoneCode(values.phone)}
                />
                <Field
                  type='text'
                  name='phone'
                  id='phone'
                  readOnly
                  placeholder='Phone'
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  onChange={handleChange}
                  value={showPhone(values.phoneCode, values.phone)}
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='phone'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
          {/* 10 */}
          <div className='flex flex-wrap my-1 items-center'>
            <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
              <label htmlFor='business_registration'>Business Registration</label>
            </div>
            <div className='col_ col_md_4 col_12 flex items-center'>
              <div className='w-full flex items-center'>
                <Field
                  type='text'
                  readOnly
                  name='business_registration'
                  id='business_registration'
                  placeholder=''
                  className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                />
              </div>
            </div>
            <div className='col_md_5 col_12'>
              <ErrorMessage
                name='business_registration'
                component='p'
                className='text-red-500 w-full text-start md:ml-auto md:pl-2'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MerchantProfile
