const Button = (props) => {
  let { Component, width } = props
  if (Component) {
    return (
      <Component className={`cs_btn ${props.classes}`} {...props}>
        {props.children}
      </Component>
    )
  }
  return (
    <button
      className={`cs_btn capitalize whitespace-nowrap ${width ? 'w-[130px]' : ''} ${props.classes}`}
      {...props}
    >
      {props.children}
    </button>
  )
}

export default Button
