import React, { useState } from 'react'
import { toast } from 'react-toastify'

import { doConfirmPayment } from 'api/RESTful/payment'

import uploadIcon from 'assets/images/svgs/blackUpload.svg'

import Button from 'utils/Button'
import { formatAmount } from 'utils/formatAmount'

const PaymentConfirmationModal = ({ paymentID, orderAmount, handleCloseModal, reciept_url }) => {
  const [selectedFile, setSelectedFile] = useState(null)
  const [paymentReceived, setPaymentReceived] = useState(0)
  const [payInPerson, setPayInPerson] = useState(false)
  const [lastFourCardDigits, setLastFourCardDigits] = useState('')
  const [secondLastFourCardDigits, setSecondLastFourCardDigits] = useState('')
  const [thirdPartyPayment, setThirdPartyPayment] = useState(false)
  const [payerName, setPayerName] = useState(null)
  const [refundRequired, setRefundRequired] = useState(false)
  const [refundReason, setRefundReason] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const difference = paymentReceived - orderAmount

  const handleConfirm = () => {
    // Common request object structure
    setIsLoading(true)
    const req = {
      payment_received: paymentReceived,
      pay_in_person: payInPerson,
      last_four_card_digits: null,
      third_party_payment: thirdPartyPayment,
      payer_name: payerName,
      refund_required: refundRequired,
      refund_reason: refundReason,
    }

    // Assigning last_four_card_digits based on conditions
    if (payInPerson && !thirdPartyPayment) {
      req.last_four_card_digits = parseInt(lastFourCardDigits)
    } else if (!payInPerson && thirdPartyPayment) {
      req.last_four_card_digits = parseInt(secondLastFourCardDigits)
    } else if (payInPerson && thirdPartyPayment) {
      toast.error('Both Pay in Person and Third Party Payment cannot be selected simultaneously.')
      return
    }

    const formReq = new FormData()
    formReq.append('payment_received', req.payment_received)
    formReq.append('pay_in_person', req.pay_in_person)
    formReq.append('last_four_card_digits', req.last_four_card_digits)
    formReq.append('third_party_payment', req.third_party_payment)
    formReq.append('payer_name', req.payer_name)
    formReq.append('refund_required', req.refund_required)
    formReq.append('refund_reason', req.refund_reason)

    if (selectedFile) {
      formReq.append('file', selectedFile, selectedFile.name)
    }
    console.log(formReq)
    // Processing the request
    if (refundRequired && refundReason) {
      doConfirmPayment(paymentID, formReq)
        .then((response) => {
          toast.success(response.data.message)
          handleCloseModal()
          window.location.reload()
        })
        .catch((error) => {
          toast.error(error.message)
        })
        .finally(() => {
          setIsLoading(false) // Set loading to false when the request is done
        })
    } else if (!refundRequired && (payInPerson || thirdPartyPayment)) {
      doConfirmPayment(paymentID, formReq)
        .then((response) => {
          handleCloseModal()
          toast.success(response.data.message, {
            onClose: () => window.location.reload(),
          })
        })
        .catch((error) => {
          toast.error(error.message)
        })
        .finally(() => {
          setIsLoading(false) // Set loading to false when the request is done
        })
    } else {
      toast.error('Please select a payment confirmation method or enter refund details')
      setIsLoading(false)
    }
  }

  const handlePaymentReceivedChange = (e) => {
    setPaymentReceived(parseFloat(e.target.value) || 0)
  }
  const handlePayInPersonChange = (e) => {
    setPayInPerson(e.target.checked)
  }
  const handleThirdPartyPaymentChange = (e) => {
    setThirdPartyPayment(e.target.checked)
  }
  const handleLastFourCardDigitsChange = (e) => {
    setLastFourCardDigits(e.target.value)
  }
  const handleSecondLastFourCardDigitsChange = (e) => {
    setSecondLastFourCardDigits(e.target.value)
  }
  const handlePayerNameChange = (e) => {
    setPayerName(e.target.value)
  }
  const handleRefundRequiredChange = (e) => {
    setRefundRequired(e.target.checked)
  }
  const handleRefundReasonChange = (e) => {
    setRefundReason(e.target.value)
  }
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0])
  }

  const handleFileDrop = (e) => {
    e.preventDefault()
    const files = e.dataTransfer.files
    if (files && files.length > 0) {
      setSelectedFile(files[0])
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
  }

  return (
    <div
      className='bg-white text-center fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-[1055] w-max h-max max-h-[calc(100%-100px) ] overflow-x-hidden overflow-y-auto outline-none min-w-[550px] max-w-[830px] 
     w-[750px] rounded md:px-3 md:py-4 px-2 py-3'
    >
      <div className='text-start pb-4 border-b'>
        <h3 className='text-lg font-semibold'>Payment Confirmation</h3>
      </div>
      <div className='flex w-full'>
        {/* Left part */}
        <div className='w-[50%] flex flex-col  py-2'>
          {/* First */}
          <div className='flex justify-around'>
            <span className='text-xs font-normal leading-[19px] text-start w-[120px]'>
              Order Amount
            </span>
            <span className='text-xs font-normal leading-[19px]'>CNY</span>
            <span className='text-xs font-normal w-[100px] text-end pr-4 leading-[19px]'>
              {formatAmount(orderAmount)}
            </span>
          </div>
          {/* ------------------------------- */}
          <div className='flex justify-around mb-2'>
            <span className='text-xs font-normal leading-[19px] text-start w-[120px]'>
              Payment Recieved
            </span>
            <span className='text-xs font-normal leading-[19px]'>CNY</span>
            <span className='text-xs font-normal leading-[19px]'>
              <input
                type='number'
                placeholder='0.00'
                value={paymentReceived}
                onChange={handlePaymentReceivedChange}
                className='w-[100px] text-end focus:border-gray-300  focus:border rounded'
              />
            </span>
          </div>
          <hr />
          {/* ------------------------------------- */}
          <div className='flex justify-around my-2'>
            <span className='text-xs font-normal leading-[19px] text-start w-[120px]'>
              Difference
            </span>
            <span className='text-xs font-normal leading-[19px]'>CNY</span>
            <span className='text-xs font-normal w-[100px] text-end pr-4 leading-[19px]'>
              {formatAmount(difference)}
            </span>
          </div>
          {/* Checks */}
          <div className='ml-5 '>
            {/* First check */}
            <div className='flex flex-col gap-1 mb-3'>
              <div className='flex items-center gap-3'>
                <input
                  type='checkbox'
                  name='payInPerson'
                  id='payInPerson'
                  checked={payInPerson}
                  onChange={handlePayInPersonChange}
                />
                <span className='text-xs font-normal leading-[19px]'>Pay in Person</span>
              </div>

              <div className='flex justify-between'>
                <span className='text-xs font-normal text-gray-400 pl-6 leading-[19px]'>
                  Last Four Digits of the Card
                </span>
                <input
                  type='text'
                  pattern='\d*'
                  maxLength='4'
                  value={lastFourCardDigits}
                  onChange={handleLastFourCardDigitsChange}
                  disabled={!payInPerson}
                  className='text-xs font-normal leading-[19px] w-[100px] focus:outline-gray-400 border rounded border-gray-300 text-gray-400 text-end'
                />
              </div>
            </div>
            {/* Second check */}
            <div className='flex flex-col gap-1 mb-3'>
              <div className='flex items-center gap-3'>
                <input
                  type='checkbox'
                  name='thirdPartyPayment'
                  id='thirdPartyPayment'
                  checked={thirdPartyPayment}
                  onChange={handleThirdPartyPaymentChange}
                />
                <span className='text-xs font-normal leading-[19px]'>Third Party Payment</span>
              </div>
              <div className='flex justify-between'>
                <span className='text-xs font-normal text-gray-400 pl-6 leading-[19px]'>
                  Payer Name
                </span>
                <input
                  type='text'
                  placeholder=''
                  value={payerName}
                  onChange={handlePayerNameChange}
                  disabled={!thirdPartyPayment}
                  className='text-xs font-normal leading-[19px] w-[100px] focus:outline-gray-400 border rounded border-gray-300 text-gray-400 text-end'
                />
              </div>
              <div className='flex justify-between'>
                <span className='text-xs font-normal text-gray-400 pl-6 leading-[19px]'>
                  Last Four Digits of the Card
                </span>
                <input
                  type='text'
                  pattern='\d*'
                  maxLength='4'
                  value={secondLastFourCardDigits}
                  onChange={handleSecondLastFourCardDigitsChange}
                  disabled={!thirdPartyPayment}
                  className='text-xs font-normal leading-[19px] w-[100px] focus:outline-gray-400 border rounded border-gray-300 text-gray-400 text-end'
                />
              </div>
            </div>
            {/* Third Check */}
            <div className='flex flex-col gap-1 mb-3'>
              <div className='flex items-center gap-3'>
                <input
                  type='checkbox'
                  name='refundRequired'
                  id='refundRequired'
                  checked={refundRequired}
                  onChange={handleRefundRequiredChange}
                />
                <span className='text-xs font-normal leading-[19px]'>Refund Required</span>
              </div>
              <div className='flex justify-between'>
                <textarea
                  type='text'
                  name='refundReason'
                  id='refundReason'
                  cols='40'
                  placeholder='Reason'
                  rows='5'
                  value={refundReason}
                  onChange={handleRefundReasonChange}
                  disabled={!refundRequired}
                  className='border border-gray-300  focus:outline-gray-400 ml-6 rounded p-1 text-xs'
                ></textarea>
              </div>
            </div>
          </div>
          {/* -----------Left end */}
        </div>
        {/* Right part */}
        <div className='w-[50%] '>
          {reciept_url ? (
            // Display the photo if reciept_url is available
            <div>
              <div className='flex gap-3 px-4 pt-4'>
                <img src={uploadIcon} alt='' />
                <span className='text-sm font-semibold leading-[21px]'>Uploaded Receipt</span>
              </div>
              <div className='mx-3 my-2'>
                <img src={reciept_url} alt='Receipt' className='min-h-[240px] max-w-full' />
              </div>
            </div>
          ) : (
            // Show upload section if reciept_url is empty
            <div>
              <div className='flex gap-3 px-4 pt-4'>
                <img src={uploadIcon} alt='' />
                <span className='text-sm font-semibold leading-[21px]'>Upload Receipt</span>
              </div>
              <div
                className='border mx-3 my-2 min-h-[240px] py-10 border-dashed border-black'
                onDrop={handleFileDrop}
                onDragOver={handleDragOver}
              >
                {selectedFile ? (
                  <span className='text-xs'>{selectedFile.name}</span>
                ) : (
                  <span className='text-xs cursor-pointer'>
                    Drag and drop to upload or{' '}
                    <label
                      htmlFor='file-upload'
                      className='text-[#625BF6] underline cursor-pointer'
                    >
                      browse for files
                    </label>
                    <input
                      type='file'
                      id='file-upload'
                      onChange={handleFileChange}
                      style={{ display: 'none' }}
                    />
                  </span>
                )}
              </div>
            </div>
          )}

          <div className='flex justify-end items-center gap-3'>
            <Button onClick={handleCloseModal}>Cancel</Button>
            <Button
              onClick={handleConfirm}
              className='bg-[#625BF6] cs_btn text-white'
              disabled={isLoading}
            >
              {isLoading ? 'Processing...' : 'Confirm'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentConfirmationModal
