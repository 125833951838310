import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { getCards } from 'api/RESTful/card'
import { getWalletDetails } from 'api/RESTful/wallet'
import { createPayoutMany } from 'api/RESTful/payout'

import Table from 'pages/Dashboard/components/Table'
import { useSortableData2 } from 'pages/Dashboard/components/useSortableData2'
import Spinner from 'utils/Spinner'
import Button from 'utils/Button'

import { formatAmount } from 'utils/formatAmount'

const CheckData = (payoutManyRawData) => {
  const [isLoading, setIsLoading] = useState(false)
  const [payoutManyData, setPayoutManyData] = useState([])
  const [cardDropdownList, setCardDropdownList] = useState([])

  const payoutManyDataColumns = [
    { name: 'Merchant' },
    { name: 'Client Name' },
    { name: 'Amount(CNY)' },
    { name: 'Bank Name' },
    { name: 'Bank Account Number' },
    { name: 'Branch' },
    { name: 'Province' },
    { name: 'City' },
    { name: 'Assign Card' },
    { name: 'After Assign Balance' },
  ]

  const fetchCardDropdownList = () => {
    getCards(10, 0)
      .then((res) => {
        getCards(res.total, 0).then((res) => {
          setCardDropdownList(res.data)
          setIsLoading(false)
        })
      })
      .catch((err) => {
        toast.error(err.response.data.message)
      })
  }

  const handleCardChange = async (e, index) => {
    const cardId = e.target.value
    const selectedAmount = payoutManyData[index].amount
    const selectedCard = cardDropdownList.find((card) => card.id === cardId)
    if (selectedCard && selectedCard.wallet_id) {
      try {
        const walletResponse = await getWalletDetails(selectedCard.wallet_id)
        const availableBalance = walletResponse.available_balance
        const afterAssignBalance = availableBalance - selectedAmount
        setPayoutManyData((currentData) =>
          currentData.map((item, i) =>
            i === index
              ? { ...item, card_id: cardId, afterAssignBalance: afterAssignBalance }
              : item,
          ),
        )
      } catch (error) {
        toast.error(error.response.data.message)
      }
    } else {
      toast.error("Selected card doesn't have an associated wallet.")
    }
  }
  const handleSubmit = async () => {
    try {
      setIsLoading(true)
      const dataToSend = payoutManyData.map(
        ({ afterAssignBalance, id, merchant_name, ...item }) => item,
      )
      await createPayoutMany(dataToSend)
      setIsLoading(false)
      toast.success('Payout successfully created')
    } catch (error) {
      setIsLoading(false)
      toast.error('Cannot create payout: ' + error.response.data.message)
    }
  }

  useEffect(() => {
    fetchCardDropdownList()
  }, [])

  useEffect(() => {
    setIsLoading(true)
    const enrichedData = payoutManyRawData.payoutManyRawData.map((item) => ({
      ...item,
      card_id: '',
      afterAssignBalance: 0,
    }))
    setPayoutManyData(enrichedData)
    setIsLoading(false)
  }, [payoutManyRawData])

  const { items: tblData, CsTableHead } = useSortableData2(payoutManyData, payoutManyDataColumns)

  return (
    <div>
      <div className='border-t rounded md:my-3 my_2'>
        <Table CsTableHead={<CsTableHead loading={isLoading} />}>
          {isLoading ? (
            <tr>
              <td colSpan='10' className='text-center'>
                <div className='w_100 py_3 px-2'>
                  <Spinner />
                  <h6 className='py-2 w_100 text-center'>Loading...</h6>
                </div>
              </td>
            </tr>
          ) : (
            tblData?.map((item, i) => (
              <tr key={i} className=''>
                <td>
                  {item?.merchant_id ? (
                    <div className='form_group flex items-center justify-center'>
                      <input
                        type='text'
                        name='merchant_id'
                        value={item?.merchant_id}
                        placeholder='merchant_id'
                        className='w_100'
                        style={{ maxWidth: '100px', textAlign: 'center' }}
                        disabled
                      />
                    </div>
                  ) : (
                    '-'
                  )}
                </td>
                <td className='text-center pr-2'>
                  {item?.client_name ? (
                    <div className='form_group flex items-center justify-center'>
                      <input
                        type='text'
                        name='client_name'
                        value={item?.client_name}
                        placeholder='client_name'
                        className='w_100'
                        style={{ maxWidth: '100px', textAlign: 'center' }}
                        disabled
                      />
                    </div>
                  ) : (
                    '-'
                  )}
                </td>
                <td>
                  {item?.amount ? (
                    <div className='form_group flex items-center justify-center'>
                      <input
                        type='text'
                        name='amount'
                        value={formatAmount(item?.amount)}
                        placeholder='amount'
                        className='w_100'
                        style={{ maxWidth: '100px', textAlign: 'left' }}
                        disabled
                      />
                    </div>
                  ) : (
                    '-'
                  )}
                </td>
                <td className='text-center'>
                  {
                    <div className='form_group flex items-center justify-center'>
                      <input
                        type='text'
                        name='bank_name'
                        value={item?.bank_name}
                        placeholder='bank_name'
                        className='w_100'
                        style={{ maxWidth: '100px', textAlign: 'left' }}
                        disabled
                      />
                    </div>
                  }
                </td>
                <td className='text-center'>
                  <div className='form_group flex items-center justify-center'>
                    <input
                      type='text'
                      name='bank_account_number'
                      value={item?.bank_account_number}
                      placeholder=''
                      className='w_100'
                      style={{ maxWidth: '150px', textAlign: 'left' }}
                      disabled
                    />
                  </div>
                </td>
                <td className='text-center'>
                  <div className='form_group flex items-center justify-center'>
                    <input
                      type='text'
                      name='branch'
                      value={item?.branch}
                      placeholder=''
                      className='w_100'
                      style={{ maxWidth: '100px', textAlign: 'left' }}
                      disabled
                    />
                  </div>
                </td>
                <td>
                  <div className='form_group flex items-center justify-center'>
                    <input
                      type='text'
                      name='province'
                      value={item?.province}
                      placeholder=''
                      className='w_100'
                      style={{ maxWidth: '100px', textAlign: 'left' }}
                      disabled
                    />
                  </div>
                </td>
                <td>
                  <div className='form_group flex items-center justify-center'>
                    <input
                      type='text'
                      name='city'
                      value={item?.city}
                      placeholder=''
                      className='w_100'
                      style={{ maxWidth: '100px', textAlign: 'left' }}
                      disabled
                    />
                  </div>
                </td>
                <td>
                  <div className='form_group flex items-center justify-center'>
                    <select
                      name='card_id'
                      id={`card_id_${i}`}
                      className='w_100'
                      onChange={(e) => handleCardChange(e, i)}
                      value={item.card_id}
                    >
                      <option value='' disabled>
                        Select Card
                      </option>
                      {isLoading ? (
                        <option value='' disabled>
                          Loading all cards
                        </option>
                      ) : cardDropdownList.length < 0 ? (
                        <option value='' disabled>
                          No card found
                        </option>
                      ) : (
                        cardDropdownList.map((item, ind) => (
                          <option value={item.id} key={item.id || ind}>
                            {item.id + ' ' + item.card_nickname}
                          </option>
                        ))
                      )}
                    </select>
                  </div>
                </td>
                <td>
                  <div className='form_group flex items-center justify-center'>
                    <input
                      type='afterAssignBalance'
                      name='afterAssignBalance'
                      value={formatAmount(item?.afterAssignBalance)}
                      placeholder=''
                      className='w_100'
                      style={{
                        maxWidth: '100px',
                        textAlign: 'left',
                        backgroundColor: item?.afterAssignBalance < 0 ? 'red' : 'transparent',
                        color: item?.afterAssignBalance < 0 ? 'white' : 'initial',
                      }}
                      disabled
                    />
                  </div>
                </td>
              </tr>
            ))
          )}
        </Table>
      </div>
      <div>
        <div className='flex items-center justify-center mr-3 gap-5 my-5'>
          <Button classes='cs_btn bg-purple-500 text-white' type='submit' onClick={handleSubmit}>
            Create
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CheckData
