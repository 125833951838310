import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import { getCardPriorities, updateCardPriority } from 'api/RESTful/card'

import activeIcon from '../../../../assets/images/svgs/active.svg'
import inActiveIcon from '../../../../assets/images/svgs/inactive.svg'
import card from '../../../../assets/images/icons/cardIcon.png'

import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import TabTitle from 'pages/Dashboard/components/TabTitle'
import { useSortableData2 } from 'pages/Dashboard/components/useSortableData2'

import { SearchIcon } from 'utils/Icons'
import Button from 'utils/Button'
import { formatDateTime } from 'utils/formatDate'
import { formatAmount } from 'utils/formatAmount'

// ----------------------
const CardsPriority = () => {
  const cardPriorityColumn = [
    { title: 'ID', name: 'ID', sort: true },
    { title: 'created_at', name: 'Created At', sort: true },
    { title: 'name', name: 'Name', sort: true },
    { title: 'card_number', name: 'Card Number', sort: true },
    { title: 'bank_name', name: 'Bank Name', sort: true },
    { title: 'max_limit', name: 'Max Limit', sort: true },
    { title: 'priority', name: 'Priority', sort: true },
    { title: 'status', name: 'Status', sort: false },
    { title: 'last_update', name: 'Last Update', sort: true },
    { title: 'action', name: 'Action' },
  ]

  const { id, feeId } = useParams()
  const [cardsData, setCardsData] = useState([])
  const [loading, setIsLoading] = useState(false)

  const handleChange = (e, cardIndex) => {
    const newCardsData = cardsData.map((card, index) => {
      if (index === cardIndex) {
        return { ...card, priority: Number(e.target.value) }
      }
      return card
    })
    setCardsData(newCardsData)
  }

  const handleUpdate = async (card, index) => {
    setIsLoading(true)
    try {
      await updateCardPriority(card.card_id, card.id, { priority: card.priority })
      toast.success('Priority updated successfully')
    } catch (error) {
      toast.error('Error updating priority')
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getCardPriorities(feeId)
      .then((res) => {
        setCardsData(res)
      })
      .catch((err) => {
        toast.error(err.response.data.message)
        console.log(err)
      })
  }, [id])

  //
  const { items: tblData, CsTable } = useSortableData2(cardsData)

  //
  return (
    <div>
      <BreadCrumbs
        navList={[
          { title: 'Merchant', path: '/dashboard/merchant' },
          {
            title: 'Merchant Details',
            path: `/dashboard/merchant/details/${id}`,
          },
          { title: 'Cards Priority', path: 'merchant/details/cardspriority/' },
        ]}
      />
      {/* ********************** */}
      <div className='flex flex-wrap items-center justify-between'>
        <div className='mr-2'>
          <TabTitle title={'Card Priority'} icon={<img src={card} />} />
        </div>
        <div className='flex flex-col'>
          <p className='text-gray-500 flex gap-2 text-xs'>
            <span className='w-[40%] text-right'>Created At</span>{' '}
            <span>{formatDateTime(cardsData.created_at)}</span>
          </p>
          <p className='text-gray-500 flex gap-2 text-xs'>
            <span>Last modified</span> <span>{formatDateTime(cardsData.last_updated)}</span>
          </p>
        </div>
      </div>
      {/* *************************** */}
      <div className='flex flex-wrap mt-5'>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className='flex gap-2 items-end flex-wrap'>
            <div>
              <label htmlFor=''>Card Name/Number</label>
              <div className=''>
                <input disabled type='text' className='border rounded mt-2 p-1' />
              </div>
            </div>
            <div>
              <label htmlFor=''>Status</label>
              <div className=''>
                <input disabled type='text' className='border rounded mt-2 p-1' />
              </div>
            </div>
            <button className='bg-purple-600 flex text-white font-medium gap-2 p-2 rounded-lg'>
              <SearchIcon /> Search
            </button>
          </div>
        </form>
      </div>
      {/* ***************************** */}
      <div className='min-h-[100vh]'>
        <div
          className={`mb-3 border rounded my-2 grid text-center ${
            cardsData?.length > 0 ? '' : 'min-h-[30vh] items-center'
          }`}
        >
          <CsTable tableClasses='header_fill text-center' hideScrollbar>
            <tr>
              {cardPriorityColumn.map((col, index) => (
                <th key={index}>{col.name}</th>
              ))}
            </tr>
            {tblData.length > 0 ? (
              tblData?.map((item, index) => {
                return (
                  <tr key={index}>
                    <td>
                      <div className='flex flex-col items-center'>
                        <span>{item.id}</span>
                      </div>
                    </td>
                    <td>
                      <div className='flex flex-col items-center'>
                        <span>{formatDateTime(item.created_at)}</span>
                      </div>
                    </td>
                    <td>
                      <div>{item.card.card_nickname}</div>
                    </td>
                    <td>
                      <div>{item.card.bank_card_number}</div>
                    </td>
                    <td>
                      <div>{item.card.bank_name}</div>
                    </td>
                    <td>{formatAmount(item.card.payin_daily_max_limit)}</td>
                    <td>
                      <input
                        name=''
                        type='number'
                        min={'0'}
                        max={'99'}
                        value={item.priority}
                        onChange={(e) => handleChange(e, index)}
                        className='border p-1 rounded text-center w-[84px] h-[28px]'
                      />
                    </td>
                    <td>
                      <div className='flex gap-1'>
                        {item.status === 'ACTIVE' ? (
                          <img src={activeIcon} />
                        ) : (
                          <img src={inActiveIcon} />
                        )}
                        <span>{item.card.status === 'ACTIVE' ? 'ACTIVE' : 'INACTIVE'}</span>
                      </div>
                    </td>
                    <td>
                      <div className='flex flex-col items-center'>
                        <span>{formatDateTime(item.last_updated)}</span>
                      </div>
                    </td>
                    <td>
                      <Button
                        onClick={() => handleUpdate(item, index)}
                        disabled={loading}
                        classes='text-white ml-1 bg-purple-500 border-none rounded'
                        type='submit'
                      >
                        {loading ? 'Saving..' : 'Save'}
                      </Button>
                    </td>
                  </tr>
                )
              })
            ) : (
              <tr>
                <td colSpan='9'>
                  <div className='my-4 mx-auto text-center'>
                    <p>No records</p>
                  </div>
                </td>
              </tr>
            )}
          </CsTable>
        </div>
      </div>
    </div>
  )
}

export default CardsPriority
