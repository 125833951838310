import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { getMerchantDetails } from 'api/RESTful/merchant'

import '../index.css'
import { initialValues as profileInitial } from './config/merchantSchema'

import circleImgIcon from '../../../assets/images/icons/circleImgIcon.png'

import BreadCrumbs from '../../../features/Dashboard/components/BreadCrumbs'
import Card from '../../Dashboard/components/Card'
import TabTitle from '../../Dashboard/components/TabTitle'

import PaymentTable from './PaymentTable'
import MerchantProfile from './MerchantProfile'
import MerchantPaymentSetting from './MerchantPaymentSetting'
import ApiInformation from './ApiInformation'

import StatusBtn from '../../../utils/statusBtn'
import { formatDateTime } from 'utils/formatDate'
import { formatAmount } from 'utils/formatAmount'

const MerchantDetail = () => {
  const { data: authData } = useSelector((store) => store.auth)
  const [merchantID, setMerchantID] = useState('')
  const [profileData, setProfileData] = useState(profileInitial)

  const fetchMerchantDetails = (merchantID) => {
    getMerchantDetails(merchantID)
      .then((data) => {
        if (data) {
          let obj = {
            ...profileInitial,
            ...data,
            is_active: data?.is_active ? 'Active' : 'Inactive',
            user: {
              ...profileInitial?.user,
              ...data?.user,
              is_active: data?.user?.is_active ? 'Active' : 'Inactive',
            },
          }
          setProfileData(obj)
        }
      })
      .catch((err) => {
        console.log(err)
        toast.error(err.message || 'Cannot get profile details, Please try again.')
      })
  }

  useEffect(() => {
    setMerchantID(authData.merchantId)
  }, [authData])

  useEffect(() => {
    if (merchantID) {
      fetchMerchantDetails(merchantID)
    }
  }, [merchantID])

  return (
    <div className='px-[32px] py-[20px]'>
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '/merchantPortal' },
          { title: 'Merchant details', path: 'merchantPortal/merchant' },
        ]}
      />

      <div className='flex flex-wrap items-start'>
        <div className='flex items-center gap-3'>
          <TabTitle title={profileData.name} icon={<img src={circleImgIcon} alt='' />} />
          <StatusBtn text={profileData?.is_active} type={profileData?.is_active} indicator />
          <div className='text-sm font-medium text-gray-400'>
            <span className='px-2'>ID: #{profileData.id} </span>
          </div>
        </div>
        <div className='ml-auto flex flex-wrap items-end'>
          <div>
            <div className='lg:mr-2 text-gray-500 flex items-center justify-end'>
              <div className=''>
                <span className='text-sm '> Created at</span>
              </div>
              <div className='min-w-[130px] text-end'>
                <span className='text-sm'>
                  {profileData?.created_at
                    ? formatDateTime(profileData.created_at)
                    : 'Not available'}
                </span>
              </div>
            </div>
            <div className='lg:mr-2 text-gray-500 flex items-center justify-end'>
              <div className=''>
                <span className='text-sm'> Last modified</span>
              </div>
              <div className='min-w-[130px] text-end'>
                <span className='text-sm'>
                  {profileData?.last_updated
                    ? formatDateTime(profileData.last_updated)
                    : 'Not available'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-wrap my-2 text-left'>
        <div className='col lg:w-[25%] w-[50%] md:pl-0 min-w-[280px]'>
          <Card cardClasses='py-2'>
            <p>Account Balance</p>
            <div className='md:mt-2 flex flex-wrap justify-end items-center'>
              <p className='lg:px-2 pr-2'>CNY</p>
              <h6 className='font-medium lg:ml-0 ml-auto'>
                {formatAmount(
                  profileData.wallet?.available_balance - profileData.wallet?.frozen_balance,
                )}
              </h6>
            </div>
          </Card>
        </div>
        <div className='col lg:w-[25%] w-[50%] md:pl-0 min-w-[280px]'>
          <Card cardClasses='py-2'>
            <p>Frozen Balance</p>
            <div className='md:mt-2 flex flex-wrap justify-end items-center'>
              <p className='lg:px-2 pr-2'>CNY</p>
              <h6 className='font-medium lg:ml-0 ml-auto'>
                {formatAmount(profileData.wallet?.frozen_balance)}
              </h6>
            </div>
          </Card>
        </div>
        <div className='col lg:w-[25%] w-[50%] md:pl-0 min-w-[280px]'>
          <Card cardClasses='py-2'>
            <p>Available balance</p>
            <div className='md:mt-2 flex flex-wrap justify-end items-center'>
              <p className='lg:px-2 pr-2'>CNY</p>
              <h6 className='font-medium lg:ml-0 ml-auto'>
                {formatAmount(profileData.wallet?.available_balance)}
              </h6>
            </div>
          </Card>
        </div>
      </div>

      <MerchantProfile id={merchantID} profileData={profileData} setProfileData={setProfileData} />
      <MerchantPaymentSetting
        id={merchantID}
        paymentSettingData={profileData}
        setProfileData={setProfileData}
      />
      <PaymentTable id={merchantID} />
      <ApiInformation />
    </div>
  )
}

export default MerchantDetail
