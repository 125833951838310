import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { getMerchantDetails } from 'api/RESTful/merchant'

import walletIcon from '../../../assets/images/icons/walletIcon.png'

import BreadCrumbs from '../../../features/Dashboard/components/BreadCrumbs'
import Card from '../../Dashboard/components/Card'
import TabTitle from '../../Dashboard/components/TabTitle'
import { useSortableData } from '../../Dashboard/components/useSortableData'

import { formatDateTime } from 'utils/formatDate'
import { formatAmount } from 'utils/formatAmount'
import Spinner from '../../../utils/Spinner'

const WalletDetails = () => {
  const walletDetailsColumn = [
    { name: 'Date', sort: true },
    { name: 'Wallet Trx ID', sort: true },
    { name: 'Type' },
    { name: 'Currency' },
    { name: 'Ammount' },
    { name: 'Description' },
    { name: 'Status' },
    { name: 'Last Updated' },
  ]
  const { data: authData } = useSelector((store) => store.auth)
  const [merchantID, setMerchantID] = useState('')
  const [walletDetails, setWalletDetails] = useState([])
  const [wallListData, setWallListData] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const fetchWalletDetails = (merchantID) => {
    getMerchantDetails(merchantID)
      .then((res) => {
        setWalletDetails(res.wallet)
      })

      .catch((err) => {
        console.log(err)
        toast.error(err.message || 'Cannot get profile details, Please try again.')
      })
  }

  useEffect(() => {
    setMerchantID(authData.merchantId)
  }, [authData])
  useEffect(() => {
    if (merchantID) {
      fetchWalletDetails(merchantID)
    }
  }, [merchantID])

  const { items: tblData, CsTable } = useSortableData(wallListData, walletDetailsColumn)
  return (
    <div className='px-[32px] py-[20px] text-left'>
      {/* ****************** page bread crumbs ***************** */}
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '' },
          { title: 'Wallet', path: 'wallet' },
          { title: 'Wallet Details', path: 'wallet' },
        ]}
      />
      {/* ************************************** */}
      {/* Tab title */}
      <div className='cs_row items-center'>
        <div className='mr-2'>
          <TabTitle title={'Wallet'} icon={<img src={walletIcon} alt='' />} />
        </div>
      </div>
      {/* ******************************************* */}
      {/* account balance info */}
      <div className='flex flex-wrap my-2'>
        <div className='col lg:w-[25%] w-[50%] md:pl-0 min-w-[280px]'>
          <Card cardClasses='py-2'>
            <p className='text-[20px]'>Account Balance</p>
            <div className='md:mt-2 flex flex-wrap justify-end items-center'>
              <p className='lg:px-2 pr-2 text-[20px]'>CNY</p>
              <h6 className='font-medium text-[20px] lg:ml-0 ml-auto'>
                {formatAmount(walletDetails?.available_balance - walletDetails.frozen_balance)}
              </h6>
            </div>
          </Card>
        </div>
        <div className='col lg:w-[25%] w-[50%] md:pl-0 min-w-[280px]'>
          <Card cardClasses='py-2'>
            <p className='text-[20px]'>Frozen Balance</p>
            <div className='md:mt-2 flex flex-wrap justify-end items-center'>
              <p className='lg:px-2 pr-2 text-[20px]'>CNY</p>
              <h6 className='font-medium text-[20px] lg:ml-0 ml-auto'>
                {formatAmount(walletDetails?.frozen_balance)}
              </h6>
            </div>
          </Card>
        </div>
        <div className='col lg:w-[25%] w-[50%] md:pl-0 min-w-[280px]'>
          <Card cardClasses='py-2'>
            <p className='text-[20px]'>Available balance</p>
            <div className='md:mt-2 flex flex-wrap justify-end items-center'>
              <p className='lg:px-2 pr-2 text-[20px]'>CNY</p>
              <h6 className='font-medium text-[20px] lg:ml-0 ml-auto'>
                {formatAmount(walletDetails?.available_balance)}
              </h6>
            </div>
          </Card>
        </div>
      </div>
      <hr />

      {/* --------------------------------------------------- */}
      {/* Data Table */}
      <div className='mt-14'>
        <div className='md:my-4 border rounded my-2'>
          <CsTable loading={isLoading} tableClasses='header_fill'>
            <tr>
              {walletDetailsColumn.map((col, index) => (
                <th key={index}>{col.name}</th>
              ))}
            </tr>
            {!isLoading ? (
              wallListData?.length > 0 ? (
                wallListData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <p className='min-w-[120px] text-sm'>{item?.date}</p>
                    </td>
                    <td>{item?.order_id}</td>
                    <td>{item?.type}</td>
                    <td>{item?.currency}</td>
                    <td>{item?.amount}</td>
                    <td>{item?.fees}</td>

                    <td className=''>
                      <p className='truncate text-ellipsis max-w-[220px] text-sm'>
                        {item.description}
                      </p>
                    </td>
                    <td className='text-center'>
                      <div>
                        <p className='bg-[#DDF6C780] p-1 rounded text-sm text-[#42821E] '>
                          {item?.status}
                        </p>
                      </div>
                    </td>
                    <td className='text-center'>
                      {' '}
                      <p className='text-sm min-w-[120px]'>{item?.lastUpdated}</p>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='8'>
                    <div className='my-4 mx-auto text-center'>
                      <p>No records</p>
                    </div>
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan='8'>
                  <div className='my-4 mx-auto text-center'>
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}
          </CsTable>
        </div>
      </div>
      {/* ----------------------------------------------------------- */}
    </div>
  )
}

export default WalletDetails
