import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import React, { useEffect, useState } from 'react'
import circleImgIcon from 'assets/images/icons/circleImgIcon.png'
import TabTitle from 'pages/Dashboard/components/TabTitle'
import StatusBtn from 'utils/statusBtn'
import Button from 'utils/Button'
import blockedIcon from 'assets/images/icons/blockedIcon.png'
import moment from 'moment'
import AgentProfile from '../components/AgentProfile'
import CardTable from './CardTable'
import { useParams } from 'react-router-dom'
import { initialValues } from '../config/agentSchema'
import { getAgentdetails, editAgent } from 'api/api'
import { capitalFirstLetter } from 'utils/helpers'
import { formatDateTime } from 'utils/formatDate'

const AgentDetails = () => {
  const { id } = useParams()
  const [profileData, setProfileData] = useState([initialValues])
  // --------------------------
  useEffect(() => {
    const getDetails = () => {
      if (!id) return

      // get agent data
      getAgentdetails(id)
        .then((data) => {
          setProfileData({
            // ...initialValues,
            ...data,
            is_active: data?.is_active ? 'Active' : 'Inactive',
          })
        })

        .catch((err) => {
          console.log(err)
        })
    }

    getDetails()
  }, [id])

  const capitalizeTabName = capitalFirstLetter(profileData.name)

  return (
    <>
      <div>
        {/* BreadCrumbs */}
        <BreadCrumbs
          navList={[
            { title: 'Home', path: '/dashboard/agents' },
            { title: 'Agent details', path: '' },
          ]}
        />
        {/* page top info  */}
        <div className='flex flex-wrap items-start'>
          <div className='flex items-center'>
            <TabTitle title={capitalizeTabName} icon={<img src={circleImgIcon} alt='' />} />
            <StatusBtn text={profileData?.is_active} type={profileData?.is_active} indicator />
            <div className='text-sm font-medium text-gray-400'>
              <span className='px-2'>ID:# {profileData.id}</span>
            </div>
          </div>
          <div className='ml-auto flex flex-wrap items-end'>
            <div>
              <div className='lg:mr-2 text-gray-500 flex items-center justify-end'>
                <div className=''>
                  <span className='text-sm '> Created at</span>
                </div>
                <div className='min-w-[130px] text-end'>
                  <span className='text-sm'>
                    {profileData?.created_at
                      ? formatDateTime(profileData.created_at)
                      : 'Not available'}
                  </span>
                </div>
              </div>
              <div className='lg:mr-2 text-gray-500 flex items-center justify-end'>
                <div className=''>
                  <span className='text-sm'> Last modified</span>
                </div>
                <div className='min-w-[130px] text-end'>
                  <span className='text-sm'>
                    {profileData?.last_updated
                      ? formatDateTime(profileData.last_updated)
                      : 'Not available'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <AgentFormWrapper/> */}
        <AgentProfile id={id} profileData={profileData} setProfileData={setProfileData} />
        {/* Agent card wrapper */}
        <CardTable />
      </div>
    </>
  )
}

export default AgentDetails
