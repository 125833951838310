import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import TabTitle from 'pages/Dashboard/components/TabTitle'
import React, { useState } from 'react'
import editIcon from 'assets/images/icons/editIcon.png'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import acceptIconImg from 'assets/images/icons/acceptIcon.png'
import { initialValues as agentInitialValues, agentProfileSchema } from '../config/agentSchema'
import { toast } from 'react-toastify'
import AgentFormWrapper from '../components/AgentFormWrapper'
import CsModal from 'utils/Modal'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { createAgent } from 'api/api'
import { lowerString } from 'utils/helpers'
import { getAgentData } from 'store/reducers/agentReducerSlice'

const AddNewAgent = () => {
  const dispatch = useDispatch()
  const [modalOpen, setModal] = useState({ open: false })
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    let obj = values
    delete obj.phoneCode
    obj.is_active = lowerString(obj?.is_active) === 'active'
    obj.postal_code = obj.postal_code.toString()
    setSubmitting(true)
    createAgent(obj)
      .then(() => {
        dispatch(getAgentData())
        setModal({ open: true })
        resetForm()
        setSubmitting(false)
        toast.success('New Agent has been added')
      })
      .catch((err) => {
        console.log(err || 'Error while creating a new Agent')
        toast.error(err.response.data.message)
        setSubmitting(false)
      })
  }

  const handleCloseModal = () => {
    setModal({ open: false })
  }
  return (
    <>
      <div>
        {/* Breadcrumbs of the page */}
        <BreadCrumbs
          navList={[
            { title: 'Home', path: '/dashboard/agents' },
            { title: 'Create agent', path: '' },
          ]}
        />
        {/* TabTitle of the page */}
        <TabTitle title={'Create agent'} icon={<img src={editIcon} alt='' />} />
        {/* Form to create a new agent */}
        <Formik
          initialValues={{
            ...agentInitialValues,
          }}
          validationSchema={Yup.object().shape({
            ...agentProfileSchema,
          })}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, handleChange, values, errors, touched }) => (
            <Form autoComplete='off'>
              <AgentFormWrapper
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                values={values}
                errors={errors}
                touched={touched}
                type='add'
              />
            </Form>
          )}
        </Formik>

        {/* Model on creating an agent */}
        <CsModal open={modalOpen.open} onClose={handleCloseModal}>
          <div className='bg-white text-center fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-[1055] w-max h-max max-h-[calc(100%-100px) ] overflow-x-hidden overflow-y-auto outline-none min-w-[550px] max-w-[730px]  rounded md:px-3 md:py-4 px-2 py-3'>
            <div className='flex justify-center items-center my-2 '>
              <span className=' flex items-center mr-2'>
                <img src={acceptIconImg} alt='' />
              </span>
              <p className='ml-1'>The agent has been created successfully</p>
            </div>
            <div className='my-2 flex flex-wrap justify-center items-center'>
              <Link to='/dashboard/agents' className='cs_btn mx_2' onClick={handleCloseModal}>
                Back to Agents
              </Link>
            </div>
          </div>
        </CsModal>
      </div>
    </>
  )
}

export default AddNewAgent
