import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from 'utils/Button'

const PageNotFound = () => {
  const navigate = useNavigate()
  return (
    <div className='grid items-center h-[100vh]'>
      <div className='flex justify-center flex-col items-center gap-3'>
        <h1 className='text-center text-4xl'>404, page not found</h1>
        <div>
          <Button onClick={() => navigate('/dashboard/merchant')}>Go back to home page</Button>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
