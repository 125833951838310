import { useState, useMemo } from 'react'
import { SortingActiveIcon, SortingIcon } from 'utils/Icons'
import { usePagination, DOTS } from 'hook/usePagination'
import classnames from 'classnames'

export const useSortableData3 = (itemsWrapper, columns, onSort) => {
  const [pageSize, setPageSize] = useState('10')
  const [currentPage, setCurrentPage] = useState(1)
  const totalLength = 100
  const [sortConfig, setSortConfig] = useState({ key: '', direction: '' })

  const sortDataEditing = (dataWrapper) => {
    return dataWrapper.data.filter((v) => v.editing || v.newRecord)
  }
  //

  const sortNotDataEditing = (dataWrapper) => {
    return dataWrapper.data.filter((v) => !v.editing && !v.newRecord)
  }

  const sortedItems = useMemo(() => {
    if (!itemsWrapper || !itemsWrapper.data) return []
    let sortableItems = [...sortNotDataEditing(itemsWrapper)]
    if (sortConfig !== null) {
      sortableItems.sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? -1 : 1
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'ascending' ? 1 : -1
        }
        return 0
      })
    }
    return [...sortDataEditing(itemsWrapper), ...sortableItems]
  }, [itemsWrapper, sortConfig])

  //

  const handleSorting = (key) => {
    onSort(key) // Pass the sorting key to the parent component
  }

  // table
  // FIXME: add columns
  const CsTable = ({ columns, children, tableClasses, hideScrollbar, hideTableHead, loading }) => {
    return (
      <div className={`cs_table_wrapper ${hideScrollbar ? ' hide-scrollbar' : ''}`}>
        <table className={`cs_table ${tableClasses}`}>
          <thead className='z-0'>
            <tr>
              {columns?.map((col, i) => (
                <th key={i}>
                  {col?.child ? (
                    <div
                      role='button'
                      onClick={() =>
                        col?.sort ? (loading ? null : handleSorting(col.title)) : null
                      }
                      disabled={loading}
                      className={`${loading ? 'cursor_not_allowed' : ''}`}
                    >
                      {col?.name}
                      {col?.sort && (
                        <div className='tbl_sort_icons flex justify-center items-center '>
                          {sortConfig?.key === col.title &&
                          sortConfig?.direction === 'ascending' ? (
                            <SortingActiveIcon />
                          ) : (
                            <SortingIcon />
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div
                      role='button'
                      onClick={() =>
                        col?.sort ? (loading ? null : handleSorting(col.title)) : null
                      }
                      disabled={loading}
                      className={`flex items-center justify-center  ${
                        col?.sort ? ' cursor-pointer' : ''
                      } ${loading ? 'cursor_not_allowed' : ''}`}
                    >
                      {col?.name}
                      {col?.sort && (
                        <div className='tbl_sort_icons flex justify-center items-center'>
                          {sortConfig?.key === col.title &&
                          sortConfig?.direction === 'ascending' ? (
                            <SortingActiveIcon />
                          ) : (
                            <SortingIcon />
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{children}</tbody>
        </table>
      </div>
    )
  }
  // table head
  const CsTableHead = ({ loading = false }) => {
    return (
      <thead>
        <tr>
          {columns?.map((col, i) => (
            <th key={i}>
              {col?.child ? (
                <div
                  role='button'
                  onClick={() => (col?.sort ? (loading ? null : handleSorting(col.title)) : null)}
                  disabled={loading}
                  className={`${loading ? 'cursor_not_allowed  text-center' : ''}`}
                >
                  {col?.name}
                  {col?.sort && (
                    <div className={`tbl_sort_icons flex justify-center items-center text-center`}>
                      {sortConfig?.key === col.title && sortConfig?.direction === 'ascending' ? (
                        <SortingActiveIcon />
                      ) : (
                        <SortingIcon />
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div
                  role='button'
                  onClick={() => (col?.sort ? (loading ? null : handleSorting(col.title)) : null)}
                  disabled={loading}
                  className={`flex justify-center items-center ${
                    col?.sort ? ' cursor-pointer' : ''
                  } ${loading ? 'cursor_not_allowed' : ''}`}
                >
                  {col?.name}
                  {col?.sort && (
                    <div className={`tbl_sort_icons flex justify-center items-center`}>
                      {sortConfig?.key === col.title && sortConfig?.direction === 'ascending' ? (
                        <SortingActiveIcon />
                      ) : (
                        <SortingIcon />
                      )}
                    </div>
                  )}
                </div>
              )}
            </th>
          ))}
        </tr>
      </thead>
    )
  }

  return {
    items: sortedItems,
    total: itemsWrapper ? itemsWrapper.total : 0,
    handleSorting,
    CsTable,
    CsTableHead,
  }
}

// pagination
export const Pagination = (props) => {
  const { onPageChange, totalCount, siblingCount = 1, currentPage, pageSize, className } = props

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  })

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null
  }

  const onNext = () => {
    onPageChange(currentPage + 1)
  }

  const onPrevious = () => {
    onPageChange(currentPage - 1)
  }

  let lastPage = paginationRange[paginationRange.length - 1]
  return (
    <ul
      className={classnames('pagination-container flex items-center w_max', {
        [className]: className,
      })}
    >
      {/* Left navigation arrow */}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className='arrow left' />
      </li>
      {paginationRange.map((pageNumber, i) => {
        // If the pageItem is a DOT, render the DOTS unicode character
        if (pageNumber === DOTS) {
          return (
            <li key={i} className='pagination-item dots'>
              &#8230;
            </li>
          )
        }

        // Render our Page Pills
        return (
          <li
            className={classnames('pagination-item', {
              selected: pageNumber === currentPage,
            })}
            onClick={() => onPageChange(pageNumber)}
            key={i}
          >
            {pageNumber}
          </li>
        )
      })}
      {/*  Right Navigation arrow */}
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <div className='arrow right' />
      </li>
    </ul>
  )
}
