import { Navigate } from 'react-router-dom'
// =========== website home page ==============
const Home = () => {
  return (
    <main>
      <Navigate to={`/login`} replace />
    </main>
  )
}

export default Home
