import { ErrorMessage, Field, Form, Formik } from 'formik'
import React from 'react'
import {
  initialValues as userInitialValues,
  schemaObject as merchantUserSchema,
} from '../config/merchantUserSchema'
import { getPhoneCode, showPhone } from 'utils/helpers'
import { countryList } from 'utils/countryList'
import * as Yup from 'yup'

const MerchentUserSetting = ({ profileData }) => {
  return (
    <div>
      <Formik
        initialValues={{
          ...userInitialValues,
        }}
        validationSchema={Yup.object().shape({
          ...merchantUserSchema,
        })}
        //   onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, handleChange, values, errors, touched }) => (
          <Form autoComplete='off'>
            <MerchantUserFormWrapper
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              values={values}
              error={errors}
              type='edit'
              touched={touched}
            />
          </Form>
        )}
      </Formik>
    </div>
  )
}

//MerchantUserFormWrapper

export const MerchantUserFormWrapper = ({ handleChange, values, type }) => {
  return (
    <>
      <div className='border rounded mb-3 mt-2'>
        <div className={` md-3 px-2 py-1 ${type === 'add' ? 'lg:mb-3 mb-2' : ''} `}>
          <h5 className='text-2xl font-bold p-2'>User Setting</h5>
          <div className='lg:mt-2 '>
            {/* 1 */}
            <div className='my-1 items-center flex flex-wrap'>
              <div className='mt-[2px] mb-[2px] items-center text-base flex-auto lg:w-[25%]   col_md_3 pl-'>
                <div className='text-right lg:ml-auto lg:pr-4 lg:flex-auto lg:w-full'>
                  <label htmlFor='name'>Name</label>
                </div>
              </div>

              <div className='mt-[2px] mb-[2px] items-center text-base flex-auto w-[33%] flex col_md_4'>
                <Field
                  type='text'
                  name='user.first_name'
                  className='w-full border items-center rounded p-1 outline-gray-300'
                />
              </div>
              <div className='flex-auto w-[41%]'>
                <ErrorMessage
                  name='user.first_name'
                  component='p'
                  className='text-red-500 text-[13px] text-start lg:ml-auto lg:pl-2'
                />
              </div>
            </div>
            {/* 2 */}
            <div className='my-1 items-center flex flex-wrap'>
              <div className='mt-[2px] mb-[2px] items-center text-base flex-auto lg:w-[25%]   col_md_3 pl-'>
                <div className='text-right lg:ml-auto lg:pr-4 lg:flex-auto lg:w-full'>
                  <label htmlFor='pppphone'>Phone</label>
                </div>
              </div>

              <div className='mt-[2px] mb-[2px] items-center text-base flex-auto w-[33%] flex col_md_4'>
                <select
                  name='user.phoneCode'
                  id='phoneCode'
                  className='cursor-pointer user-select-none outline-gray-300 mr-2 border items-center rounded p-1'
                  onChange={handleChange}
                  value={values?.user?.phoneCode || getPhoneCode(values?.user?.phone)}
                >
                  {countryList.map((v, i) => (
                    <option value={v.dial_code} key={i}>
                      {v.code + ' ' + v.dial_code}
                    </option>
                  ))}
                </select>
                <Field
                  type='text'
                  name='user.phone'
                  id='phone'
                  placeholder='Phone'
                  className='w-full border items-center rounded p-1 outline-gray-300'
                  onChange={handleChange}
                  value={showPhone(values?.user?.phoneCode, values?.user?.phone)}
                />
              </div>
              <div className='flex-auto w-[41%]'>
                <ErrorMessage
                  name='user.phone'
                  component='p'
                  className='text-red-500 text-[13px] text-start lg:ml-auto lg:pl-2'
                />
              </div>
            </div>
            {/* 3 */}
            <div className='my-1 items-center flex flex-wrap'>
              <div className='mt-[2px] mb-[2px] items-center text-base flex-auto lg:w-[25%]   col_md_3 pl-'>
                <div className='text-right lg:ml-auto lg:pr-4 lg:flex-auto lg:w-full'>
                  <label htmlFor='name'>Email</label>
                </div>
              </div>

              <div className='mt-[2px] mb-[2px] items-center text-base flex-auto w-[33%] flex col_md_4'>
                <Field
                  type='text'
                  name='user.email'
                  className='w-full border items-center rounded p-1 outline-gray-300'
                />
              </div>
              <div className='flex-auto w-[41%]'>
                <ErrorMessage
                  name='user.email'
                  component='p'
                  className='text-red-500 text-[13px] text-start lg:ml-auto lg:pl-2'
                />
              </div>
            </div>
            {/* **************************************** */}
          </div>
        </div>
      </div>
    </>
  )
}

export default MerchentUserSetting
