export const countryList = [
  {
    name: 'China',
    dial_code: '+86',
    code: 'CN',
  },
  {
    name: 'Hong Kong',
    dial_code: '+852',
    code: 'HK',
  },
]
