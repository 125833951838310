import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import TabTitle from 'pages/Dashboard/components/TabTitle'
import React from 'react'
import editIcon from 'assets/images/icons/editIcon.png'
import CardForm from './CardForm'

const CreateCard = () => {
  return (
    <div>
      {/* Page breadcrumbs */}
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '/dashboard/all-cards' },
          { title: 'Create card', path: '' },
        ]}
      />
      {/* TAB title */}
      <TabTitle title={'Create card'} icon={<img src={editIcon} alt='' />} />
      {/* Create Card form */}
      <CardForm type='add' />
    </div>
  )
}

export default CreateCard
