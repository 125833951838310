import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import TabTitle from 'pages/Dashboard/components/TabTitle'
import React, { useEffect, useState } from 'react'
import circleImgIcon from 'assets/images/icons/circleImgIcon.png'
import Button from 'utils/Button'
import moment from 'moment'
import { Form, Formik } from 'formik'
import { initialValues, profileSchema } from '../config/profileSchema'
import ProfileFormWrapper from '../components/ProfileFormWrapper'
import RelatedAgentsTable from '../components/RelatedAgentsTable'
import { editProfile, getProfileDetails } from 'api/api'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useDispatch } from 'react-redux'
import { getAllCommissionProfiles } from 'store/reducers/profilesReducerSlice'
import CsModal from 'utils/Modal'
import { formatDateTime } from 'utils/formatDate'
const ProfileDetails = () => {
  const [modalOpen, setModal] = useState({ open: false, id: '' })

  const handleCloseModal = () => {
    setModal({
      open: false,
      id: '',
    })
  }

  const dispatch = useDispatch()
  const { id } = useParams()

  const handleSubmit = (values, { setSubmitting }) => {
    let obj = values
    delete obj.id
    obj.master_agent_percent = parseInt(obj.master_agent_percent)
    obj.agent_1_percent = parseInt(obj.agent_1_percent)
    obj.agent_2_percent = parseInt(obj.agent_2_percent)
    editProfile(obj, id)
      .then((res) => {
        dispatch(getAllCommissionProfiles())
        setSubmitting(false)
        setModal({ open: true })
        toast.success('Profile has been saved')
        getProfileDetails(id)
          .then((res) => {
            setprofileDetails(res || initialValues)
          })
          .catch((err) => {
            toast.error(err.message || "Could'nt able to fetch data")
          })
      })
      .catch((err) => {
        setSubmitting(false)
        console.log(err)
        toast.error(err.message || 'Something went Wrong')
      })
  }
  const [profileDetails, setprofileDetails] = useState(initialValues)
  useEffect(() => {
    const getDetails = () => {
      getProfileDetails(id)
        .then((res) => {
          setprofileDetails(res || initialValues)
        })
        .catch((err) => {
          toast.error(err.message || "Could'nt able to fetch data")
        })
    }
    getDetails()
    return () => {
      getDetails()
    }
  }, [id])

  return (
    <>
      <div>
        {/* BreadCrumbs */}
        <BreadCrumbs
          navList={[
            { title: 'Home', path: '/dashboard/commision-profile' },
            { title: 'Profile details', path: '' },
          ]}
        />
        {/* Page top details */}
        <div className='flex flex-wrap items-start'>
          <div className='flex items-center'>
            <TabTitle
              title={profileDetails?.profile_name}
              icon={<img src={circleImgIcon} alt='' />}
            />

            <div className='text-sm font-medium text-gray-400 ml-6'>
              <span className='px-2'>ID:# {profileDetails?.id}</span>
            </div>
          </div>
          <div className='ml-auto flex flex-wrap items-end'>
            <div>
              <div className='lg:mr-2 text-gray-500 flex items-center justify-end'>
                <div className=''>
                  <span className='text-sm '> Created at</span>
                </div>
                <div className='min-w-[130px] text-end'>
                  <span className='text-sm'>
                    {profileDetails?.created_at
                      ? formatDateTime(profileDetails.created_at)
                      : 'Not available'}
                  </span>
                </div>
              </div>
              <div className='lg:mr-2 text-gray-500 flex items-center justify-end'>
                <div className=''>
                  <span className='text-sm'> Last modified</span>
                </div>
                <div className='min-w-[130px] text-end'>
                  <span className='text-sm'>
                    {profileDetails?.last_updated
                      ? formatDateTime(profileDetails.last_updated)
                      : 'Not available'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* ------------------------------- */}
        <Formik
          initialValues={profileDetails || initialValues}
          validationSchema={profileSchema}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ isSubmitting, handleChange, values, errors, touched }) => (
            <Form autoComplete='off'>
              <ProfileFormWrapper
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                values={values}
                errors={errors}
                touched={touched}
                type='edit'
              />
            </Form>
          )}
        </Formik>

        {/* Related Agents table */}
        <RelatedAgentsTable data={profileDetails} />
      </div>
      <CsModal open={modalOpen.open} onClose={handleCloseModal}>
        <div className='bg-white text-center  cs_modal rounded md:px-3 md:py-4 px-2 py-3'>
          <div className='flex content-center items-center my-2'>
            <span className='mr-2 flex items-center'>
              {/* <img src={acceptIconImg} alt="" /> */}
            </span>
            <p className='ml-1'>Profile has been updated successfully</p>
          </div>
          <div className='my-2 flex flex-wrap justify-center items-center'>
            <button className='cs_btn mx-2' onClick={handleCloseModal}>
              Okay
            </button>
          </div>
        </div>
      </CsModal>
    </>
  )
}

export default ProfileDetails
