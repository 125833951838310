import { ErrorMessage, Field } from 'formik'
import { useCancelablePromises } from 'hook/useCancelablePromises'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import Button from 'utils/Button'
import { countryList } from 'utils/countryList'
import { getPhoneCode, showPhone } from 'utils/helpers'
import Select from 'react-select'

const UsersFormWrapper = ({ isSubmitting, handleChange, values, type }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [selectedOptions, setSelectedOptions] = useState([null, null])

  const dropdowns = [{ label: 'Role', selectedOption: selectedOptions[0] }]
  // data for dropdown options
  const options = [
    { value: 'SUPER_ADMIN', label: 'SUPER_ADMIN' },
    { value: 'MERCHANT_ADMIN', label: 'MERCHANT_ADMIN' },
    // Add more options here
  ]

  // function for handling dropdown select option

  const handleSelectOption = (index, selectedOption) => {
    const newSelectedOptions = [...selectedOptions]
    newSelectedOptions[index] = selectedOption
    setSelectedOptions(newSelectedOptions)
    handleChange({ target: { name: 'role', value: selectedOption.value } })
  }

  return (
    <>
      <div className='border rounded mb-3 mt-4 md:mt-9'>
        <div className={`md:px-3 px-2 py-1 ${type === 'add' ? 'md:mb-3 mb-2' : ''}`}>
          <div className='md:mt-2'>
            {/* 1 */}
            <div className='flex flex-wrap my-1 items-center'>
              <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                <label htmlFor='name'>
                  First Name<b className='text-red-600'>*</b>
                </label>
              </div>
              <div className='col_ col_md_4 col_12 flex items-center'>
                <div className='w-full flex items-center'>
                  <Field
                    type='text'
                    name='first_name'
                    id='name'
                    placeholder='First name'
                    className='w-full rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  />
                </div>
              </div>
              <div className='col_md_5 col_12'>
                <ErrorMessage
                  name='first_name'
                  component='p'
                  className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                />
              </div>
            </div>
            {/* 2 */}
            <div className='flex flex-wrap md:my-0 my-1 items-center'>
              <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                <label htmlFor='last_name'>
                  last Name<b className='text-red-600'>*</b>{' '}
                </label>
              </div>
              <div className='col_ col_md_4 col_12 flex item-center'>
                <div className='w-full flex items-center'>
                  <Field
                    type='text'
                    name='last_name'
                    id='last_name'
                    placeholder='Last Name'
                    className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  />
                </div>
              </div>
              <div className='col_md_5 col_12'>
                <ErrorMessage
                  name='last_name'
                  component='p'
                  className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                />
              </div>
            </div>
            <div className='flex flex-wrap my-1 items-center'>
              <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                <label htmlFor='email'>
                  Email<b className='text-red-600'>*</b>
                </label>
              </div>
              <div className='col_ col_md_4 col_12 flex items-center'>
                <div className='w-full flex items-center'>
                  <Field
                    type='email'
                    name='email'
                    id='email'
                    className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                  />
                </div>
              </div>
              <div className='col_md_5 col_12'>
                <ErrorMessage
                  name='email'
                  component='p'
                  className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                />
              </div>
            </div>
            {/* 9 */}
            <div className='flex flex-wrap my-1 items-center'>
              <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                <label htmlFor='phone'>Phone</label>
              </div>
              <div className='col_ col_md_4 col_12 flex items-center'>
                <div className='w-full flex items-center'>
                  <select
                    name='phoneCode'
                    id='phoneCode'
                    className='cursor-pointer select-none me_2   rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                    onChange={handleChange}
                    value={values?.phoneCode || getPhoneCode(values.phone)}
                  >
                    {countryList.map((v, i) => (
                      <option value={v.dial_code} key={i}>
                        {v.code + ' ' + v.dial_code}
                      </option>
                    ))}
                  </select>
                  <Field
                    type='text'
                    name='phone'
                    id='phone'
                    placeholder='Phone'
                    className='w-full  rounded px-[15px] py-[3px] max-w-full h-auto min-h-auto text-base outline-gray-300 border-gray-300 border text-black m-1 placeholder:text-gray-500'
                    onChange={handleChange}
                    value={showPhone(values.phoneCode, values.phone)}
                  />
                </div>
              </div>
              <div className='col_md_5 col_12'>
                <ErrorMessage
                  name='phone'
                  component='p'
                  className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                />
              </div>
            </div>
            {/* 10 */}
            <>
              <div>
                {dropdowns.map((dropdown, index) => (
                  <div key={index} className='flex flex-wrap my-1 items-center mb-3'>
                    <div className='col_md_3 col_12 md:ml-auto pr-3 md:text-end'>
                      <label htmlFor='business_registration'>
                        {dropdown.label}
                        <b className='text-red-600'>*</b>
                      </label>
                    </div>
                    <Field name='role'>
                      {({ field }) => (
                        <div className='col_ col_md_4 col_12 flex items-center'>
                          <div className='w-full flex items-center'>
                            {/* Select dropdown for commision profile */}
                            <Select
                              className='react-select-container'
                              {...field}
                              value={
                                options.find((option) => option.value === values.role) ||
                                dropdown.selectedOption
                              }
                              onChange={(selectedOption) =>
                                handleSelectOption(index, selectedOption)
                              }
                              options={options}
                              isSearchable={true}
                              placeholder={`Select an option`}
                            />
                          </div>
                        </div>
                      )}
                    </Field>
                    <div className='col_md_5 col_12'>
                      <ErrorMessage
                        name='role'
                        component='p'
                        className='text-gray-400 w-full text-start md:ml-auto md:pl-2'
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          </div>
          {type === 'edit' ? (
            <>
              <div className='md:my-3 flex justify-end items-center my-2'>
                <Button
                  disabled={isSubmitting}
                  onClick={() => navigate('/dashboard/setting/users')}
                  classes='mx-1'
                  type='button'
                >
                  Cancel
                </Button>
                <Button
                  disabled={isSubmitting}
                  classes='text-white ml-1 bg-purple-500 border-none rounded'
                  type='submit'
                >
                  {isSubmitting ? 'Saving..' : 'Save'}
                </Button>
              </div>
            </>
          ) : (
            <div className='md:my-3 flex justify-center items-center my-2'>
              <Button
                disabled={isSubmitting}
                onClick={() => navigate('/dashboard/setting/users')}
                classes='mx-1'
                type='button'
              >
                Cancel
              </Button>
              <Button
                disabled={isSubmitting}
                classes='text-white ml-1 bg-purple-500 border-none rounded'
                type='submit'
              >
                {location.pathname === '/dashboard/setting/users/details/'
                  ? 'Save'
                  : isSubmitting
                  ? 'Creating..'
                  : 'Create'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default UsersFormWrapper
