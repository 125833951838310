import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import TabTitle from 'pages/Dashboard/components/TabTitle'
import Button from 'utils/Button'
import editIcon from 'assets/images/icons/editIcon.png'
import CsModal from 'utils/Modal'
import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import acceptIconImg from 'assets/images/icons/acceptIcon.png'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { MerchantProfileFormWrapper } from '../components/MerchantProfile'
import {
  initialValues as merchantInitialValue,
  schemaObject as merchantSchema,
} from '../config/merchantUserSchema'
import { MerchantPaySettingWrapper } from '../components/MerchantPaymentSetting'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { MerchantUserFormWrapper } from '../components/MerchentUserSetting'
import { getMerchantData } from 'store/reducers/merchantReducerSlice'
import { postMerchant } from 'api/api'
import { lowerString } from 'utils/helpers'

// ******************  Add new merchant page ***********************
const MerchantAddNew = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  // modal state
  const [modalOpen, setModal] = useState({ open: false })

  // create a new merchant
  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setSubmitting(true)
    let obj = values
    obj.payin_daily_limit = parseInt(obj.payin_daily_limit)
    obj.payout_daily_limit = parseInt(obj.payout_daily_limit)
    obj.postal_code = obj.postal_code.toString()
    delete obj.phoneCode
    delete obj.user.phoneCode

    obj.user = {
      ...obj.user,
      last_name: '',
      is_active: lowerString(obj?.user?.is_active) === 'active',
    }
    obj.is_active = lowerString(obj?.is_active) === 'active'
    postMerchant(obj)
      .then(() => {
        dispatch(getMerchantData('?take=10&skip=0'))
        setModal({ open: true })
        resetForm()
        setSubmitting(false)
      })
      .catch((err) => {
        console.log('Error while creating a new merchant', err)
        toast.error('Failed') //TODO: revert
        setSubmitting(false)
      })
  }

  // handle close modal
  const handleCloseModal = () => {
    setModal({ open: false })
  }

  return (
    <div>
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '/dashboard/merchant' },
          { title: 'Merchent', path: '/dashboard/merchant/' },
          { title: 'Create New Merchant', path: 'merchant/addNew' },
        ]}
      />
      <TabTitle title={'Create merchant'} icon={<img src={editIcon} alt='' />} />
      {/* all forms */}
      <Formik
        initialValues={{
          ...merchantInitialValue,
        }}
        validationSchema={Yup.object().shape({
          ...merchantSchema,
        })}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting, handleChange, values, errors, touched }) => (
          <Form autoComplete='off'>
            {/* ************** profile form ********** */}
            <MerchantProfileFormWrapper
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              type='add'
            />
            {/*/ ********************user setting****************** */}
            <MerchantUserFormWrapper
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              type='add'
            />
            {/* ************** Payment settings form ********** */}
            <MerchantPaySettingWrapper
              isSubmitting={isSubmitting}
              handleChange={handleChange}
              values={values}
              type='add'
            />
            {/* ************* submit form *********** */}
            <div className='md:mt-3 flex justify-center items-center my-2 gap-5'>
              <Button
                disabled={isSubmitting}
                onClick={() => navigate('/dashboard/merchant')}
                classes='mx-1'
                type='button'
              >
                Cancel
              </Button>
              <Button
                disabled={isSubmitting}
                classes='text-white ml-1 bg-purple-500 border-none rounded'
                type='submit'
              >
                {isSubmitting ? 'Creating..' : 'Create'}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      {/* *************************************** */}
      {/* ******* Modal  */}
      <CsModal open={modalOpen.open} onClose={handleCloseModal}>
        <div className='bg-white text-center fixed top-[50%] left-[50%] -translate-x-[50%] -translate-y-[50%] z-[1055] w-max h-max max-h-[calc(100%-100px) ] overflow-x-hidden overflow-y-auto outline-none min-w-[550px] max-w-[730px]  rounded md:px-3 md:py-4 px-2 py-3'>
          <div className='flex justify-center items-center my-2 '>
            <span className=' flex items-center mr-2'>
              <img src={acceptIconImg} alt='' />
            </span>
            <p className='ml-1'>The merchant has been updated successfully</p>
          </div>
          <div className='my-2 flex flex-wrap justify-center items-center'>
            <Link to='/dashboard/merchant' className='cs_btn mx_2' onClick={handleCloseModal}>
              Back to Merchant
            </Link>
          </div>
        </div>
      </CsModal>
      {/* ************** */}
    </div>
  )
}

export default MerchantAddNew
