import { Link } from 'react-router-dom'
import './dashboard-components.css'

const BreadCrumbs = ({ navList = [] }) => {
  return (
    <div className='breadcrumbs-container'>
      {navList?.map((item, i) =>
        navList?.length === i + 1 ? (
          <span key={i} className='breadcrumb-item text-gray-400'>
            {item.title}
          </span>
        ) : (
          <Link key={i} to={item.path} className='breadcrumb-item'>
            {item.title} <b className='breadcrumb-separator'>{'>'}</b>
          </Link>
        ),
      )}
    </div>
  )
}

export default BreadCrumbs
