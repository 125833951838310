import axios from 'axios'

const getPayouts = async (take, skip, sort) => {
  const res = await axios.get(`/payouts?take=${take}&skip=${skip}&sort=${sort}`)
  return res.data
}
const getPayoutDetails = async (id) => {
  const res = await axios.get(`/payouts/${id}`)
  return res.data
}
const createPayout = async (req) => {
  return await axios.post('/payouts', req)
}
const createPayoutMany = async (req) => {
  return await axios.post('/payouts/create-many', req)
}

const createPayoutRemark = async (id, req) => {
  return await axios.post(`/payouts/${id}/add-remark`, req)
}

const doUpdateAuditStatus = async (id, auditStatus) => {
  return await axios.patch(`/payouts/${id}/audit-status?auditStatus=${auditStatus}`)
}

const doUpdatePayoutStatus = async (id, payoutStatus, req) => {
  return await axios.patch(`/payouts/${id}/payout-status?payoutStatus=${payoutStatus}`, req)
}

const doUploadPayoutManyFile = async (req) => {
  return await axios.post(`/payouts/check-data`, req)
}

export {
  getPayouts,
  getPayoutDetails,
  createPayout,
  createPayoutMany,
  createPayoutRemark,
  doUpdateAuditStatus,
  doUpdatePayoutStatus,
  doUploadPayoutManyFile,
}
