import React, { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import { toast } from 'react-toastify'

import {
  getPayoutDetails,
  createPayoutRemark,
  doUpdateAuditStatus,
  doUpdatePayoutStatus,
} from 'api/RESTful/payout'
import { getUserDetails } from 'api/RESTful/user'

import { CloseIcon } from 'utils/Icons'
import recieved from 'assets/images/icons/inboxIcon.png'
import { RiArrowUpCircleFill } from 'react-icons/ri'

import BreadCrumbs from 'features/Dashboard/components/BreadCrumbs'
import Button from 'utils/Button'

import { formatAmount } from 'utils/formatAmount'
import { formatDateTime } from 'utils/formatDate'

const PayoutDetails = () => {
  const { payoutID } = useParams()
  const [payoutDetails, setPayoutDetails] = useState([])
  const [remarkText, setRemarkText] = useState('')
  const [userDetails, setUserDetails] = useState({})
  const [proofFile, setProofFile] = useState(null)

  const payoutDetailsCustomerData = [
    { title: 'Client Name', key: 'client_name' },
    { title: 'Bank Account Number', key: 'bank_account_number' },
    { title: 'Province', key: 'province' },
    { title: 'Bank Name', key: 'bank_name' },
    { title: 'Branch', key: 'branch' },
    { title: 'City', key: 'city' },
  ]
  const payoutDetailsMerchantData = [
    { title: 'Payment Method Bank', key: 'payment_method_bank' },
    { title: 'Payment Method Account Number', key: 'payment_method_bank_account_number' },
    { title: 'Payment Method Currency', key: 'payment_method_currency' },
    { title: 'Payment Method Type', key: 'payment_method_type' },
  ]

  const componentsBelowPayout = [
    { title: 'audit approval', type: 'A' },
    { title: 'payout completion', type: 'B' },
    { title: 'remark', type: 'C' },
  ]
  const payoutAuditStatusData = [
    { title: 'Audit Status', key: 'audit_status' },
    { title: 'Reviewed Datetime', key: 'audit_approved_datetime' },
    { title: 'Reviewed By', key: 'approved_by' },
  ]
  const payoutPayoutStatusData = [
    { title: 'Payout Status', key: 'payout_status' },
    { title: 'Submitted Datetime', key: 'completed_datetime' },
    { title: 'Submitted By', key: 'confirmed_by' },
    { title: 'Payout Proof', key: '' },
  ]
  const handleCreateRemark = async () => {
    if (!remarkText.trim()) {
      toast.error('Please enter a remark.')
      return
    }

    try {
      const payload = { remark: remarkText }
      await createPayoutRemark(payoutID, payload)
      setRemarkText('')
      toast.success('Remark added successfully', {
        onClose: () => window.location.reload(),
      })
    } catch (error) {
      toast.error('Failed to add remark')
    }
  }

  const handleUpdateAuditStatus = async (status) => {
    try {
      await doUpdateAuditStatus(payoutID, status)
      toast.success(`Audit status updated to ${status}`, {
        onClose: () => window.location.reload(),
      })
    } catch (error) {
      toast.error('Failed to update audit status')
    }
  }

  const handleUpdatePayoutStatus = async (status) => {
    if (!proofFile && status === 'COMPLETED') {
      toast.error('Please upload proof to complete the payout')
      return
    }

    const formData = new FormData()
    if (proofFile) {
      formData.append('file', proofFile, proofFile.name)
    }

    try {
      await doUpdatePayoutStatus(payoutID, status, formData)
      toast.success(`Payout status updated to ${status}`, {
        onClose: () => window.location.reload(),
      })
    } catch (error) {
      toast.error('Failed to update payout status')
    }
  }

  useEffect(() => {
    const fetchPayoutDetails = async () => {
      try {
        const response = await getPayoutDetails(payoutID)
        setPayoutDetails(response)

        // Fetch user details for each remark
        const userDetailsPromises = response.remark.map((remark) => getUserDetails(remark.user_id))
        const users = await Promise.all(userDetailsPromises)
        const userDetailsMap = users.reduce((acc, user, index) => {
          acc[response.remark[index].user_id] = user
          return acc
        }, {})
        setUserDetails(userDetailsMap)
      } catch (error) {
        toast.error(error)
      }
    }
    fetchPayoutDetails()
  }, [payoutID])

  return (
    <div>
      {/* BreadCrumbs */}
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '/dashboard/payment' },
          { title: 'Payout', path: '/dashboard/payout' },
          { title: 'Payout Details', path: '' },
        ]}
      />
      {/* Top Details */}
      <div>
        <h5 className='text-gray-400 font-normal text-2xl'>Payout# {payoutDetails.id}</h5>
        <div className='cs_row'>
          <div className='flex mr-2 flex-wrap items-center'>
            <h4 className='font-normal mr-2 text-3xl'>
              <span className='text-gray-400'>{payoutDetails?.payment_method_currency}</span>
              {` `}
              {formatAmount(payoutDetails?.amount)}
            </h4>
            <p
              className='bg-[#D9D9D9] px-2 py-1 rounded text-gray-400'
              style={{ fontSize: '13px' }}
            >
              {payoutDetails?.payout_status}
            </p>
          </div>
        </div>
      </div>
      {/* Top Table */}
      {/* *********************** */}
      <div className='my-6 cs_row py-3 border-t-2 border-gray-400'>
        <div className='md:pr-6 px-0  border-r-2 p-4'>
          <span className='text-gray-400'>Last Updated</span>
          <p className='font-medium'>{formatDateTime(payoutDetails.last_updated)}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Merchant</span>
          <p className='text-[#499CD6] font-medium'>{payoutDetails?.merchant_id}</p>
        </div>
        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Customer</span>
          <p className='font-medium'>{payoutDetails?.customer_name || 'N/A'}</p>
        </div>

        <div className='md:px-6 px-2  border-r-2 p-4'>
          <span className='text-gray-400'>Card ID</span>
          <p className='text-[#499CD6] font-medium'>
            <Link
              to={`/dashboard/all-cards/details/${payoutDetails?.card_id}`}
              className='text-[#499CD6] font-medium'
            >
              {payoutDetails?.card_id}
            </Link>
          </p>
        </div>
      </div>
      {/* Payout Details */}
      <div className='my-8'>
        <h5 className='text-2xl font-semibold border-b-2 pb-2'>Payout Details</h5>
        <div className='mt-[20px] three-cols'>
          {payoutDetails.payout_to === 'CUSTOMER' &&
            payoutDetailsCustomerData.map((payout, index) => (
              <div key={index}>
                <span className='text-gray-400'>{payout.title}</span>
                <p className='font-medium'>{payoutDetails[payout.key] || 'N/A'}</p>
              </div>
            ))}
          {payoutDetails.payout_to === 'MERCHANT' &&
            payoutDetailsMerchantData.map((payout, index) => (
              <div key={index}>
                <span className='text-gray-400'>{payout.title}</span>
                <p className='font-medium'>{payoutDetails[payout.key] || 'N/A'}</p>
              </div>
            ))}
        </div>
      </div>
      {/* ---------------------------------------- */}
      {/* Compoenents for payoutdetails */}
      <div>
        {componentsBelowPayout.map((payout, index) => (
          <div
            className='mb-[20px] pl-[33px] pr-[31px] pt-[25px] min-h-[160px] border-[1px] rounded-[5px] border-gray-400'
            key={index}
          >
            <div className='flex items-center justify-between flex-wrap gap-2'>
              {payout.type === 'A' && (
                <div>
                  <h5 className='text-2xl font-semibold capitalize'>{payout.title}</h5>
                  {['PENDING', 'PROCESSING'].includes(payoutDetails.audit_status) && (
                    <div className='mt-5 flex flex-wrap  gap-6'>
                      <Button
                        onClick={() => handleUpdateAuditStatus('APPROVED')}
                        classes='mr-2 px-2 w-[128px]'
                      >
                        <span className='mr-2 flex items-center'>
                          <img src={recieved} alt='' />
                        </span>
                        approve
                      </Button>
                      <Button onClick={() => handleUpdateAuditStatus('REJECTED')} classes='px-2'>
                        <span className='mr-2 flex items-center'>
                          <CloseIcon />
                        </span>
                        reject
                      </Button>
                    </div>
                  )}
                  {['APPROVED', 'REJECTED'].includes(payoutDetails.audit_status) && (
                    <div className='mt-5 flex flex-wrap items-center gap-10'>
                      {payoutAuditStatusData.map((item, idx) => (
                        <div key={idx}>
                          <span className='text-gray-400'>{item.title}</span>
                          <p className='font-medium'>
                            {item.key === 'audit_approved_datetime' ||
                            item.key === 'completed_datetime'
                              ? formatDateTime(payoutDetails[item.key]) || 'N/A'
                              : item.key === 'approved_by' || item.key === 'confirmed_by'
                              ? `${payoutDetails[item.key].first_name} ${
                                  payoutDetails[item.key].last_name
                                }`
                              : payoutDetails[item.key] || 'N/A'}
                          </p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
              {payout.type === 'B' && (
                <div>
                  <h5 className='text-2xl font-semibold capitalize'>{payout.title}</h5>
                  {payoutDetails.audit_status === 'APPROVED' &&
                    ['PENDING', 'PROCESSING'].includes(payoutDetails.payout_status) && (
                      <div className='flex flex-wrap items-center gap-6 mt-4'>
                        <label className='capitalize' htmlFor='proofInput'>
                          Upload proof
                        </label>
                        <input
                          type='file'
                          id='proofInput'
                          onChange={(e) => setProofFile(e.target.files[0])}
                          className='w-100 border focus:outline-none m-1 ml-3 p-[2px] rounded pl-3'
                        />
                        <Button
                          onClick={() => handleUpdatePayoutStatus('COMPLETED')}
                          classes='mr-2 px-2 w-[128px]'
                        >
                          <span className='mr-2 flex items-center'>
                            <img src={recieved} alt='' />
                          </span>
                          completed
                        </Button>
                        <Button onClick={() => handleUpdatePayoutStatus('REJECTED')} classes='px-2'>
                          <span className='mr-2 flex items-center'>
                            <CloseIcon />
                          </span>
                          reject
                        </Button>
                      </div>
                    )}
                  {['COMPLETED', 'REJECTED'].includes(payoutDetails.payout_status) && (
                    <div className='mt-5 flex flex-wrap items-center gap-10'>
                      {payoutPayoutStatusData.map((item, idx) => (
                        <div key={idx} className='flex flex-col'>
                          <span className='text-gray-400'>{item.title}</span>
                          <div className='font-medium'>
                            {item.title === 'Payout Proof' ? (
                              payoutDetails.payout_proof ? (
                                <button
                                  onClick={() => window.open(payoutDetails.payout_proof, '_blank')}
                                  className='text-blue-500 hover:text-blue-700' // Here you apply the color blue and a darker blue on hover
                                >
                                  View
                                </button>
                              ) : (
                                'N/A' // Display 'N/A' if there is no payout proof
                              )
                            ) : item.key === 'audit_approved_datetime' ||
                              item.key === 'completed_datetime' ? (
                              formatDateTime(payoutDetails[item.key]) || 'N/A'
                            ) : item.key.includes('approved_by') ||
                              item.key.includes('confirmed_by') ? (
                              `${payoutDetails[item.key].first_name} ${
                                payoutDetails[item.key].last_name
                              }`
                            ) : (
                              payoutDetails[item.key] || 'N/A'
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            {payout.type === 'C' && (
              <div className='mt-5'>
                <h5 className='mb-5 text-2xl font-semibold capitalize'>{payout.title}</h5>
                {/* Check if there are any remarks */}
                {payoutDetails.remark && payoutDetails.remark.length > 0 ? (
                  payoutDetails.remark.map((remark, index) => (
                    <div key={index} className='flex items-center flex-wrap gap-10 mb-5'>
                      {/* Remark details */}
                      <div className='capitalize'>
                        <p>
                          {userDetails[remark.user_id]
                            ? `${userDetails[remark.user_id].first_name} ${
                                userDetails[remark.user_id].last_name
                              }`
                            : 'Loading...'}
                        </p>
                        <p>{formatDateTime(remark.created_at)}</p>
                      </div>
                      <p>{remark.remark}</p>
                    </div>
                  ))
                ) : (
                  <p>No records</p> // Displayed when there are no remarks
                )}

                {/* Input for new remark */}
                <div className='relative min-h-[160px] w-full border-[1px] rounded-[5px] border-gray-400 p-2 mb-10'>
                  <textarea
                    className='relative min-h-[160px] w-full border-[1px] rounded-[5px] border-gray-400 p-2 mb-10'
                    value={remarkText}
                    onChange={(e) => setRemarkText(e.target.value)}
                  />
                  <RiArrowUpCircleFill
                    onClick={handleCreateRemark} // You need to implement this function
                    className='text-purple-500 cursor-pointer'
                    style={{
                      position: 'absolute',
                      right: '10px',
                      bottom: '10px',
                      fontSize: '40px',
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default PayoutDetails
