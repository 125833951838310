import { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import { setAuth } from 'store/reducers/authReducerSlice'
import { useLocalStorage } from 'hook/useLocalStorage'

import Icon from 'common/components/Icons'

import { NavBar } from '../../features/Dashboard/components/NavBar'
import { capitalizeString } from 'utils/helpers'

import './dashboard.css'

const Dashboard = () => {
  // FIXME: move this to constant
  const navbarData = [
    {
      icon: <Icon name='merchantNavBar' size='16px' />,
      path: 'merchant',
      title: 'Merchant',
    },
    {
      icon: <Icon name='agentNavBar' size='18px' />,
      path: 'agents',
      title: 'agent',
    },
    {
      icon: <Icon name='commissionProfileNavBar' size='18px' />,
      path: 'commision-profile',
      title: 'commission profile',
    },
    {
      icon: <Icon name='cardNavBar' size='18px' />,
      path: 'all-cards',
      title: 'card',
    },
    {
      icon: <Icon name='orderNavBar' size='18px' />,
      path: 'orders',
      title: 'order',
    },
    {
      icon: <Icon name='paymentNavBar' size='18px' />,
      path: 'payment',
      title: 'payment',
    },
    {
      icon: <Icon name='userNavBar' size='18px' />,
      path: 'setting/users',
      title: 'user',
    },
    {
      icon: <Icon name='walletNavBar' size='18px' />,
      path: 'wallet',
      title: 'wallet',
    },
    {
      icon: <Icon name='payoutNavBar' size='18px' />,
      path: 'payout',
      title: 'payout',
    },
    {
      icon: <Icon name='changePasswordNavBar' size='18px' />,
      path: 'change-password',
      title: 'change password',
    },
  ]

  const dispatch = useDispatch()
  const { data: user } = useSelector((store) => store.auth)
  const { pathname } = useLocation()
  const element = useRef(null)
  const { setLocalVal } = useLocalStorage('picotop_user_token', '')
  const { setLocalVal: setLocalUserId } = useLocalStorage('picotop_user_id', '')
  const { setLocalVal: setLocalUserEmail } = useLocalStorage('picotop_user_email', '')

  const [menuOpen, setMenuOpen] = useState(false)

  const closeMenu = () => {
    setMenuOpen(false)
  }

  const logout = () => {
    setLocalVal('')
    setLocalUserId('')
    setLocalUserEmail('')
    dispatch(
      setAuth({
        isAuthenticated: false,
        isLoading: false,
        data: {},
      }),
    )
    toast.success('Logged out successfully!')
  }

  useEffect(() => {
    element?.current?.scrollTo(0, 0)
  }, [pathname])

  return (
    <main className='dashboard_main flex mb-6 md:mb-0'>
      {/* Dashboard sidebar  */}
      <nav className={`dashboard_nav flex flex-col ${menuOpen ? ' opened' : ''} hide-scrollbar`}>
        <div className='dashboard_logo select-none flex flex-col'>
          <h4>PICOTOP</h4>
          <div className='cs_chip bg-[#31B167] text-white mx_auto bg_primary text_white '>
            Administrator
          </div>
          <div
            onClick={closeMenu}
            role='button'
            tabIndex='0'
            className='menu_close_btn flex-col  justify-between cursor-pointer select-none'
          ></div>
        </div>
        <div className='dashboard_menu hide-scrollbar'>
          {/* **** Merchant tabs **** */}
          {navbarData.map((sidebar) => (
            <NavBar
              key={sidebar.path}
              icon={sidebar.icon}
              path={sidebar.path}
              title={sidebar.title}
              onClick={closeMenu}
            />
          ))}
        </div>
      </nav>
      {/* Dashboard tabs and header wrapper  */}
      <aside className='dashboard_aside flex flex-col'>
        {/* here is the header of the dashboard*/}
        <header className='dashboard_header flex items-center'>
          <div
            onClick={() => setMenuOpen(true)}
            role='button'
            tabIndex='0'
            className='hamburg_icon flex-col   justify-between cursor-pointer select-none '
          ></div>
          <div className='ml-auto profile_name flex items-center justify-center'>
            <div className='avatar bg-orange-500 rounded-full text-white font-bold select-none flex items-center justify-center'>
              <span>{capitalizeString(user?.email?.toString()?.slice(0, 2))}</span>
            </div>
            <button className='cs_btn'>My Account</button>
            <button className='cs_btn' onClick={logout}>
              Logout
            </button>
          </div>
        </header>
        {/* this is main wrapper for all tabs that will nested in dashboard */}
        <section ref={element} className='dashboard_section_wrapper'>
          <Outlet />
        </section>
      </aside>
      {/* backdrop blur  */}
      {menuOpen && <div onClick={closeMenu} className='dashboard_backdrop'></div>}
    </main>
  )
}

export default Dashboard
