import axios from 'axios'

const doSendOtp = async () => {
  return await axios.post('/auth/otp/send')
}

const doVerifyOtp = async (req) => {
  return await axios.post('/auth/otp/verify', req)
}

export { doSendOtp, doVerifyOtp }
