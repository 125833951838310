import { useSortableData } from 'pages/Dashboard/components/useSortableData'
import React from 'react'
import { IndicatorIcon } from 'utils/Icons'
import Spinner from 'utils/Spinner'
import moment from 'moment'
// TODO: add hook to call assigned agents

const RelatedAgentsTable = (data) => {
  let relatedAgentsData = []

  if (data.data.agent) {
    relatedAgentsData = data.data.agent
  }

  const relatedAgentsCol = [
    { name: '#' },
    { name: 'Agent ID', sort: true },
    { name: 'Agent Name', sort: true },
    { name: 'Status' },
    { name: 'Last Updated', sort: true },
  ]
  const isLoading = false
  //   table hook

  const { items: tblData, CsTable } = useSortableData(relatedAgentsData, relatedAgentsCol)
  return (
    <div className='mt-20 mb-12'>
      <CsTable loading={isLoading} tableClasses='header_fill'>
        <tr>
          {relatedAgentsCol.map((col, index) => (
            <th key={index}>{col.name}</th>
          ))}
        </tr>
        {!isLoading ? (
          tblData?.length > 0 ? (
            tblData.map((item, index) => (
              <tr key={index}>
                <td className='text-center'>{index + 1}</td>
                <td className='text-center'>{item?.id}</td>
                <td className='text-center'>{item?.name}</td>
                <td className='text-center'>
                  <div className='flex items-center justify-center'>
                    <span className='mr-2'>
                      <IndicatorIcon type={item?.is_active ? 'active' : 'inactive'} />
                    </span>
                    <p className='text_sm'>{item?.is_active ? 'Active' : 'Inactive'}</p>
                  </div>
                </td>
                <td className='text-center'>
                  {item?.last_updated ? moment(item?.last_updated).format('DD/MM/YYYY HH:MM') : '-'}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan='5' className='text-center'>
                {' '}
                {/* Updated line */}
                <div className='my-4 mx-auto'>
                  <p>No records</p>
                </div>
              </td>
            </tr>
          )
        ) : (
          <tr>
            <td colSpan='5'>
              <div className='my-4 mx-auto text-center'>
                <Spinner />
              </div>
            </td>
          </tr>
        )}
      </CsTable>
    </div>
  )
}

export default RelatedAgentsTable
