import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

import { getWalletDetails } from 'api/RESTful/wallet'
import { getOrders } from 'api/RESTful/order'

import walletIcon from '../../../../assets/images/icons/walletIcon.png'

import BreadCrumbs from '../../../../features/Dashboard/components/BreadCrumbs'
import Card from '../../components/Card'
import TabTitle from '../../components/TabTitle'
import { useSortableData } from '../../components/useSortableData'

import { formatDateTime } from 'utils/formatDate'
import { formatAmount } from 'utils/formatAmount'
import Spinner from 'utils/Spinner'

const WalletDetails = () => {
  const walletDetailsColumn = [
    { name: 'Date', sort: true },
    { name: 'Order ID', sort: true },
    { name: 'Type' },
    { name: 'Currency' },
    { name: 'Amount' },
    { name: 'Status' },
    { name: 'Last Updated' },
  ]
  const { id } = useParams()
  const [walletDetails, setWalletDetails] = useState([])
  const [walletListData, setWalletListData] = useState([])
  const [isLoading, setIsLoading] = useState(false)

  // FIXME: should fetch by card id, agent id and merchant id
  // const fetchOrderDetails = async () => {
  //   try {
  //     setIsLoading(true)
  //     const response = await getOrders(10, 0)
  //     const totalOrders = response.total
  //     const totalOrdersResponse = await getOrders(totalOrders, 0)
  //     setWalletListData(totalOrdersResponse.data)
  //   } catch (error) {
  //     toast.error(error.message || "Cannot get wallet's orders, Please try again.")
  //   } finally {
  //     setIsLoading(false)
  //   }
  // }
  const fetchWalletDetails = (id) => {
    getWalletDetails(id)
      .then((res) => {
        setWalletDetails(res)
      })

      .catch((err) => {
        console.log(err)
        toast.error(err.message || 'Cannot get wallet details, Please try again.')
      })
  }

  useEffect(() => {
    if (id) {
      fetchWalletDetails(id)
    }
  }, [id])

  // useEffect(() => {
  //   fetchOrderDetails()
  // }, [])

  const { items: tblData, CsTable } = useSortableData(walletListData, walletDetailsColumn)
  return (
    <div className='px-[32px] py-[20px] text-left'>
      {/* ****************** page bread crumbs ***************** */}
      <BreadCrumbs
        navList={[
          { title: 'Home', path: '/dashboard' },
          { title: 'Wallet', path: '/dashboard/wallet' },
          { title: 'Wallet Details', path: 'wallet' },
        ]}
      />
      {/* ************************************** */}
      {/* Tab title */}
      <div className='cs_row items-center'>
        <div className='mr-2'>
          <TabTitle title={walletDetails.name} icon={<img src={walletIcon} alt='' />} />
        </div>
      </div>
      {/* ******************************************* */}
      {/* account balance info */}
      <div className='flex flex-wrap my-2'>
        <div className='col lg:w-[25%] w-[50%] md:pl-0 min-w-[280px]'>
          <Card cardClasses='py-2'>
            <p className='text-[20px]'>Account Balance</p>
            <div className='md:mt-2 flex flex-wrap justify-end items-center'>
              <p className='lg:px-2 pr-2 text-[20px]'>CNY</p>
              <h6 className='font-medium text-[20px] lg:ml-0 ml-auto'>
                {formatAmount(walletDetails?.available_balance - walletDetails.frozen_balance)}
              </h6>
            </div>
          </Card>
        </div>
        <div className='col lg:w-[25%] w-[50%] md:pl-0 min-w-[280px]'>
          <Card cardClasses='py-2'>
            <p className='text-[20px]'>Frozen Balance</p>
            <div className='md:mt-2 flex flex-wrap justify-end items-center'>
              <p className='lg:px-2 pr-2 text-[20px]'>CNY</p>
              <h6 className='font-medium text-[20px] lg:ml-0 ml-auto'>
                {formatAmount(walletDetails?.frozen_balance)}
              </h6>
            </div>
          </Card>
        </div>
        <div className='col lg:w-[25%] w-[50%] md:pl-0 min-w-[280px]'>
          <Card cardClasses='py-2'>
            <p className='text-[20px]'>Available balance</p>
            <div className='md:mt-2 flex flex-wrap justify-end items-center'>
              <p className='lg:px-2 pr-2 text-[20px]'>CNY</p>
              <h6 className='font-medium text-[20px] lg:ml-0 ml-auto'>
                {formatAmount(walletDetails?.available_balance)}
              </h6>
            </div>
          </Card>
        </div>
      </div>
      <hr />

      {/* --------------------------------------------------- */}
      {/* Data Table */}
      <div className='mt-14'>
        <div className='md:my-4 border rounded my-2'>
          <CsTable columns={walletDetailsColumn} loading={isLoading} tableClasses='header_fill'>
            {!isLoading ? (
              walletListData?.length > 0 ? (
                walletListData.map((item, index) => (
                  <tr key={index}>
                    <td>
                      <p className='min-w-[120px] text-sm'>{formatDateTime(item?.created_at)}</p>
                    </td>
                    <td>
                      <Link
                        to={'/dashboard/orders/details/' + item.id}
                        className='text-start text-[#499CD6]'
                      >
                        {item?.id}
                      </Link>
                    </td>
                    <td>{item?.type}</td>
                    <td>{item?.currency}</td>
                    <td>{item?.order_amount}</td>

                    <td className='text-center'>
                      <div>
                        <p className='bg-[#DDF6C780] p-1 rounded text-sm text-[#42821E] '>
                          {item?.status}
                        </p>
                      </div>
                    </td>
                    <td className='text-center'>
                      {' '}
                      <p className='text-sm min-w-[120px]'>{formatDateTime(item?.last_updated)}</p>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan='8'>
                    <div className='my-4 mx-auto text-center'>
                      <p>No records</p>
                    </div>
                  </td>
                </tr>
              )
            ) : (
              <tr>
                <td colSpan='8'>
                  <div className='my-4 mx-auto text-center'>
                    <Spinner />
                  </div>
                </td>
              </tr>
            )}
          </CsTable>
        </div>
      </div>
      {/* ----------------------------------------------------------- */}
    </div>
  )
}

export default WalletDetails
