import { useLocalStorage } from 'hook/useLocalStorage'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { setAuth } from 'store/reducers/authReducerSlice'
import Button from 'utils/Button'

const AccessDenied = () => {
  const { data: user } = useSelector((store) => store.auth)
  const userRole = user.roles[0]
  const { setLocalVal } = useLocalStorage('picotop_user_token', '')
  const { setLocalVal: setLocalUserId } = useLocalStorage('picotop_user_id', '')
  const { setLocalVal: setLocalUserEmail } = useLocalStorage('picotop_user_email', '')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  // logout
  const logout = () => {
    setLocalVal('')
    setLocalUserId('')
    setLocalUserEmail('')
    dispatch(
      setAuth({
        isAuthenticated: false,
        isLoading: false,
        data: {},
      }),
    )
    navigate('/login')
    toast.success('Logged out successfully!')
  }
  return (
    <div className='h-[100vh] grid place-content-center'>
      <div>
        <h1 className='mb-2'>You don't have access to this page</h1>
        <div className='flex flex-col items-center justify-center'>
          <Button
            className='md:ml-[1rem] ms-2  text-white bg-purple-500 border-purple-500 rounded cursor-pointer select-none w-max  max-w-full px-2 py-1  my-[6px]'
            onClick={() =>
              navigate(
                `/${
                  userRole === 'MERCHANT_ADMIN' ? 'merchantPortal/merchant' : 'Dashboard/merchant'
                }`,
              )
            }
          >
            Go back to home page
          </Button>
          <Button onClick={logout}>Logout</Button>
        </div>
      </div>
    </div>
  )
}

export default AccessDenied
